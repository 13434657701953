// Navigation.js
import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import logo from './img/logo.png'; 
import { Link } from 'react-router-dom';

import SearchModal from './SearchModal';
import MenuModal from './MenuModal';


const Navigation = ({ 
  starIcon, 
  searchIcon, 
  messageIcon,
  handleSearchClick,
   handleMenuClick, 
   showSearch, 
   showMenu, 
   navSearchRef, 
   searchRef, 
   menuRef, 
   user, 
   handleLogout, 
   openSearchModal, 
   isSearchModalOpen, 
   closeSearchModal,
   bigFeaturedIcon,
   bigRankIcon,
   bigRankAllIcon,
   bigDiaryIcon,
   bigHomeIcon,
   bigLogoutIcon,
   changeLanguage,
   t,
   heartIcon,
   userIcon,
   settingsIcon,
   chevronUpIcon,
  chevronDownIcon,
  calendarIcon,
  activityIcon,
  dragIcon,
  iconSearch,
  bestPlaysIcon,
  bestMusicalsIcon,
  rankPlaysIcon,
  rankMusicalsIcon
  }) => {
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  const [isExploreMenuOpen, setIsExploreMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  // Referencje do timeoutów
  const exploreTimeoutRef = useRef(null);
  const profileTimeoutRef = useRef(null);

  const openMenuModal = () => {
    setIsMenuModalOpen(true);
  };

  const closeMenuModal = () => {
    setIsMenuModalOpen(false);
  };

  const handleNavigation = (path) => {
    window.location.href = path; // wymuś przejście na nowy adres
  };

  // Funkcje dla menu Explore
  const handleExploreMouseEnter = () => {
    if (exploreTimeoutRef.current) {
      clearTimeout(exploreTimeoutRef.current);
    }
    setIsProfileMenuOpen(false); // Zamknij drugie menu
    setIsExploreMenuOpen(true);
  };

  const handleExploreMouseLeave = () => {
    exploreTimeoutRef.current = setTimeout(() => {
      setIsExploreMenuOpen(false);
    }, 300);
  };

  // Funkcje dla menu Profile
  const handleProfileMouseEnter = () => {
    if (profileTimeoutRef.current) {
      clearTimeout(profileTimeoutRef.current);
    }
    setIsExploreMenuOpen(false); // Zamknij drugie menu
    setIsProfileMenuOpen(true);
  };

  const handleProfileMouseLeave = () => {
    profileTimeoutRef.current = setTimeout(() => {
      setIsProfileMenuOpen(false);
    }, 300);
  };

  // Czyszczenie timeoutów przy odmontowaniu komponentu
  useEffect(() => {
    return () => {
      if (exploreTimeoutRef.current) clearTimeout(exploreTimeoutRef.current);
      if (profileTimeoutRef.current) clearTimeout(profileTimeoutRef.current);
    };
  }, []);

  const handleForumLogin = async () => {
    try {
        window.location.href = `${process.env.REACT_APP_DISCOURSE_URL}/session/sso`;
    } catch (error) {
        console.error('Error during forum login:', error);
    }
  };


  return (
    <Container fluid className="px-3 main-container">
      {/* Header */}
      <Row className="g-0 s-bg-primary text-white justify-content-center align-items-center py-3 mx-n3 app-header-container"> {/* Usuń boczny padding i dodaj ujemny margin */}
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl"> 
          <header className="app-header mx-n3"> {/* Dodaj ujemny margin tylko dla headera */}
            <Container className="d-flex justify-content-between align-items-center px-3">
              <Link to="/" >
                <img src={logo} alt="Showify" className="logo" />
              </Link>
              <div className="navigation-icons">
                <div className="desktop-menu">
                  <Button  className="btn-top-nav homepage-nav-link" onClick={() => handleNavigation('/')}>
                    {t('navigation.home')}
                  </Button>
                              <Button  className="btn-top-nav display-none" onClick={handleForumLogin}>
                              {t('navigation.forum')}
                            </Button>
                  <Button  className="btn-top-nav" onClick={() => handleNavigation('/diary')}>
                  {t('navigation.my-shows')}
                  </Button>
                  <div 
                    className="explore-container position-relative"
                    onMouseEnter={handleExploreMouseEnter}
                    onMouseLeave={handleExploreMouseLeave}
                  >
                    <Button 
                      className="btn-top-nav" 
                      onClick={() => handleNavigation('/explore')}

                    >
                      {t('navigation.explore')}
                      <img 
                        src={isExploreMenuOpen ? chevronUpIcon : chevronDownIcon} 
                        alt="Explore" 
                        className="quick-icon nav-chevron" 
                      />
                    </Button>
                    
                    {isExploreMenuOpen && (
                      <div 
                        className="explore-dropdown"
                        onMouseEnter={handleExploreMouseEnter}
                        onMouseLeave={handleExploreMouseLeave}
                      >
                        <div className="dropdown-menu show position-absolute">
                        <Button 
                            className="dropdown-item" 
                            variant="light"
                            onClick={() => handleNavigation('/calendar')}
                          >
                             <img src={calendarIcon} alt="Calendar" className="quick-icon" />
                            {t('quick-links.calendar')}
                          </Button>
                          <Button 
                            className="dropdown-item"
                            variant="light"
                            onClick={() => handleNavigation('/best-played')}
                          >
                             <img src={bestMusicalsIcon} alt="Best currently" className="quick-icon" />
                            {t('quick-links.best-currently-musicals')}
                          </Button>
                          <Button 
                            className="dropdown-item"
                            variant="light"
                            onClick={() => handleNavigation('/best-played-plays')}
                          >
                             <img src={bestPlaysIcon} alt="Best currently" className="quick-icon" />
                            {t('quick-links.best-currently-plays')}
                          </Button>
                          <Button 
                            className="dropdown-item" 
                            variant="light"
                            onClick={() => handleNavigation('/best-all-time')}
                          >
                             <img src={rankMusicalsIcon} alt="Best all time" className="quick-icon" />
                            {t('quick-links.best-alltime-musicals')}
                          </Button>
                          <Button 
                            className="dropdown-item" 
                            variant="light"
                            onClick={() => handleNavigation('/best-all-time-plays')}
                          >
                             <img src={rankPlaysIcon} alt="Best all time" className="quick-icon" />
                            {t('quick-links.best-alltime-plays')}
                          </Button>
                          <Button 
                            className="dropdown-item" 
                            variant="light"
                            onClick={() => handleNavigation('/feed')}
                          >
                             <img src={activityIcon} alt="Activity" className="quick-icon" />
                            {t('quick-links.activity')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  
                




        {!user && ( // Tylko jeśli użytkownik jest zalogowany
            
              <Button variant="primary" onClick={() => handleNavigation('/signup')} className="signup-desktop">
                {t('navigation.sign-up')}
              </Button>
           
          
        )}

        {user && ( // Tylko jeśli użytkownik jest zalogowany
        <>
        <div className="nav-line"></div>

                  <div 
                    className="nav-profile-container position-relative"
                    onMouseEnter={handleProfileMouseEnter}
                    onMouseLeave={handleProfileMouseLeave}
                  >
                    <Button 
                      className="btn-top-nav desktop-profile-button" 
                      onClick={() => handleNavigation('/stats-rank')}
                    >
                      <span className="nav-username">{user.name}</span>

                      <img 
                        src={isProfileMenuOpen ? chevronUpIcon : chevronDownIcon} 
                        alt="Profile" 
                        className="quick-icon nav-chevron" 
                      />

<img 
  src={user?.avatarUrl && user.avatarUrl.includes('http') 
    ? user.avatarUrl 
    : `${process.env.REACT_APP_API_BASE_URL}/img${user?.avatarUrl || '/default-avatar.png'}`} 
  alt="User Avatar" 
  className="user-avatar" 
/>
</Button>
                    
                    {isProfileMenuOpen && (
                      <div 
                        className="profile-dropdown"
                        onMouseEnter={handleProfileMouseEnter}
                        onMouseLeave={handleProfileMouseLeave}
                      >
                        <div className="dropdown-menu menu-right show position-absolute">
                          <Button 
                            className="dropdown-item"
                            variant="light"
                            onClick={() => handleNavigation('/stats-rank')}
                          >
                            <img src={heartIcon} alt="Profile" className="quick-icon" />
                            {t('navigation.my-profile')}
                          </Button>
                          <Button 
                            className="dropdown-item" 
                            variant="light"
                            onClick={() => handleNavigation('/diary')}
                          >
                            <img src={bigDiaryIcon} alt="My shows" className="quick-icon" />
                            {t('navigation.my-shows')}
                          </Button>
                          <Button 
                            className="dropdown-item" 
                            variant="light"
                            onClick={() => handleNavigation('/my-account')}
                          >
                            <img src={settingsIcon} alt="Account" className="quick-icon" />
                            {t('navigation.my-account')}
                          </Button>
                          <Button 
                            className="dropdown-item btn-logout" 
                            variant="light"
                            onClick={() => {
                              handleLogout(); // Wylogowanie użytkownika
                              window.location.reload(); // Przeładowanie strony
                            }}
                          >
                            <img src={bigLogoutIcon} alt="Logout" className="quick-icon" />
                            {t('navigation.logout')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>

</>
        )}

         </div>
                <img src={iconSearch} alt="Search" onClick={openSearchModal} className="icon" />
                <SearchModal
  isOpen={isSearchModalOpen}
  onRequestClose={closeSearchModal}
  starIcon={starIcon}
  searchIcon={searchIcon}
  t={t}
/>
<img src={dragIcon} alt="Menu" onClick={openMenuModal} className="icon mobile-menu-icon" />
                <MenuModal 
                  isOpen={isMenuModalOpen} 
                  onRequestClose={closeMenuModal} 
                  handleLogout={handleLogout} // Przekazanie handleLogout
                  user={user}
                  bigFeaturedIcon={bigFeaturedIcon}
                  bigRankIcon={bigRankIcon}
                  bigRankAllIcon={bigRankAllIcon}
                  bigDiaryIcon={bigDiaryIcon}
                  bigHomeIcon={bigHomeIcon}
                  bigLogoutIcon={bigLogoutIcon}
                  heartIcon={heartIcon}
                  t={t}
                  changeLanguage={changeLanguage}
                  userIcon={userIcon}
                  settingsIcon={settingsIcon}
                  messageIcon={messageIcon}
                  calendarIcon={calendarIcon}
                  activityIcon={activityIcon}
                  bestPlaysIcon={bestPlaysIcon}
                  bestMusicalsIcon={bestMusicalsIcon}
                  rankPlaysIcon={rankPlaysIcon}
                  rankMusicalsIcon={rankMusicalsIcon}
                />
              </div>
            </Container>
          </header>
        </Col>
      </Row>
    </Container>
  );
};

export default Navigation;