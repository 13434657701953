import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

// Sprawdź zgodę użytkownika przed inicjalizacją Google Analytics
const consentStatus = localStorage.getItem('cookieConsent');
if (consentStatus === 'accepted') {
  ReactGA.initialize('G-VSVCD6BYY4');
} else {
  window['ga-disable-G-VSVCD6BYY4'] = true;
}

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
