import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const OnboardingModal = ({ 
  showOnboardingModal, 
  handleCloseOnboardingModal, 
  t
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 6;
  const navigate = useNavigate();
  const [onboardingImages, setOnboardingImages] = useState([]);

  // Ładowanie obrazków tylko gdy modal jest otwarty
  useEffect(() => {
    if (showOnboardingModal) {
      const loadImages = async () => {
        const images = await Promise.all([
          import('./img/onboarding/onboarding1.png'),
          import('./img/onboarding/onboarding2.png'),
          import('./img/onboarding/onboarding3.png'),
          import('./img/onboarding/onboarding4.png'),
          import('./img/onboarding/onboarding5.png')
        ]);
        setOnboardingImages(images.map(img => img.default));
      };
      loadImages();
    }
  }, [showOnboardingModal]);

  const onboardingSteps = [
    {
      image: onboardingImages[0],
      title: t('onboarding.step1.title'),
      description: t('onboarding.step1.description')
    },
    {
      image: onboardingImages[1],
      title: t('onboarding.step2.title'),
      description: t('onboarding.step2.description')
    },
    {
      image: onboardingImages[2],
      title: t('onboarding.step3.title'),
      description: t('onboarding.step3.description')
    },
    {
      image: onboardingImages[3],
      title: t('onboarding.step4.title'),
      description: t('onboarding.step4.description')
    },
    {
      image: onboardingImages[4],
      title: t('onboarding.step5.title'),
      description: t('onboarding.step5.description')
    },
    {
      image: onboardingImages[0],
      title: t('onboarding.step6.title'),
      buttons: true
    }
  ];

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const markOnboardingComplete = () => {
    // Natychmiast zapisz w localStorage
    localStorage.setItem('hasSeenOnboarding', 'true');
    
    // Wysyłamy żądanie w tle
    axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/onboarding-complete`, 
      {}, 
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
    ).catch(error => {
      console.error('Error marking onboarding as complete:', error);
    });
  };

  const handleAddFirstShow = () => {
    handleCloseOnboardingModal(); // To zamknie modal i ustawi localStorage
    markOnboardingComplete(); // Wysyłamy żądanie w tle
    navigate('/addToDiary');
  };

  const handleCheckActivity = () => {
    handleCloseOnboardingModal(); // To zamknie modal i ustawi localStorage
    markOnboardingComplete(); // Wysyłamy żądanie w tle
    navigate('/feed');
  };

  const handleClose = () => {
    handleCloseOnboardingModal(); // To zamknie modal i ustawi localStorage
    markOnboardingComplete(); // Wysyłamy żądanie w tle
  };

  return (
    <Modal 
      backdrop="true"
      centered
      className="onboarding-modal"
      onHide={handleClose}
      show={showOnboardingModal}
    >
      <Modal.Header closeButton className="modal-header">
      </Modal.Header>
      <Modal.Body>
        <div className="onboarding-modal-container">
          <div className="onboarding-modal-image-container">
            {onboardingImages.length > 0 && (
              <img 
                src={onboardingSteps[currentStep - 1].image} 
                alt="Onboarding" 
                className="onboarding-modal-image"
              />
            )}
          </div>
          
          <div className="modal-section">
            <div className="onboarding-content">
              <h2>{onboardingSteps[currentStep - 1].title}</h2>
              {!onboardingSteps[currentStep - 1].buttons && (
                <p>{onboardingSteps[currentStep - 1].description}</p>
              )}
              
            </div>

            {currentStep === totalSteps ? (
                <div className="final-step-buttons">
                  <Button variant="primary" onClick={handleAddFirstShow} className="w-100 mb-2">
                    {t('onboarding.buttons.add_show')}
                  </Button>
                  <Button variant="light" onClick={handleCheckActivity} className="w-100 mb-2">
                    {t('onboarding.buttons.check_activity')}
                  </Button>
                  <Button variant="light" onClick={handleClose} className="w-100">
                    {t('onboarding.buttons.explore')}
                  </Button>
                </div>
              ) : (
                <div className="navigation-buttons">
                  <Button 
                    variant="light" 
                    onClick={handlePrevious} 
                    disabled={currentStep === 1}
                  >
                    {t('onboarding.buttons.previous')}
                  </Button>
                  <Button 
                    variant="primary" 
                    onClick={handleNext}
                  >
                    {t('onboarding.buttons.next')}
                  </Button>
                </div>
              )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OnboardingModal; 