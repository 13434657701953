import React, { useState, useRef, useEffect, useTransition } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import i18next from './i18next';
import { pl, enUS } from 'date-fns/locale';

import HomePage from './Homepage';
import BestPlayedPage from './BestPlayedPage';
import BestAllTime from './BestAllTime';
import BestPlayedPagePlays from './BestPlayedPagePlays';
import BestAllTimePlays from './BestAllTimePlays';
import GoogleSignUp from './GoogleSignUp'; 
import Diary from './Diary';
import AddToDiary from './AddToDiary'; 
import ShowPage from './ShowPage';  
import UserPage from './UserPage';  
import MyAccount from './MyAccount';  
import StatsRank from './StatsRank';  
import Navigation from './Navigation'; 
import Footer from './Footer'; 
import BottomMenu from './BottomMenu'; // Importuj nowy komponent
import CookieConsent from './CookieConsent';
import About from './About';
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import PersonPage from './PersonPage';
import ActivityFeed from './ActivityFeed';
import Calendar from './Calendar';
import CalendarDayView from './CalendarDayView';
import WelcomeModal from './WelcomeModal';
import Explore from './Explore';
import AdminPanel from './AdminPanel';
import ModeratorPanel from './ModeratorPanel';
import TheaterShows from './TheaterShows';


import { jwtDecode } from 'jwt-decode';

// Import ikon
import searchIcon from './img/icons/search.svg';
import starIcon from './img/icons/star.svg';
import calendarIcon from './img/icons/calendar.svg';
import rankIcon from './img/icons/rank.svg';
import userIcon from './img/icons/user.svg';
import heartIcon from './img/icons/heart.svg';
import messageIcon from './img/icons/message.svg';
import fileIcon from './img/icons/file.svg';
import musicIcon from './img/icons/music.svg';
import clockIcon from './img/icons/clock.svg';
import plusIcon from './img/icons/plus.svg';
import successIcon from './img/icons/success.svg';
import infoIcon from './img/icons/info.svg';
import homeIcon from './img/icons/home.svg';
import compassIcon from './img/icons/compass.svg';
import helpIcon from './img/icons/help.svg';
import arrowLeftIcon from './img/icons/arrow-left.svg';
import arrowRightIcon from './img/icons/arrow-right.svg';
import chevronDownIcon from './img/icons/chevron-down.svg';
import chevronUpIcon from './img/icons/chevron-up.svg';
import dragIcon from './img/icon-menu.svg';
import eyeIcon from './img/icons/eye.svg';
import globeIcon from './img/icons/globe.svg';
import shareIcon from './img/icons/share.svg';
import shareLinkIcon from './img/icons/share-2.svg';
import downloadIcon from './img/icons/download.svg';
import settingsIcon from './img/icons/settings.svg';
import noteIcon from './img/icons/note.svg';
import shieldIcon from './img/icons/shield.svg';
import keyIcon from './img/icons/key.svg';
import arrowLeftRegIcon from './img/icons/arrow-left-reg.svg';
import chevronsRightIcon from './img/icons/chevrons-right.svg';
import sunIcon from './img/icons/sun.svg';
import checkCircleIcon from './img/icons/check-circle.svg';
import activityIcon from './img/icons/activity.svg';

import defaultPoster from './img/defaults/default-poster.png';
import forumLogo from './img/forum-logo.png';
import welcomeModalVideo from './img/welcome1.mp4';

import bigRankIcon from './img/icons/big-rank.svg';
import bigRankAllIcon from './img/icons/big-rank-all.svg'; 
import bigDiaryIcon from './img/icons/big-diary.svg';
import bigFeaturedIcon from './img/icons/big-featured.svg';
import bigHomeIcon from './img/icons/big-home.svg';
import bigLogoutIcon from './img/icons/big-logout.svg';
import colorCircleCalendarIcon from './img/icons/color-circle-calendar.svg';
import colorCircleStarIcon from './img/icons/color-circle-star.svg';

import bestPlaysIcon from './img/icons/best-plays.svg';
import bestMusicalsIcon from './img/icons/best-musicals.svg';
import rankMusicalsIcon from './img/icons/rank-musicals.svg';
import rankPlaysIcon from './img/icons/rank-plays.svg';

import iconSearch from './img/icon-search.svg';
import logoGrey from './img/logo-grey.svg';
import socialInstaIcon from './img/icons/social-instagram.svg';

// Globalna konfiguracja axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'https://showify-app-ed20531d82b3.herokuapp.com/api';

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const App = () => {


  const [, startTransition] = useTransition();
  // Stan aplikacji
  const [user, setUser] = useState(null);
  const [shows, setShows] = useState([]);
  const [userDiary, setUserDiary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('Warszawa');
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  

// Dodaj na początku pliku App.js
const checkUserSession = () => {
  const token = localStorage.getItem('token');
  const storedUser = localStorage.getItem('user');
  
  if (!token || !storedUser) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return null;
  }
  
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return null;
    }
    
    return JSON.parse(storedUser);
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return null;
  }
};

  // Pobieranie danych użytkownika z localStorage
  useEffect(() => {
    const validUser = checkUserSession();
    setUser(validUser);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('token');
        // Wylogowanie użytkownika lub inne działania
      }
    }
  }, []);

  // Pobieranie listy spektakli oraz dziennika użytkownika
  useEffect(() => {
    async function fetchShows() {
      try {
        setIsLoading(true);
  
        // Pobieranie danych spektakli (niezależnie od logowania)
        const showsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows`);
        
        startTransition(() => {
          setShows(showsResponse.data);
        });
  
        // Pobieranie dziennika użytkownika tylko, jeśli token istnieje
        const token = localStorage.getItem('token');
        if (token) {
          const diaryResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          startTransition(() => {
            setUserDiary(diaryResponse.data);
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  
    fetchShows();
  }, []);
  
  

  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  const localeMap = {
    en: enUS,
    pl: pl,
  };

    // Wybieramy locale na podstawie aktualnego języka z i18n
    const currentLocale = localeMap[i18next.language] || pl;
  
// Pobranie oceny użytkownika dla konkretnego spektaklu
const getUserRatingForShow = (showId) => {
  if (!userDiary || !showId) {
    return null;
  }

  // Filtrowanie wpisów dla konkretnego showId
  const entries = userDiary.filter(entry => 
    entry && entry.show && entry.show._id === showId
  );
  
  if (entries.length === 0) {
    return null;
  }

  // Filtrowanie ocen, aby uwzględnić tylko te, które nie są null i nie są równe 0
  const ratings = entries
    .map(entry => entry.userRating)
    .filter(rating => rating !== null && rating !== 0);

  if (ratings.length === 0) {
    return null;
  }

  // Sumowanie ocen i obliczanie średniej
  const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
  const averageRating = totalRating / ratings.length;

  return averageRating.toFixed(1);
};


  // Funkcja zwracająca opis oceny na podstawie wartości liczbowej
  const getRatingDescription = (rating) => {
    if (rating <= 1.5) return t('ratings.description.disaster');
    if (rating <= 2.5) return t('ratings.description.terrible');
    if (rating <= 3.5) return t('ratings.description.bad');
    if (rating <= 4.5) return t('ratings.description.below-average');
    if (rating <= 5.5) return t('ratings.description.average');
    if (rating <= 6.5) return t('ratings.description.above-average');
    if (rating <= 7.5) return t('ratings.description.good');
    if (rating <= 8.5) return t('ratings.description.very-good');
    if (rating <= 9.5) return t('ratings.description.excellent');
    if (rating > 9.5) return t('ratings.description.masterpiece');
    return t('ratings.description.none');
  };

  // Renderowanie gwiazdek oceny
  const renderStarRating = (rating) => {
    const totalStars = 10;
    const yellowStars = Math.round(rating);
    return (
      <div className="star-rating">
        {[...Array(totalStars)].map((_, i) => (
          <img
            key={i}
            src={starIcon}
            alt="Star"
            className={`star-icon ${i < yellowStars ? 'yellow-star' : 'gray-star'}`}
          />
        ))}
      </div>
    );
  };

  const handleNavigation = (path) => {
    window.location.href = path; // wymuś przejście na nowy adres
  };

  const POLAND_CITIES = [
    'Warszawa',
    'Gdynia',      // Teatr Muzyczny w Gdyni
    'Kraków',      // Opera Krakowska, Teatr im. Juliusza Słowackiego
    'Poznań',      // Teatr Muzyczny w Poznaniu
    'Łódź',        // Teatr Muzyczny w Łodzi
    'Wrocław',     // Opera Wrocławska, Capitol Musical Theatre
    'Chorzów',
    'Toruń',       // Teatr im. Wilama Horzycy
    'Bydgoszcz',   // Opera Nova
    'Szczecin',    // Opera na Zamku w Szczecinie
    'Koszalin',
  ];

  const FEATURED_SHOWS = [
    // rekomendowane, najpewniej grane w najblizszym czasie
    'wicked',
    'jesus-christ-superstar-1',
    'thrill-me',
    // nadchodza
    'drogi-evanie-hansenie',
    'six-1',
    'anioly-w-ameryce',
    // warte zainteresowania, chwile po premierze albo top
    'premiera-ktora-poszla-nie-tak',
    'cabaret-5'
  ];
  

  // Zmiana lokalizacji spektakli
  const handleChangeLocation = (location) => {
    setCurrentLocation(location);
  };

  // Filtracja i sortowanie spektakli według lokalizacji
  const showsByLocation = shows.filter(show => show.location === currentLocation);
  const showsByLocationSorted = showsByLocation
  .filter(show => show.globalRank !== null && show.globalRank > 0) // Dodanie filtra globalRank
  .sort((a, b) => a.globalRank - b.globalRank);

    // Sortowanie spektakli według avgRating
    const sortedShowsByLocation = showsByLocation
    .filter(show => show.globalRank !== null && show.globalRank > 0) // Dodanie filtra globalRank
    .sort((a, b) => b.avgRating - a.avgRating); // Sortowanie według średniej oceny malejąco
  
  // Dynamiczne określanie pozycji w rankingu
  const showsWithDynamicRank = sortedShowsByLocation.map((show, index) => ({
    ...show,
    dynamicRank: index + 1,
  }));

  // Obsługa elementów interfejsu użytkownika
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navSearchRef = useRef(null);
  const searchRef = useRef(null);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.icon')) {
      setShowMenu(false);
    }
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
    if (navSearchRef.current && !navSearchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
    setShowMenu(false);
  };

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
    setShowSearch(false);
  };

  const handleLogout = async () => {
    try {
      console.log('Starting logout process...');
      
      startTransition(() => {
        setUser(null);
      });
      
      localStorage.removeItem('user');
      const token = localStorage.getItem('token');
      localStorage.removeItem('token');
      console.log('Cleared local storage and user state');
      
      // Wyloguj z Google
      if (window.google?.accounts.id) {
        window.google.accounts.id.disableAutoSelect();
        console.log('Disabled Google auto-select');
      }

      // Wyloguj z forum przez nasz backend
      try {
        console.log('Attempting forum logout through backend...');
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/discourse-logout`,
          {},
          { 
            withCredentials: true,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('Forum logout successful');
      } catch (forumError) {
        console.error('Forum logout error:', forumError);
      }

      // Wyczyść ciasteczka Discourse
      const discourseCookies = ['_t', 'destination_url', 'discourse_sso', 'discourse_sso_payload', 'discourse_sso_sig', 'auth_token'];
      discourseCookies.forEach(cookieName => {
        document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.showify.pl`;
        document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      });
      console.log('Cleared cookies');

      // Przekieruj do strony głównej
      window.location.href = '/';
    } catch (error) {
      console.error('Main logout error:', error);
      window.location.href = '/';
    }
  };

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('pl-PL', options);
  };

  // Obsługa kliknięcia poza aktywne elementy interfejsu
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Modyfikacja useEffect dla modalu powitalnego
  useEffect(() => {
    const checkWelcomeModal = () => {
      const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
      const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                    window.navigator.standalone || 
                    document.referrer.includes('android-app://');
      
      if (!hasSeenWelcome && !isPWA) {
        startTransition(() => {
          setShowWelcomeModal(true);
        });
      }
    };

    // Opóźnienie sprawdzenia, aby dać czas na załadowanie komponentu
    const timeoutId = setTimeout(checkWelcomeModal, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleCloseWelcomeModal = () => {
    startTransition(() => {
      setShowWelcomeModal(false);
      localStorage.setItem('hasSeenWelcome', 'true');
    });
  };


  return (
<Router>
  <Navigation
    activityIcon={activityIcon}
    bigDiaryIcon={bigDiaryIcon}
    bigFeaturedIcon={bigFeaturedIcon}
    bigHomeIcon={bigHomeIcon}
    bigLogoutIcon={bigLogoutIcon}
    bigRankAllIcon={bigRankAllIcon}
    bigRankIcon={bigRankIcon}
    calendarIcon={calendarIcon}
    changeLanguage={changeLanguage}
    chevronDownIcon={chevronDownIcon}
    chevronUpIcon={chevronUpIcon}
    closeSearchModal={closeSearchModal}
    handleLogout={handleLogout}
    handleMenuClick={handleMenuClick}
    handleSearchClick={handleSearchClick}
    heartIcon={heartIcon}
    isSearchModalOpen={isSearchModalOpen}
    menuRef={menuRef}
    messageIcon={messageIcon}
    navSearchRef={navSearchRef}
    openSearchModal={openSearchModal}
    searchIcon={searchIcon}
    settingsIcon={settingsIcon}
    showMenu={showMenu}
    showSearch={showSearch}
    starIcon={starIcon}
    t={t}
    user={user}
    userIcon={userIcon}
    dragIcon={dragIcon}
    iconSearch={iconSearch}
    bestPlaysIcon={bestPlaysIcon}
    bestMusicalsIcon={bestMusicalsIcon}
    rankPlaysIcon={rankPlaysIcon}
    rankMusicalsIcon={rankMusicalsIcon}
  />

  <Routes>
    <Route 
      path="/" 
      element={
        <HomePage 
          FEATURED_SHOWS={FEATURED_SHOWS}
          POLAND_CITIES={POLAND_CITIES}
          arrowLeftIcon={arrowLeftIcon}
          arrowRightIcon={arrowRightIcon}
          bigDiaryIcon={bigDiaryIcon}
          bigFeaturedIcon={bigFeaturedIcon}
          bigRankAllIcon={bigRankAllIcon}
          bigRankIcon={bigRankIcon}
          calendarIcon={calendarIcon}
          changeLanguage={changeLanguage}
          checkCircleIcon={checkCircleIcon}
          chevronsRightIcon={chevronsRightIcon}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          currentLocale={currentLocale}
          currentLocation={currentLocation}
          defaultPoster={defaultPoster}
          formatDate={formatDate}
          forumLogo={forumLogo}
          getRatingDescription={getRatingDescription}
          getUserRatingForShow={getUserRatingForShow}
          handleChangeLocation={handleChangeLocation}
          heartIcon={heartIcon}
          infoIcon={infoIcon}
          isLoading={isLoading}
          plusIcon={plusIcon}
          rankIcon={rankIcon}
          renderStarRating={renderStarRating}
          searchIcon={searchIcon}
          setUser={setUser}
          shows={shows}
          showsByLocationSorted={showsByLocationSorted}
          starIcon={starIcon}
          successIcon={successIcon}
          sunIcon={sunIcon}
          t={t}
          user={user}
          userIcon={userIcon}
          activityIcon={activityIcon}
          bestPlaysIcon={bestPlaysIcon}
          bestMusicalsIcon={bestMusicalsIcon}
          rankPlaysIcon={rankPlaysIcon}
          rankMusicalsIcon={rankMusicalsIcon}
          handleNavigation={handleNavigation}
        />
      } 
    />

    <Route 
      path="/signup" 
      element={
        <GoogleSignUp 
          keyIcon={keyIcon}
          setUser={setUser}
          shieldIcon={shieldIcon}
          t={t}
          userIcon={userIcon}
        />
      } 
    />

    <Route 
      path="/diary" 
      element={
        <Diary 
          bigDiaryIcon={bigDiaryIcon}
          bigFeaturedIcon={bigFeaturedIcon}
          bigRankAllIcon={bigRankAllIcon}
          bigRankIcon={bigRankIcon}
          calendarIcon={calendarIcon}
          chevronDownIcon={chevronDownIcon}
          chevronUpIcon={chevronUpIcon}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          currentLocale={currentLocale}
          currentLocation={currentLocation}
          getRatingDescription={getRatingDescription}
          getUserRatingForShow={getUserRatingForShow}
          handleChangeLocation={handleChangeLocation}
          isLoading={isLoading}
          rankIcon={rankIcon}
          renderStarRating={renderStarRating}
          searchIcon={searchIcon}
          setIsLoading={setIsLoading}
          setUser={setUser}
          shows={shows}
          showsByLocationSorted={showsByLocationSorted}
          starIcon={starIcon}
          successIcon={successIcon}
          t={t}
          user={user}
        />
      } 
    />

    <Route 
      path="/addToDiary" 
      element={
        <AddToDiary
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          currentLocale={currentLocale}
          defaultPoster={defaultPoster}
          getRatingDescription={getRatingDescription}
          noteIcon={noteIcon}
          plusIcon={plusIcon}
          rankIcon={rankIcon}
          renderStarRating={renderStarRating}
          searchIcon={searchIcon}
          starIcon={starIcon}
          successIcon={successIcon}
          t={t}
        />
      } 
    />

    <Route 
      path="/shows/:slug" 
      element={
        <ShowPage 
          calendarIcon={calendarIcon}
          clockIcon={clockIcon}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          currentLocale={currentLocale}
          defaultPoster={defaultPoster}
          fileIcon={fileIcon}
          formatDate={formatDate}
          getRatingDescription={getRatingDescription}
          helpIcon={helpIcon}
          infoIcon={infoIcon}
          isLoading={isLoading}
          messageIcon={messageIcon}
          musicIcon={musicIcon}
          noteIcon={noteIcon}
          rankIcon={rankIcon}
          renderStarRating={renderStarRating}
          searchIcon={searchIcon}
          starIcon={starIcon}
          successIcon={successIcon}
          t={t}
          user={user}
          userIcon={userIcon}
        />
      } 
    />

    <Route 
      path="/person/:slug" 
      element={
        <PersonPage
          calendarIcon={calendarIcon}
          clockIcon={clockIcon}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          currentLocale={currentLocale}
          defaultPoster={defaultPoster}
          fileIcon={fileIcon}
          formatDate={formatDate}
          getRatingDescription={getRatingDescription}
          getUserRatingForShow={getUserRatingForShow}
          helpIcon={helpIcon}
          infoIcon={infoIcon}
          isLoading={isLoading}
          messageIcon={messageIcon}
          musicIcon={musicIcon}
          noteIcon={noteIcon}
          rankIcon={rankIcon}
          renderStarRating={renderStarRating}
          searchIcon={searchIcon}
          starIcon={starIcon}
          successIcon={successIcon}
          t={t}
          user={user}
          userIcon={userIcon}
        />
      } 
    />

    <Route 
      path="/best-played" 
      element={
        <BestPlayedPage 
          POLAND_CITIES={POLAND_CITIES}
          currentLocation={currentLocation}
          getUserRatingForShow={getUserRatingForShow}
          handleChangeLocation={handleChangeLocation}
          isLoading={isLoading}
          rankIcon={rankIcon}
          shows={shows}
          showsByLocationSorted={showsByLocationSorted}
          showsWithDynamicRank={showsWithDynamicRank}
          sortedShowsByLocation={sortedShowsByLocation}
          starIcon={starIcon}
          t={t}
          user={user}
          bigRankIcon={bigRankIcon}
          handleNavigation={handleNavigation}
        />
      } 
    />

<Route 
      path="/best-played-plays" 
      element={
        <BestPlayedPagePlays 
          POLAND_CITIES={POLAND_CITIES}
          currentLocation={currentLocation}
          getUserRatingForShow={getUserRatingForShow}
          handleChangeLocation={handleChangeLocation}
          isLoading={isLoading}
          rankIcon={rankIcon}
          shows={shows}
          showsByLocationSorted={showsByLocationSorted}
          showsWithDynamicRank={showsWithDynamicRank}
          sortedShowsByLocation={sortedShowsByLocation}
          starIcon={starIcon}
          t={t}
          user={user}
          bigRankIcon={bigRankIcon}
          handleNavigation={handleNavigation}
        />
      } 
    />

    <Route 
      path="/user/:googleId" 
      element={
        <UserPage 
          calendarIcon={calendarIcon}
          chevronDownIcon={chevronDownIcon}
          chevronUpIcon={chevronUpIcon}
          colorCircleCalendarIcon={colorCircleCalendarIcon}
          colorCircleStarIcon={colorCircleStarIcon}
          currentLocale={currentLocale}
          eyeIcon={eyeIcon}
          getRatingDescription={getRatingDescription}
          infoIcon={infoIcon}
          isLoading={isLoading}
          renderStarRating={renderStarRating}
          setIsLoading={setIsLoading}
          shareIcon={shareIcon}
          shareLinkIcon={shareLinkIcon}
          starIcon={starIcon}
          successIcon={successIcon}
          t={t}
          userIcon={userIcon}
        />
      } 
    />

    <Route 
      path="/best-all-time" 
      element={
        <BestAllTime
          POLAND_CITIES={POLAND_CITIES}
          currentLocation={currentLocation}
          getUserRatingForShow={getUserRatingForShow}
          handleChangeLocation={handleChangeLocation}
          isLoading={isLoading}
          rankIcon={rankIcon}
          shows={shows}
          showsByLocationSorted={showsByLocationSorted}
          showsWithDynamicRank={showsWithDynamicRank}
          sortedShowsByLocation={sortedShowsByLocation}
          starIcon={starIcon}
          t={t}
          user={user}
          bigRankAllIcon={bigRankAllIcon}
          handleNavigation={handleNavigation}
        />
      } 
    />

<Route 
      path="/theater-summary" 
      element={
        <TheaterShows
          POLAND_CITIES={POLAND_CITIES}
          currentLocation={currentLocation}
          getUserRatingForShow={getUserRatingForShow}
          handleChangeLocation={handleChangeLocation}
          isLoading={isLoading}
          rankIcon={rankIcon}
          shows={shows}
          showsByLocationSorted={showsByLocationSorted}
          showsWithDynamicRank={showsWithDynamicRank}
          sortedShowsByLocation={sortedShowsByLocation}
          starIcon={starIcon}
          t={t}
          user={user}
          bigRankAllIcon={bigRankAllIcon}
          handleNavigation={handleNavigation}
        />
      } 
    />

<Route 
      path="/best-all-time-plays" 
      element={
        <BestAllTimePlays
          POLAND_CITIES={POLAND_CITIES}
          currentLocation={currentLocation}
          getUserRatingForShow={getUserRatingForShow}
          handleChangeLocation={handleChangeLocation}
          isLoading={isLoading}
          rankIcon={rankIcon}
          shows={shows}
          showsByLocationSorted={showsByLocationSorted}
          showsWithDynamicRank={showsWithDynamicRank}
          sortedShowsByLocation={sortedShowsByLocation}
          starIcon={starIcon}
          t={t}
          user={user}
          bigRankAllIcon={bigRankAllIcon}
          handleNavigation={handleNavigation}
        />
      } 
    />

    <Route 
      path="/about" 
      element={
        <About
          t={t}
          welcomeModalVideo={welcomeModalVideo}
        />
      } 
    />

<Route 
      path="/contact" 
      element={
        <Contact
          t={t}
        />
      } 
    />

<Route 
      path="/privacypolicy" 
      element={
        <PrivacyPolicy
          t={t}
        />
      } 
    />

    <Route 
      path="/feed" 
      element={
        <ActivityFeed
          calendarIcon={calendarIcon}
          formatDate={formatDate}
          infoIcon={infoIcon}
          renderStarRating={renderStarRating}
          t={t}
          user={user}
          activityIcon={activityIcon}
        />
      } 
    />

    <Route 
      path="/my-account" 
      element={
        <MyAccount 
          dragIcon={dragIcon}
          eyeIcon={eyeIcon}
          globeIcon={globeIcon}
          infoIcon={infoIcon}
          setIsLoading={setIsLoading}
          user={user}
          settingsIcon={settingsIcon}
        />
      } 
    />

    <Route 
      path="/stats-rank" 
      element={
        <StatsRank
          chevronDownIcon={chevronDownIcon}
          chevronUpIcon={chevronUpIcon}
          downloadIcon={downloadIcon}
          dragIcon={dragIcon}
          eyeIcon={eyeIcon}
          globeIcon={globeIcon}
          infoIcon={infoIcon}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          settingsIcon={settingsIcon}
          shareIcon={shareIcon}
          shareLinkIcon={shareLinkIcon}
          starIcon={starIcon}
          user={user}
          heartIcon={heartIcon}
        />
      } 
    />

    <Route 
      path="/calendar" 
      element={
        <Calendar 
          POLAND_CITIES={POLAND_CITIES}
          arrowLeftIcon={arrowLeftIcon}
          arrowRightIcon={arrowRightIcon}
          chevronsRightIcon={chevronsRightIcon}
          currentLocale={currentLocale}
          infoIcon={infoIcon}
          starIcon={starIcon}
          sunIcon={sunIcon}
          t={t}
          calendarIcon={calendarIcon}
        />
      } 
    />

    <Route 
      path="/calendar/day" 
      element={
        <CalendarDayView 
          POLAND_CITIES={POLAND_CITIES}
          arrowLeftIcon={arrowLeftIcon}
          arrowLeftRegIcon={arrowLeftRegIcon}
          arrowRightIcon={arrowRightIcon}
          currentLocale={currentLocale}
          starIcon={starIcon}
          t={t}
        />
      } 
    />

    <Route 
      path="/explore" 
      element={
        <Explore 
          t={t}
          compassIcon={compassIcon}
          bestPlaysIcon={bestPlaysIcon}
          bestMusicalsIcon={bestMusicalsIcon}
          rankPlaysIcon={rankPlaysIcon}
          rankMusicalsIcon={rankMusicalsIcon}
          activityIcon={activityIcon}
          calendarIcon={calendarIcon}
        />
      } 
    />

    <Route 
      path="/admin" 
      element={
        (() => {
          console.log('App - Rendering AdminPanel');
          console.log('Current user state:', user);
          return <AdminPanel user={user} POLAND_CITIES={POLAND_CITIES} />;
        })()
      } 
    />
    
    <Route 
      path="/moderator" 
      element={
        (() => {
          console.log('App - Rendering ModPanel');
          console.log('Current user state:', user);
          return <ModeratorPanel user={user} />;
        })()
      } 
    />

    <Route 
      path="*" 
      element={<Navigate to="/" />} 
    />
  </Routes>

  <BottomMenu
    bigDiaryIcon={bigDiaryIcon}
    closeSearchModal={closeSearchModal}
    colorCircleCalendarIcon={colorCircleCalendarIcon}
    colorCircleStarIcon={colorCircleStarIcon}
    compassIcon={compassIcon}
    defaultPoster={defaultPoster}
    getRatingDescription={getRatingDescription}
    homeIcon={homeIcon}
    isLoading={isLoading}
    isSearchModalOpen={isSearchModalOpen}
    openSearchModal={openSearchModal}
    searchIcon={searchIcon}
    setIsLoading={setIsLoading}
    starIcon={starIcon}
    successIcon={successIcon}
    t={t}
    userIcon={userIcon}
  />

  <Footer
    changeLanguage={changeLanguage}
    t={t}
    logoGrey={logoGrey}
    socialInstaIcon={socialInstaIcon}
  />

  <CookieConsent t={t} />

  <WelcomeModal 
    showWelcomeModal={showWelcomeModal}
    handleCloseWelcomeModal={handleCloseWelcomeModal}
    t={t}
    welcomeModalVideo={welcomeModalVideo}
    heartIcon={heartIcon}
    calendarIcon={calendarIcon}
    bigDiaryIcon={bigDiaryIcon}
    userIcon={userIcon}
  />
</Router>

  );
};

export default App;