import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Container, Row, Col, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShowTags from './components/ShowTags';

const BestAllTimePlays = ({
  POLAND_CITIES,
  isLoading,
  getUserRatingForShow,
  starIcon,
  rankIcon,
  user,
  t,
  bigRankAllIcon,
  handleNavigation
}) => {
  const [currentCountry, setCurrentCountry] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [posterLoadingStates, setPosterLoadingStates] = useState({});
  const [playShows, setPlayShows] = useState([]);
  const [visibleEntriesCount, setVisibleEntriesCount] = useState(51);
  const [hasMore, setHasMore] = useState(true);
  const [showAllCities, setShowAllCities] = useState(false);

  // Pobieranie danych o spektaklach z API przy pierwszym załadowaniu komponentu
  useEffect(() => {
    const fetchData = async () => {
      try {
        const playResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/shows?category=plays`);
        let filteredPlays = playResponse.data;
        setPlayShows(filteredPlays);
      } catch (error) {
        console.error('Error fetching shows:', error);
      }
    };

    fetchData();
  }, []); // teraz te wartości są stabilne

  // Unikalne kraje z shows, pomijając puste wartości
  const countries = Array.from(new Set(playShows.map(show => show.country)))
    .filter(country => country && country.trim() !== '');

  // Zmodyfikowana funkcja getCitiesForCountry
  const getCitiesForCountry = (country) => {
    if (country === 'Polska') {
      // Funkcja pomocnicza do normalizacji nazw miast
      const normalizeCity = (city) => city?.trim();
      
      // Pobierz miasta z shows, ignorując null, undefined, puste wartości i miasta z przecinkami
      const citiesFromShows = Array.from(new Set(
        playShows
          .filter(show => show.country === 'Polska')
          .map(show => normalizeCity(show.location))
          .filter(city => 
            city && 
            city.length > 0 && 
            !city.includes(',')
          )
      ));

      // Znormalizuj POLAND_CITIES
      const normalizedPolandCities = POLAND_CITIES.map(normalizeCity);

      if (!showAllCities) {
        // Pokaż tylko miasta z POLAND_CITIES, które mają spektakle
        return normalizedPolandCities.filter(city =>
          citiesFromShows.includes(city)
        );
      }

      // Jeśli showAllCities jest true, pokaż wszystkie miasta
      // Najpierw miasta z POLAND_CITIES, które mają spektakle
      const mainCities = normalizedPolandCities.filter(city =>
        citiesFromShows.includes(city)
      );

      // Następnie dodatkowe miasta (nie z POLAND_CITIES)
      const additionalCities = citiesFromShows
        .filter(city => !normalizedPolandCities.includes(city))
        .sort((a, b) => a.localeCompare(b));

      return [...mainCities, ...additionalCities];
    }

    // Dla innych krajów - z poprawkami na null, undefined, puste wartości i przecinki
    return Array.from(new Set(
      playShows
        .filter(show => show.country === country)
        .map(show => show.location?.trim())
        .filter(city => 
          city && 
          city.length > 0 && 
          !city.includes(',')
        )
    )).sort((a, b) => a.localeCompare(b));
  };

  // Funkcje obsługujące filtrowanie
  const resetCountryFilter = () => {
    setCurrentCountry(null);
    setCurrentCity(null);
  };

  const resetCityFilter = () => setCurrentCity(null);

  const handleCountryChange = (country) => {
    setCurrentCountry(country);
    setCurrentCity(null); // Reset miasta przy zmianie kraju
  };

  const handleCityChange = (city) => {
    setCurrentCity(city);
  };

  // Generowanie dynamicznego nagłówka
  const getHeaderText = () => {
    if (currentCity) {
      return t('best.best_shows.city', { city: currentCity });
    }
    if (currentCountry) {
      return t('best.best_shows.country', { country: currentCountry });
    }
    return t('best.best_shows.global');
  };

  const [filteredRankedShows, filteredUnrankedShows] = useMemo(() => {
    const baseFiltered = playShows
      .filter(show => !currentCountry || show.country === currentCountry)
      .filter(show => !currentCity || (show.location && show.location.split(',').map(city => city.trim()).includes(currentCity)));

    // Zachowujemy obecne filtrowanie dla ranked shows
    const ranked = baseFiltered
      .filter(show => show.numberOfRatings >= 3)
      .sort((a, b) => b.avgRating - a.avgRating);

    // Druga grupa - bez warunku numberOfRatings
    const unranked = baseFiltered
      .filter(show => show.numberOfRatings < 3 || !show.numberOfRatings)
      .sort((a, b) => b.avgRating - a.avgRating);

    // Sprawdź całkowitą liczbę spektakli
    const totalShows = ranked.length + unranked.length;
    setHasMore(totalShows > visibleEntriesCount);

    // Najpierw wyświetl wszystkie spektakle z rankingiem, a następnie uzupełnij pozostały limit nieocenionymi
    const rankedLimit = Math.min(ranked.length, visibleEntriesCount);
    const unrankedLimit = Math.min(unranked.length, Math.max(0, visibleEntriesCount - ranked.length));

    return [
      ranked.slice(0, rankedLimit),
      unranked.slice(0, unrankedLimit)
    ];
  }, [playShows, currentCountry, currentCity, visibleEntriesCount]);

  const showsWithDynamicRank = useMemo(() => {
    return filteredRankedShows.map((show, index) => ({
      ...show,
      dynamicRank: index + 1,
    }));
  }, [filteredRankedShows]);

  const loadMoreEntries = () => {
    setVisibleEntriesCount(prevCount => prevCount + 51);
  };

  const handlePosterLoad = (showId) => {
    setPosterLoadingStates(prev => ({
      ...prev,
      [showId]: false
    }));
  };

  // Dodaj nową funkcję renderowania elementów dropdownu
  const renderCityItems = () => {
    if (!currentCountry) return null;

    const cities = getCitiesForCountry(currentCountry);

    return (
      <>
        <Dropdown.Item onClick={resetCityFilter}>{t('best.reset')}</Dropdown.Item>
        {cities.map(city => (
          <Dropdown.Item key={city} onClick={() => handleCityChange(city)}>
            {city}
          </Dropdown.Item>
        ))}
        {currentCountry === 'Polska' && !showAllCities && (
          <Dropdown.Item 
            onClick={(e) => {
              e.stopPropagation(); // Zapobiega zamknięciu dropdownu
              setShowAllCities(true);
            }}
            className="show-more-item"
          >
            {t('calendar.show-more')}
          </Dropdown.Item>
        )}
      </>
    );
  };

  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>    ;
  }

  return (
    <Container fluid className="px-3 main-container">
      <Row className="title-row mb-5 justify-content-center no-margin-row">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center title-col">
          <div className="page-title-container">
            <img src={bigRankAllIcon} alt="Best All Time" className="title-icon" />
            <h1 className="page-title">{t('best.title-alltime')}: {t('common.plays')}</h1>
          </div>
        </Col>
      </Row>
      <Row className="g-0 best-played mb-5 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
          {/* Filtracja po kraju */}
          <div className="filter-container mb-4">
            <Dropdown drop="auto">
              <Dropdown.Toggle variant="primary" id="dropdown-country">
                {currentCountry || t('best.choose-country')}
              </Dropdown.Toggle>

              <Dropdown.Menu renderMenuOnMount={true} container={document.body}>
                <Dropdown.Item onClick={resetCountryFilter}>{t('best.reset')}</Dropdown.Item>
                {countries
                  .sort((a, b) =>
                    playShows.filter(show => show.country === b).length - 
                    playShows.filter(show => show.country === a).length
                  ).map(country => (
                  <Dropdown.Item key={country} onClick={() => handleCountryChange(country)}>
                    {country}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {/* Filtracja po mieście (jeśli wybrano kraj) */}
            {currentCountry && (
              <Dropdown drop="auto">
                <Dropdown.Toggle variant="light" id="dropdown-city">
                  {currentCity || t('best.choose-city')}
                </Dropdown.Toggle>

                <Dropdown.Menu renderMenuOnMount={true} container={document.body}>
                  {renderCityItems()}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>

          {/* Dynamiczny nagłówek */}
          <h2 className="dynamic-header mb-5 mt-5">{getHeaderText()}</h2>
        </Col>

        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="best-played-col resp-pre-xl">
          {/* Sekcja ze spektaklami z rankingiem - zachowujemy obecny wygląd */}
          {!isLoading && showsWithDynamicRank.length > 0 && (
            <>
              {showsWithDynamicRank.map(show => (
                <div key={show._id} className="show-card" onClick={() => handleNavigation(`/shows/${show.slug}`)}>
                  <div className="show-poster">
                    <Link to={`/shows/${show.slug}`} className="show-link">
                      <div className="poster-container">
                        <div className={`skeleton-loader ${posterLoadingStates[show._id] !== false ? 'visible' : ''}`} />
                        <img 
                          key={show._id}
                          src={show.poster && show.poster.includes('http') 
                            ? show.poster 
                            : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`}
                          alt={show.title}
                          className={`poster-img ${posterLoadingStates[show._id] === false ? 'visible' : 'hidden'}`}
                          onLoad={() => handlePosterLoad(show._id)}
                          onError={() => handlePosterLoad(show._id)}
                        />
                      </div>
                    </Link>
                    {user && getUserRatingForShow(show._id) ? (
                      <div className="user-rating-badge">
                        <span>{getUserRatingForShow(show._id)}</span>
                      </div>
                    ) : (
                      <div className="user-rating-badge-star">
                        <img src={starIcon} alt="Rating" className="rating-star-badge" />
                      </div>
                    )}
                  </div>

                  <div className="show-info">
                    <div className="ranking">
                      <img src={rankIcon} alt="Rating" className="rank-icon" />
                      <span>{show.dynamicRank}.</span>
                    </div>
                    <div className="show-info-basic">
                      <Link to={`/shows/${show.slug}`} className="show-link">
                        <h5 className="show-title">{show.title}</h5>
                      </Link>
                      <div className="theater-info">{show.theater}</div>
                      <div className="location">{show.location}</div>
                    </div>
                    <div className="rating">
                      <img src={starIcon} alt="Rating" className="rating-star" />
                      <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
                    </div>
                    {(show.showCategory || show.showType || show.showForKids || show.showOrigin) && (
                      <div className="tags">
                        <ShowTags show={show} t={t} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}

          {/* Sekcja ze spektaklami bez rankingu - uproszczony wygląd */}
          {!isLoading && filteredUnrankedShows.length > 0 && (
            <>
              {filteredUnrankedShows.map(show => (
                <div key={show._id} className="show-card" onClick={() => handleNavigation(`/shows/${show.slug}`)}>
                  <div className="show-poster">
                    <Link to={`/shows/${show.slug}`} className="show-link">
                      <div className="poster-container">
                        <div className={`skeleton-loader ${posterLoadingStates[show._id] !== false ? 'visible' : ''}`} />
                        <img 
                          key={show._id}
                          src={show.poster && show.poster.includes('http') 
                            ? show.poster 
                            : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`}
                          alt={show.title}
                          className={`poster-img ${posterLoadingStates[show._id] === false ? 'visible' : 'hidden'}`}
                          onLoad={() => handlePosterLoad(show._id)}
                          onError={() => handlePosterLoad(show._id)}
                        />
                      </div>
                    </Link>
                    {user && getUserRatingForShow(show._id) && (
                      <div className="user-rating-badge">
                        <span>{getUserRatingForShow(show._id)}</span>
                      </div>
                    )}
                  </div>

                  <div className="show-info">
                    <div className="show-info-basic">
                      <Link to={`/shows/${show.slug}`} className="show-link">
                        <h5 className="show-title">{show.title}</h5>
                      </Link>
                      <div className="theater-info">{show.theater}</div>
                      <div className="location">{show.location}</div>
                    </div>
                    {(show.showCategory || show.showType || show.showForKids || show.showOrigin) && (
                      <div className="tags">
                        <ShowTags show={show} t={t} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}

          {!isLoading && filteredRankedShows.length === 0 && filteredUnrankedShows.length === 0 && (
            <div className="no-shows-av">{t('best.no-shows')}</div>
          )}
        </Col>
      </Row>

      <Row className="g-0 best-played mb-5 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center title-col">
          {hasMore && (
            <div className="text-center mt-3 mb-4 show-more-entries">
              <Button onClick={loadMoreEntries}>{t('buttons.show_more')}</Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default BestAllTimePlays;
