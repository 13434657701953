import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import theaterLogoRoma from './img/logos/theater-pl-warszawa-roma.jpg';
import theaterLogoGdynia from './img/logos/theater-pl-gdynia-gdynia.png';
import theaterLogoLodz from './img/logos/theater-pl-lodz-lodz.png';
import theaterLogoSyrena from './img/logos/theater-pl-warszawa-syrena.jpg';
import theaterLogoPoznan from './img/logos/theater-pl-poznan-poznan.jpg';
import theaterLogoRozrywki from './img/logos/theater-pl-chorzow-rozrywki.png';
import theaterLogoCapitol from './img/logos/theater-pl-wroclaw-capitol.jpeg';

import onboarding1 from './img/onboarding/onboarding1.png';
import onboarding2 from './img/onboarding/onboarding2.png';
import onboarding3 from './img/onboarding/onboarding3.png';
import onboarding5 from './img/onboarding/onboarding5.png';


const About = ( {
  t,
  welcomeModalVideo
} ) => {
  return (
    <Container fluid className="about-page px-3 mb-5 ">
                  <Row className="title-row mb-5 justify-content-center no-margin-row">
              <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center title-col">
                <div className="page-title-container">
                  <h2 className="page-subtitle">{t('about.title')}</h2>
                </div>
                <div className="">
                  <h1 className="page-title">{t('about.tagline')}</h1>
                </div>
              </Col>
            </Row>
      <Row className="justify-content-center mt-5">
      <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
          <div className="row-about mb-5">
            <div className="left">
          <video 
                autoPlay 
                muted 
                playsInline
                className="welcome-modal-image"
              >
                <source src={welcomeModalVideo} type="video/mp4" />
              </video>
              </div>
              <div className="right">
                <h2 className="about-section-title">{t('about.about-about-title')}</h2>
            <img src={`${process.env.REACT_APP_API_BASE_URL}/img/about.png`} className="about-img display-none" alt="About" />
            <p>{t('about.about-1')}</p>
            <p>{t('about.about-2')}</p>
            <p>{t('about.about-3')}</p>
            <p><strong>{t('about.about-4')}</strong></p>
            
            </div>
          </div>
          <div className="row-numbers mt-5 mb-5">
            <div className="number-container">
              <span className="number">250+</span>
              <span className="description">{t('about.row-numbers.shows')}</span>
            </div>
            <div className="number-container">
              <span className="number">80+</span>
              <span className="description">{t('about.row-numbers.theaters')}</span>
            </div>
            <div className="number-container">
              <span className="number">3</span>
              <span className="description">{t('about.row-numbers.countries')}</span>
            </div>
          </div>

          <div className="row-theaters mt-5 mb-5">
            <div className="about-section-header mb-3">

            
          <h2 className="about-section-title mt-1 mb-4">{t('about.row-theaters.title')}</h2>
          <p className="about-section-subtitle">{t('about.row-theaters.subtitle')}</p>
          </div>
            <div className="theaters mt-3">
          
          <img src={theaterLogoRoma} className="theater-logo l-roma" alt="Theater" />
          <img src={theaterLogoGdynia} className="theater-logo l-gdynia" alt="Theater" />
          <img src={theaterLogoLodz} className="theater-logo l-lodz" alt="Theater" />
          <img src={theaterLogoSyrena} className="theater-logo l-syrena" alt="Theater" />
          <img src={theaterLogoPoznan} className="theater-logo l-poznan" alt="Theater" />
          <img src={theaterLogoRozrywki} className="theater-logo l-rozrywki" alt="Theater" />
          <img src={theaterLogoCapitol} className="theater-logo l-capitol" alt="Theater" />

          </div>
          </div>

          <div className=" mt-5 mb-5">
            <div className="about-section-header mb-3">

            
          <h2 className="about-section-title mt-1 mb-4">{t('about.row-features.title')}</h2>
          <p className="about-section-subtitle">
          {t('about.row-features.subtitle')}
          </p>
          </div>
          </div>

          <div className="row-features mb-5">
          <div className="feature-container">
              <div className="feature-image-container">
                <img src={onboarding1} alt="Onboarding1" className="feature-image"/>
              </div>
              <span className="feature">{t('onboarding.step0.title')}</span>
              <span className="description">{t('onboarding.step0.description')}</span>
            </div>
            <div className="feature-container">
              <div className="feature-image-container">
                <img src={onboarding2} alt="Onboarding1" className="feature-image"/>
              </div>
              <span className="feature">{t('onboarding.step2.title')}</span>
              <span className="description">{t('onboarding.step2.description')}</span>
            </div>
            <div className="feature-container">
              <div className="feature-image-container">
                <img src={onboarding3} alt="Onboarding1" className="feature-image"/>
              </div>
              <span className="feature">{t('onboarding.step3.title')}</span>
              <span className="description">{t('onboarding.step3.description')}</span>
            </div>
            <div className="feature-container">
              <div className="feature-image-container">
                <img src={onboarding5} alt="Onboarding1" className="feature-image"/>
              </div>
              <span className="feature">{t('onboarding.step5.title')}</span>
              <span className="description">{t('onboarding.step5.description')}</span>
            </div>
          </div>


          <div className="row-contact mt-5 mb-5">
            <div className="about-section-header mb-3">

            
          <h2 className="about-section-title mt-1">{t('about.row-contact.title')}</h2>
          <h3 className="about-section-subtitle contact-info"><strong>{t('about.row-contact.subtitle')}</strong></h3>
          </div>
          </div>

        </Col>
      </Row>


    </Container>
  );
};

export default About;
