import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { Modal as BootstrapModal } from 'react-bootstrap';
import axios from 'axios';
import { FormControl, InputGroup, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import AddCustomShowModal from './AddCustomShowModal';
import plusIcon from './img/icons/plus.svg';
import ShowTags from './components/ShowTags';




const SearchModal = ({ isOpen, onRequestClose, starIcon, searchIcon, defaultPoster, colorCircleCalendarIcon, colorCircleStarIcon, successIcon, getRatingDescription, currentLocale }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [popularShows, setPopularShows] = useState([]);
  const [visibleShows, setVisibleShows] = useState(5);
  const [customModalIsOpen, setCustomModalIsOpen] = useState(false);
  const [unverifiedResults, setUnverifiedResults] = useState([]);
  const [showUnverified, setShowUnverified] = useState(false);
  const [showUnverifiedModal, setShowUnverifiedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [exactMatches, setExactMatches] = useState([]);
  const [startMatches, setStartMatches] = useState([]);
  const [wordMatches, setWordMatches] = useState([]);
  const [partialMatches, setPartialMatches] = useState([]);

  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const capitalizeWords = (str) => {
    return str.replace(/(^|\s)[^\s]/g, (match) => match.toUpperCase());
  };

  const abortControllerRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const fetchPopularShows = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/popular-shows`);
  
        const shuffledShows = response.data.sort(() => Math.random() - 0.5);
  
        setPopularShows(shuffledShows);
      } catch (error) {
        console.error('Error fetching popular shows:', error);
      }
    };
  
    fetchPopularShows();
  }, []);

  const handleLoadMore = () => {
    setVisibleShows((prevVisibleShows) => prevVisibleShows + 5);
  };

  useEffect(() => {
    if (isOpen) {
      setSearchTerm('');
    }
  }, [isOpen]);

  useEffect(() => {
    const handleSearch = async () => {
      if (searchTerm.length >= 1) {
        try {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          abortControllerRef.current = new AbortController();
          setIsLoading(true);

          await new Promise((resolve) => {
            timeoutRef.current = setTimeout(resolve, 300);
          });

          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/shows`,
            {
              params: { title: searchTerm.trim() },
              signal: abortControllerRef.current.signal
            }
          );

          const exact = [];
          const start = [];
          const word = [];
          const partial = [];
          
          response.data.forEach(show => {
            switch(show.score) {
              case 4:
                exact.push(show);
                break;
              case 3:
                start.push(show);
                break;
              case 2:
                word.push(show);
                break;
              case 1:
                partial.push(show);
                break;
              default:
                break;
            }
          });

          const sortByRating = (a, b) => b.avgRating - a.avgRating;
          
          setExactMatches(exact.sort(sortByRating));
          setStartMatches(start.sort(sortByRating));
          setWordMatches(word.sort(sortByRating));
          setPartialMatches(partial.sort(sortByRating));
          
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error('Error searching shows:', error);
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setExactMatches([]);
        setStartMatches([]);
        setWordMatches([]);
        setPartialMatches([]);
        setIsLoading(false);
      }
    };

    handleSearch();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [searchTerm]);

  const allSearchResults = [...exactMatches, ...startMatches, ...wordMatches, ...partialMatches];

  useEffect(() => {
    if (searchTerm.length >= 1 && showUnverified) {
      const fetchUnverifiedResults = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows/unverified`, {
            params: { title: searchTerm },
          });
          setUnverifiedResults(response.data);
        } catch (error) {
          console.error('Error searching unverified shows:', error);
        }
      };

      fetchUnverifiedResults();
    }
  }, [searchTerm, showUnverified]);

  const { t } = useTranslation();


  const handleShowClick = (slug) => {
    navigate(`/shows/${slug}`);
    onRequestClose();
  };

  const handleAddToDiaryClick = (slug) => {
    if (slug) {
      navigate(`/addToDiary?slug=${slug}`);
    } else {
      console.error('Slug is missing or invalid:');
    }
  };

  const handleOpenCustomModal = () => {
    setCustomModalIsOpen(true);
  };

  const handleCloseCustomModal = () => {
    setCustomModalIsOpen(false);
  };

  const handleShowUnverified = () => {
    setShowUnverifiedModal(true);
  };

  const handleConfirmUnverified = () => {
    setShowUnverified(true);
    setShowUnverifiedModal(false);
  };

  const renderShowItem = (show, isUnverified = false) => (
    <div 
      key={show._id}
      className={`search-result-item ${isUnverified ? 'unverified-show' : ''}`}
      onClick={() => show.isVerified ? handleShowClick(show.slug) : handleAddToDiaryClick(show.slug)}
      style={{ cursor: 'pointer' }}
    >
      <div className="show-poster">
        <img
          src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
          alt={show.title}
          className="poster-img"
        />
      </div>
      <div className="show-info">
        {isUnverified && <div className="label-unverified">{t('add_to_diary.unverified-show')}</div>}
        <h5 className="show-title">{show.title}</h5>
        <div className="theater-info">
        {show.theater && show.location ? (
          <>
            <span className="theater-info">{show.theater}</span>,{' '}
            <span className="location-info">{show.location}</span>
          </>
        ) : (
          <>
            {show.theater && <span className="theater-info">{show.theater}</span>}
            {show.location && <span className="location-info">{show.location}</span>}
          </>
        )}
        </div>
        <div className="rating mb-2">
          {show.avgRating > 0 && (
            <>
              <img src={starIcon} alt="Rating" className="rating-star" />
              <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
            </>
          )}
        </div>
        <div className="show-tags mt-1">
        <ShowTags show={show} t={t} />
        </div>
      </div>
    </div>
  );

  return (
    <>
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="search-modal"
      overlayClassName="search-modal-overlay"
      
    >
      <div className="search-modal-header-container">
      <div className="search-modal-header resp-regular">
        <InputGroup className="search-input-group">
          <FormControl
            placeholder={t('search.placeholder')} 
            aria-label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
            style={{ paddingLeft: '40px' }}
          />
          <img src={searchIcon} alt="Search" className="search-icon" />
        </InputGroup>
        <button className="close-modal-button" onClick={onRequestClose}>
          &times;
        </button>
      </div>
      </div>
      {searchTerm.length > 0 && (
        <>
        <div className="search-results-container">
          <div className="search-modal-results resp-regular">
            <h3 className="section-subtitle">{t('modals.search_modal.search_results')}
            :</h3>
            {allSearchResults.map(show => renderShowItem(show))}

<div 
                    className="search-result-item special-result-item"
                    onClick={handleOpenCustomModal}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="show-poster">
                      <img
                        src={defaultPoster}
                        alt="Default poster"
                        className="poster-img"
                      />
                    </div>
                    <div className="show-info">
                      <p className="theater-info">{t('add_to_diary.cant-find')}</p>
                      <p className="theater-info-add">{t('add_to_diary.add-yourself')}:</p>
                      <h5 className="show-title">{capitalizeWords(searchTerm)}</h5>
                    </div>
                    <img src={plusIcon} alt="Add new show" className="search-results-icon" />
                  </div>

              {!showUnverified && searchTerm.length > 0 && (
                <div 
                  className="search-unverified-link mt-3"
                  onClick={handleShowUnverified}
                >
                  {t('add_to_diary.search_unverified')}
                </div>
              )}

              {showUnverified && unverifiedResults.length > 0 && (
                <div className="unverified-section mt-3">
                  <h3 className="section-subtitle">
                    {t('add_to_diary.unverified_results')}:
                  </h3>
                  {unverifiedResults.map(show => renderShowItem(show, true))}
                </div>
              )}
          </div>
          
          </div>
        </>
      )}

      {searchTerm.length === 0 && (
        <>
        <div className="search-results-container">
          <div className="popular-shows resp-regular">
            <h3 className="section-subtitle">{t('modals.search_modal.popular')}:</h3>
            <div>
              {popularShows.slice(0, visibleShows).map((show) => (
                <div
                  key={show._id}
                  className="search-result-item"
                  onClick={() => show.isVerified ? handleShowClick(show.slug) : handleAddToDiaryClick(show.slug)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="show-poster">
                    <img
                      src={show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                      alt={show.title}
                      className="poster-img"
                    />
                  </div>
                  <div className="show-info">
                    <h5 className="show-title">{show.title}</h5>
                    <div className="theater-info">
        {show.theater && show.location ? (
          <>
            <span className="theater-info">{show.theater}</span>,{' '}
            <span className="location-info">{show.location}</span>
          </>
        ) : (
          <>
            {show.theater && <span className="theater-info">{show.theater}</span>}
            {show.location && <span className="location-info">{show.location}</span>}
          </>
        )}
        </div>
        <div className="rating mb-2">
          {show.avgRating > 0 && (
            <>
              <img src={starIcon} alt="Rating" className="rating-star" />
              <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
            </>
          )}
        </div>
        <div className="show-tags mt-1">
        <ShowTags show={show} t={t} />
        </div>
                  </div>
                </div>
              ))}
            </div>
            {visibleShows < popularShows.length && (
              <Button onClick={handleLoadMore} className="load-more-btn">
                {t('modals.search_modal.get_more')}
              </Button>
            )}
          </div>
          </div>
        </>
      )}
      
    </Modal>

    <BootstrapModal
      show={showUnverifiedModal}
      onHide={() => setShowUnverifiedModal(false)}
      className="add-custom-show-modal"
      centered
      backdrop="static"
    >
      <BootstrapModal.Header closeButton>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <h1 className="mb-4 mt-4">{t('add_to_diary.unverified_warning_title')}</h1>
        <p className="mb-4">{t('add_to_diary.unverified_warning_body')}</p>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={handleConfirmUnverified}>
            {t('common.continue')}
          </button>
          <button className="btn btn-secondary" onClick={() => setShowUnverifiedModal(false)}>
            {t('common.cancel')}
          </button>
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>

    <AddCustomShowModal
isOpen={customModalIsOpen}
onClose={handleCloseCustomModal}
defaultPoster={defaultPoster}
initialTitle={capitalizeWords(searchTerm)}
colorCircleCalendarIcon={colorCircleCalendarIcon} 
colorCircleStarIcon={colorCircleStarIcon} 
starIcon={starIcon} 
getRatingDescription={getRatingDescription}
successIcon={successIcon}
currentLocale={currentLocale}
t={t}
isLoading={isLoading}
setIsLoading={setIsLoading}
isSuccess={isSuccess}
setIsSuccess={setIsSuccess}
confirmationMsg={confirmationMessage}
setConfirmationMsg={setConfirmationMessage}
/>

</>

  );
};

export default SearchModal;