import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Container, Table, Form, Button, Row, Col, Collapse, Modal, Alert } from 'react-bootstrap';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

const AdminPanel = ({ user, POLAND_CITIES }) => {
  const [shows, setShows] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [verificationFilter, setVerificationFilter] = useState('all');
  const [editingShow, setEditingShow] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [sortField, setSortField] = useState('title');
  const [sortDirection, setSortDirection] = useState('asc');
  const [expandedRows, setExpandedRows] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showToDelete, setShowToDelete] = useState(null);
  const [posterFile, setPosterFile] = useState(null);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [mergeData, setMergeData] = useState({
    correctSlug: '',
    duplicateSlugs: ''
  });
  const [mergeResult, setMergeResult] = useState(null);
  const [isMerging, setIsMerging] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_PAGE = 20;
  const abortControllerRef = useRef(null);
  const timeoutRef = useRef(null);
  const [moderatorChanges, setModeratorChanges] = useState([]);
  const [showModeratorChanges, setShowModeratorChanges] = useState(false);
  const [, setChangesPage] = useState(1);
  const [hasMoreChanges, setHasMoreChanges] = useState(true);
  const [isLoadingChanges, setIsLoadingChanges] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newShow, setNewShow] = useState({
    title: '',
    theater: '',
    location: '',
    poster: null,
    background: null,
    country: '',
    showCategory: [],
    showType: [],
    showOrigin: [],
    showForKids: false,
    isVerified: false,
    currentlyPlayed: false,
    ticketUrl: '',
    about: '',
    direction: '',
    author: ''
  });
  const [countryFieldClicked, setCountryFieldClicked] = useState({});
  const [newShowCountryClicked, setNewShowCountryClicked] = useState(false);
  const currentChangesPageRef = useRef(1);
  const [showCastModal, setShowCastModal] = useState(false);
  const [castData, setCastData] = useState({
    showSlug: '',
    cast: [{
      fullName: '',
      slug: '',
      photoUrl: '',
      order: '',
      exists: false
    }]
  });

  const fetchShows = useCallback(async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      abortControllerRef.current = new AbortController();
      
      if (page === 1) {
        setIsSearching(true);
      } else {
        setIsLoadingMore(true);
      }

      await new Promise((resolve) => {
        timeoutRef.current = setTimeout(resolve, 300);
      });

      const params = new URLSearchParams();
      if (searchTitle) params.append('title', searchTitle.trim());
      params.append('page', page);
      params.append('limit', ITEMS_PER_PAGE);
      
      switch(verificationFilter) {
        case 'verified':
          params.append('isVerified', 'true');
          break;
        case 'softVerified':
          params.append('onlySoftVerified', 'true');
          break;
        case 'unverified':
          params.append('completelyUnverified', 'true');
          break;
        default:
          break;
      }
      
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/admin/shows?${params.toString()}`, {
        headers: { 'Authorization': `Bearer ${token}` },
        signal: abortControllerRef.current.signal
      });

      const sortedShows = response.data.shows.sort((a, b) => {
        if (a.score !== b.score) {
          return b.score - a.score;
        }
        return (b.avgRating || 0) - (a.avgRating || 0);
      });

      setShows(prevShows => page === 1 ? sortedShows : [...prevShows, ...sortedShows]);
      setHasMore(response.data.shows.length === ITEMS_PER_PAGE);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error fetching shows:', error);
      }
    } finally {
      setIsInitialLoading(false);
      setIsSearching(false);
      setIsLoadingMore(false);
    }
  }, [searchTitle, verificationFilter, page]);

  useEffect(() => {
    setPage(1);
    setShows([]);
  }, [searchTitle, verificationFilter]);

  useEffect(() => {
    fetchShows();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [fetchShows]);

  const fetchModeratorChanges = useCallback(async (loadMore = false) => {
    try {
      setIsLoadingChanges(true);
      const token = localStorage.getItem('token');
      
      const pageToFetch = loadMore ? currentChangesPageRef.current + 1 : 1;
      
      const response = await axios.get(`/api/admin/moderator-changes?page=${pageToFetch}&limit=10`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      setModeratorChanges(prev => loadMore ? [...prev, ...response.data.changes] : response.data.changes);
      setHasMoreChanges(response.data.hasMore);
      
      currentChangesPageRef.current = pageToFetch;
      setChangesPage(pageToFetch);
    } catch (error) {
      console.error('Error fetching moderator changes:', error);
    } finally {
      setIsLoadingChanges(false);
    }
  }, []);

  const handleLoadMoreChanges = useCallback(() => {
    fetchModeratorChanges(true);
  }, [fetchModeratorChanges]);

  useEffect(() => {
    if (showModeratorChanges) {
      currentChangesPageRef.current = 1;
      fetchModeratorChanges(false);
    }
  }, [showModeratorChanges, fetchModeratorChanges]);

  const handleModeratorChange = async (changeId, status) => {
    try {
      if (!changeId) {
        throw new Error('Brak ID zmiany');
      }

      const comment = prompt(
        status === 'approved' 
          ? 'Dodaj opcjonalny komentarz do zatwierdzenia:' 
          : 'Podaj powód odrzucenia:'
      );

      const token = localStorage.getItem('token');
      const response = await axios.put(
        `/api/admin/moderator-changes/${changeId}`, 
        { status, adminComment: comment || '' },
        { headers: { 'Authorization': `Bearer ${token}` }}
      );

      if (response.data.success) {
        const changedItem = moderatorChanges.find(c => c._id === changeId);
        
        setModeratorChanges(prevChanges => 
          prevChanges.map(change => 
            change._id === changeId 
              ? { ...change, status, adminComment: comment || '' }
              : change
          )
        );
        
        if (changedItem?.show && status === 'approved') {
          await fetchShows();
        }

        alert(status === 'approved' ? 'Zmiany zostały zatwierdzone' : 'Zmiany zostały odrzucone');
      } else {
        throw new Error(response.data.message || 'Wystąpił błąd podczas przetwarzania zmiany');
      }
    } catch (error) {
      console.error('Error processing moderator change:', error);
      alert('Wystąpił błąd podczas przetwarzania zmiany: ' + (error.response?.data?.message || error.message));
    }
  };

  if (!user || (user._id !== '66c1121aa065d115e8be53ee' && 
      user.googleId !== '116667037246245615153')) {
    return null;
  }

  const handleSort = (field) => {
    if (field === 'title') {
      const sortedShows = [...shows].sort((a, b) => {
        const direction = sortDirection === 'asc' ? 1 : -1;
        return direction * a.title.localeCompare(b.title);
      });
      setShows(sortedShows);
    }
    setSortDirection(field === sortField ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc');
    setSortField(field);
  };

  const toggleRowExpand = (showId) => {
    setExpandedRows(prev => ({
      ...prev,
      [showId]: !prev[showId]
    }));
  };

  const handleEdit = (show) => {
    setEditingShow(show);
    setEditedValues({});
    setCountryFieldClicked(prev => ({...prev, [show._id]: false}));
  };

  const handleDelete = async (id) => {
    setShowToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/admin/shows/${showToDelete}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setShows(shows.filter(show => show._id !== showToDelete));
      setShowDeleteModal(false);
      setShowToDelete(null);
    } catch (error) {
      console.error('Error deleting show:', error);
    }
  };

  const handleInputChange = (field, value) => {
    setEditedValues(prev => {
      if (Array.isArray(value) && value.length === 0) {
        const newValues = { ...prev };
        delete newValues[field];
        return newValues;
      }
      if (typeof value === 'boolean' && !value && ['showForKids'].includes(field)) {
        const newValues = { ...prev };
        delete newValues[field];
        return newValues;
      }
      return {
        ...prev,
        [field]: value
      };
    });
  };

  const handlePosterChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPosterFile(file);
      handleInputChange('poster', file);
    }
  };

  const handleBackgroundChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackgroundFile(file);
      handleInputChange('background', file);
    }
  };

  const handlePosterUrlChange = (e) => {
    const url = e.target.value;
    setPosterFile(null);
    handleInputChange('poster', url);
  };

  const handleBackgroundUrlChange = (e) => {
    const url = e.target.value;
    setBackgroundFile(null);
    handleInputChange('background', url);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      
      Object.keys(editedValues).forEach(key => {
        if (key === 'poster' && posterFile) {
          formData.append('poster', posterFile);
        } else if (key === 'background' && backgroundFile) {
          formData.append('background', backgroundFile);
        } else if (key === 'tags' && Array.isArray(editedValues[key])) {
          formData.append('tags', JSON.stringify(editedValues[key]));
        } else {
          formData.append(key, editedValues[key]);
        }
      });

      const response = await axios.put(
        `/api/admin/shows/${editingShow._id}`, 
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      
      setShows(shows.map(show => 
        show._id === editingShow._id ? response.data : show
      ));
      setEditingShow(null);
      setEditedValues({});
      setPosterFile(null);
      setBackgroundFile(null);
    } catch (error) {
      console.error('Error updating show:', error);
    }
  };

  const handleMergeSubmit = async () => {
    try {
      setIsMerging(true);
      const token = localStorage.getItem('token');
      const duplicateSlugsArray = mergeData.duplicateSlugs
        .split(',')
        .map(slug => slug.trim())
        .filter(slug => slug.length > 0);

      const response = await axios.post(
        '/api/admin/shows/merge',
        {
          correctSlug: mergeData.correctSlug,
          duplicateSlugs: duplicateSlugsArray
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      setMergeResult(response.data);
      await fetchShows();
    } catch (error) {
      setMergeResult({
        success: false,
        error: error.response?.data?.message || 'Wystąpił błąd podczas łączenia spektakli'
      });
    } finally {
      setIsMerging(false);
    }
  };

  const SHOW_CATEGORIES = ['play', 'musical', 'opera', 'concert', 'other'];
  const SHOW_TYPES = ['comedy', 'jukebox', 'revue'];
  const SHOW_ORIGINS = ['broadway', 'west-end', 'polish', 'german', 'korean'];

  const getStatusStyle = (status) => {
    switch (status) {
      case 'approved':
        return { color: '#198754' }; // zielony
      case 'rejected':
        return { color: '#dc3545' }; // czerwony
      default:
        return {};
    }
  };

  const handleAddShow = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      
      // Podstawowa walidacja
      if (!newShow.title.trim()) {
        throw new Error('Tytuł jest wymagany');
      }

      // Dodaj wszystkie pola do FormData
      Object.keys(newShow).forEach(key => {
        if (key === 'poster' || key === 'background') {
          if (newShow[key]) {
            formData.append(key, newShow[key]);
          }
        } else if (Array.isArray(newShow[key])) {
          // Konwertuj tablice na JSON string
          formData.append(key, JSON.stringify(newShow[key]));
        } else if (typeof newShow[key] === 'boolean') {
          // Konwertuj wartości boolean na string
          formData.append(key, newShow[key].toString());
        } else {
          formData.append(key, newShow[key]);
        }
      });

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Brak tokenu autoryzacji');
      }

      const response = await axios.post(
        '/api/admin/shows', // Zmieniony endpoint
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (!response.data) {
        throw new Error('Brak odpowiedzi z serwera');
      }

      // Odśwież listę spektakli
      await fetchShows();

      // Resetuj formularz i zamknij modal
      setShowAddModal(false);
      setNewShow({
        title: '',
        theater: '',
        location: '',
        poster: null,
        background: null,
        country: '',
        showCategory: [],
        showType: [],
        showOrigin: [],
        showForKids: false,
        isVerified: false,
        currentlyPlayed: false,
        ticketUrl: '',
        about: '',
        direction: '',
        author: ''
      });
      setNewShowCountryClicked(false);

      alert('Spektakl został pomyślnie dodany!');
    } catch (error) {
      console.error('Szczegóły błędu:', error);
      alert('Wystąpił błąd podczas dodawania spektaklu: ' + 
        (error.response?.data?.message || error.message || 'Nieznany błąd'));
    }
  };

  // Zaktualizuj obsługę plików w formularzu
  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      setNewShow(prev => ({
        ...prev,
        [field]: file
      }));
    }
  };

  const getCountryFromLocation = (location) => {
    if (!location) return null;
    
    // Sprawdź miasta w Polsce
    if (POLAND_CITIES.includes(location)) {
      return 'Polska';
    }
    
    // Sprawdź inne miasta
    switch (location) {
      case 'London':
        return 'UK';
      case 'New York':
        return 'USA';
      default:
        return null;
    }
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setNewShowCountryClicked(false);
    setNewShow({
      title: '',
      theater: '',
      location: '',
      poster: null,
      background: null,
      country: '',
      showCategory: [],
      showType: [],
      showOrigin: [],
      showForKids: false,
      isVerified: false,
      currentlyPlayed: false,
      ticketUrl: '',
      about: '',
      direction: '',
      author: ''
    });
  };

  const generateSlug = (fullName) => {
    const nameParts = fullName.split(' ');
    const lastName = nameParts.pop(); // Bierze ostatni element jako nazwisko
    const firstName = nameParts.join(' '); // Łączy pozostałe części jako imię/imiona
    
    const slugParts = [lastName, firstName].filter(part => part); // Usuwa puste stringi
    
    const polishChars = {
      'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 
      'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
      'Ą': 'A', 'Ć': 'C', 'Ę': 'E', 'Ł': 'L', 'Ń': 'N',
      'Ó': 'O', 'Ś': 'S', 'Ź': 'Z', 'Ż': 'Z'
    };

    return slugParts
      .join('-')
      .toLowerCase()
      .split('')
      .map(char => polishChars[char] || char) // Zamienia polskie znaki na odpowiedniki
      .join('')
      .replace(/[^a-z0-9-]/g, '') // Usuwa wszystkie znaki oprócz małych liter, cyfr i myślników
      .replace(/-+/g, '-'); // Zamienia wielokrotne myślniki na pojedynczy
  };

  const checkSlugExists = async (slug) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/admin/cast/${slug}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data.exists;
    } catch (error) {
      console.error('Error checking slug:', error);
      return false;
    }
  };

  const handleCastNameChange = async (index, value) => {
    const newCast = [...castData.cast];
    newCast[index].fullName = value;
    const generatedSlug = generateSlug(value);
    newCast[index].slug = generatedSlug;
    
    // Sprawdź czy slug istnieje
    const exists = await checkSlugExists(generatedSlug);
    newCast[index].exists = exists;
    
    setCastData({
      ...castData,
      cast: newCast
    });
  };

  const handleCastSlugChange = (index, value) => {
    const newCast = [...castData.cast];
    newCast[index].slug = value;
    setCastData({
      ...castData,
      cast: newCast
    });
  };

  const handleCastPhotoChange = (index, file) => {
    const newCast = [...castData.cast];
    newCast[index].photo = file; // Zapisujemy plik tymczasowo
    setCastData({
      ...castData,
      cast: newCast
    });
  };

  const handleCastOrderChange = (index, value) => {
    const newCast = [...castData.cast];
    newCast[index].order = value;
    setCastData({
      ...castData,
      cast: newCast
    });
  };

  const addCastMember = () => {
    setCastData({
      ...castData,
      cast: [
        ...castData.cast,
        {
          fullName: '',
          slug: '',
          photoUrl: '',
          order: '',
          exists: false
        }
      ]
    });
  };

  const handleSaveCast = async () => {
    try {
      const token = localStorage.getItem('token');
      
      // Uploaduj zdjęcia tylko dla nowych członków obsady
      const newCastMembers = castData.cast.filter(member => !member.exists);
      for (let member of newCastMembers) {
        if (member.photo && !member.photoUrl) { // Sprawdź czy zdjęcie nie zostało już uploadowane
          const formData = new FormData();
          formData.append('photo', member.photo);
          
          const response = await axios.post(
            '/api/admin/cast/upload',
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          member.photoUrl = response.data.url;
          delete member.photo; // Usuń plik po uploadzie
        }
      }

      // Dodaj nowych członków obsady
      if (newCastMembers.length > 0) {
        await axios.post('/api/admin/cast/batch', {
          cast: newCastMembers.map(member => ({
            firstName: member.fullName.split(' ').slice(0, -1).join(' '),
            lastName: member.fullName.split(' ').slice(-1)[0],
            photoUrl: member.photoUrl
          }))
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
      }

      // Przypisz wszystkich członków obsady do spektaklu
      await axios.post(`/api/admin/shows/${castData.showSlug}/cast`, {
        cast: castData.cast.map(member => ({
          slug: member.slug,
          order: parseInt(member.order) || 0
        }))
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      setShowCastModal(false);
      setCastData({
        showSlug: '',
        cast: [{
          fullName: '',
          slug: '',
          photoUrl: '',
          photo: null,
          order: '',
          exists: false
        }]
      });
      
      alert('Obsada została pomyślnie dodana!');
    } catch (error) {
      console.error('Error saving cast:', error);
      alert('Wystąpił błąd podczas zapisywania obsady: ' + (error.response?.data?.message || error.message));
    }
  };

  if (isInitialLoading) {
    return <Container>Ładowanie...</Container>;
  }

  return (
    <Container fluid className="admin-panel px-3 mb-5">
      <Row className="mb-4">
        <Col md={4}>
          <Form.Group>
            <Form.Label>Wyszukaj po tytule</Form.Label>
            <Form.Control
              type="text"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              placeholder="Wpisz tytuł..."
            />
            {isSearching && <div className="mt-2">Wyszukiwanie...</div>}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>Status weryfikacji</Form.Label>
            <Form.Select
              value={verificationFilter}
              onChange={(e) => setVerificationFilter(e.target.value)}
            >
              <option value="all">Wszystkie</option>
              <option value="verified">Zweryfikowane</option>
              <option value="softVerified">Zostawione na platformie</option>
              <option value="unverified">Niezweryfikowane</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className="mb-3 admin-duplicate">
        <Button 
        variant="info" 
        onClick={() => setShowAddModal(true)}
        className="mt-4"
      >
        Dodaj nowy spektakl
      </Button>
      <Button 
            variant="info" 
            onClick={() => setShowCastModal(true)}
            className="mt-4"
          >
            Dodaj obsadę
          </Button>
          <Button 
            variant="warning" 
            onClick={() => setShowMergeModal(true)}
            className="mt-4"
          >
            Złącz duplikaty
          </Button>
      </Col>
      </Row>


      <style>
        {`
          .admin-table {
            font-size: 0.9rem;
          }
          .admin-table th {
            padding: 0.5rem;
            vertical-align: middle;
          }
          .admin-table td {
            padding: 0.5rem;
          }
          .admin-table th {
            white-space: normal;
            word-wrap: break-word;
          }
          .status-column {
            width: 100px;
            min-width: 80px;
            max-width: 80px;
          }
          .action-buttons .btn {
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
          }
        `}
      </style>

      <Table striped bordered responsive className="admin-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('title')} style={{ cursor: 'pointer', width: '240px' }}>
              Tytuł {sortField === 'title' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th>
              Slug {sortField === 'slug' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th style={{ width: '60px' }}>Plakat</th>
            <th>Data premiery</th>
            <th>Teatr</th>
            <th>Kategoria</th>
            <th>Lokalizacja</th>
            <th>Kraj</th>
            <th className="status-column">
              Aktualnie grany
            </th>
            <th className="status-column">
              Zweryfikowany
            </th>
            <th className="status-column">
              Zostawiony
            </th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {shows.map(show => (
            <React.Fragment key={show._id}>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                  <Button 
                      variant="link" 
                      onClick={() => toggleRowExpand(show._id)}
                      className="arrow"
                    >
                      {expandedRows[show._id] ? '▼' : '▶'}
                    </Button>
                    <span className="me-2">{editingShow?._id === show._id ? (
                      <Form.Control
                        type="text"
                        defaultValue={show.title}
                        onChange={(e) => handleInputChange('title', e.target.value)}
                      />
                    ) : show.title}</span>

                  </div>
                </td>
                <td>{show.slug}</td>
                <td>
                  {editingShow?._id === show._id ? (
                    <div>
                      <Form.Control
                        type="file"
                        onChange={handlePosterChange}
                        accept="image/*"
                        className="mb-2"
                      />
                      <Form.Control
                        type="text"
                        placeholder="lub wklej URL"
                        onChange={handlePosterUrlChange}
                        defaultValue={show.poster}
                      />
                    </div>
                  ) : (
                    <img 
                      src={
                        show?.poster?.includes('http')
                          ? show?.poster
                          : `${process.env.REACT_APP_API_BASE_URL}/img${show?.poster}`
                      }
                      alt={show.title} 
                      
                      className="admin-poster"
                    />
                  )}
                </td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Control
                      type="date"
                      defaultValue={show.premiere ? new Date(show.premiere).toISOString().split('T')[0] : ''}
                      onChange={(e) => handleInputChange('premiere', e.target.value)}
                    />
                  ) : (show.premiere ? format(new Date(show.premiere), 'dd.MM.yyyy', { locale: pl }) : 'Brak daty')}
                </td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Control
                      type="text"
                      defaultValue={show.theater}
                      onChange={(e) => handleInputChange('theater', e.target.value)}
                    />
                  ) : show.theater || '-'}
                </td>
                <td>{show.showCategory?.join(', ') || '-'}</td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Control
                      type="text"
                      defaultValue={show.location}
                      onChange={(e) => handleInputChange('location', e.target.value)}
                    />
                  ) : show.location || '-'}
                </td>
                <td>{show.country || '-'}</td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Check
                      type="checkbox"
                      checked={editedValues.currentlyPlayed ?? show.currentlyPlayed}
                      onChange={(e) => handleInputChange('currentlyPlayed', e.target.checked)}
                    />
                  ) : (
                    show.currentlyPlayed ? '✅' : '❌'
                  )}
                </td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Check
                      type="checkbox"
                      checked={editedValues.isVerified ?? show.isVerified}
                      onChange={(e) => handleInputChange('isVerified', e.target.checked)}
                    />
                  ) : (
                    show.isVerified ? '✅' : '❌'
                  )}
                </td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Check
                      type="checkbox"
                      checked={editedValues.isSoftVerified ?? show.isSoftVerified}
                      onChange={(e) => handleInputChange('isSoftVerified', e.target.checked)}
                    />
                  ) : (
                    show.isSoftVerified ? '✅' : '❌'
                  )}
                </td>
                <td className="action-buttons">
                  {editingShow?._id === show._id ? (
                    <>
                      <Button variant="success" size="sm" onClick={handleSave} className="me-2 mb-1">
                        Zapisz
                      </Button>
                      <Button variant="secondary" size="sm" onClick={() => {
                        setEditingShow(null);
                        setEditedValues({});
                        setPosterFile(null);
                        setBackgroundFile(null);
                      }}>
                        Anuluj
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" size="sm" onClick={() => handleEdit(show)} className="me-2 mb-1">
                        Edytuj
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => handleDelete(show._id)}>
                        Usuń
                      </Button>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="11" className="p-0">
                  <Collapse in={expandedRows[show._id]}>
                    <div className="p-3">
                      <Row>
                        <Col md={4}>
                          <h6>Podstawowe informacje</h6>
                          {editingShow?._id === show._id ? (
                            <>
                              <Form.Group className="mb-2">
                                <Form.Label>Autor</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.author}
                                  onChange={(e) => handleInputChange('author', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Reżyseria</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.direction}
                                  onChange={(e) => handleInputChange('direction', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Muzyka</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.musicAuthor}
                                  onChange={(e) => handleInputChange('musicAuthor', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Tłumaczenie</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.translation}
                                  onChange={(e) => handleInputChange('translation', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Czas trwania (min)</Form.Label>
                                <Form.Control
                                  type="number"
                                  defaultValue={show.duration}
                                  onChange={(e) => handleInputChange('duration', e.target.value)}
                                />
                              </Form.Group>
                            </>
                          ) : (
                            <>
                              <p>Autor: {show.author || '-'}</p>
                              <p>Reżyseria: {show.direction || '-'}</p>
                              <p>Muzyka: {show.musicAuthor || '-'}</p>
                              <p>Tłumaczenie: {show.translation || '-'}</p>
                              <p>Czas trwania: {show.duration ? `${show.duration} min` : '-'}</p>
                            </>
                          )}
                        </Col>
                        <Col md={4} className="classification">
  <h6>Klasyfikacja spektaklu</h6>
  {editingShow?._id === show._id ? (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Kategoria</Form.Label>
        <div>
          {SHOW_CATEGORIES.map(category => (
            <Form.Check
              key={category}
              type="checkbox"
              inline
              label={category.charAt(0).toUpperCase() + category.slice(1)}
              checked={editedValues.showCategory?.includes(category) ?? show.showCategory?.includes(category) ?? false}
              onChange={(e) => {
                const currentCategories = editedValues.showCategory ?? show.showCategory ?? [];
                const newCategories = e.target.checked
                  ? [...currentCategories, category]
                  : currentCategories.filter(c => c !== category);
                handleInputChange('showCategory', newCategories.length > 0 ? newCategories : undefined);
              }}
            />
          ))}
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Typ</Form.Label>
        <div>
          {SHOW_TYPES.map(type => (
            <Form.Check
              key={type}
              type="checkbox"
              inline
              label={type.charAt(0).toUpperCase() + type.slice(1)}
              checked={editedValues.showType?.includes(type) ?? show.showType?.includes(type) ?? false}
              onChange={(e) => {
                const currentTypes = editedValues.showType ?? show.showType ?? [];
                const newTypes = e.target.checked
                  ? [...currentTypes, type]
                  : currentTypes.filter(t => t !== type);
                handleInputChange('showType', newTypes.length > 0 ? newTypes : undefined);
              }}
            />
          ))}
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Dla dzieci</Form.Label>
        <Form.Check
          type="checkbox"
          label="Tak"
          checked={editedValues.showForKids ?? show.showForKids ?? false}
          onChange={(e) => handleInputChange('showForKids', e.target.checked)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Pochodzenie</Form.Label>
        <div>
          {SHOW_ORIGINS.map(origin => (
            <Form.Check
              key={origin}
              type="checkbox"
              inline
              label={origin.charAt(0).toUpperCase() + origin.slice(1)}
              checked={editedValues.showOrigin?.includes(origin) ?? show.showOrigin?.includes(origin) ?? false}
              onChange={(e) => {
                const currentOrigins = editedValues.showOrigin ?? show.showOrigin ?? [];
                const newOrigins = e.target.checked
                  ? [...currentOrigins, origin]
                  : currentOrigins.filter(o => o !== origin);
                handleInputChange('showOrigin', newOrigins.length > 0 ? newOrigins : undefined);
              }}
            />
          ))}
        </div>
      </Form.Group>
    </>
  ) : (
    <>
      <p>Kategoria: {show.showCategory?.join(', ') || '-'}</p>
      <p>Typ: {show.showType?.join(', ') || '-'}</p>
      <p>Dla dzieci: {show.showForKids ? 'Tak' : 'Nie'}</p>
      <p>Pochodzenie: {show.showOrigin?.join(', ') || '-'}</p>
    </>
  )}
</Col>

                        <Col md={4}>
                          <h6>Dodatkowe informacje</h6>
                          {editingShow?._id === show._id ? (
                            <>
                              <Form.Group className="mb-2">
                                <Form.Label>Kraj</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={editedValues.country ?? show.country ?? ''}
                                  onChange={(e) => handleInputChange('country', e.target.value)}
                                  onFocus={() => {
                                    if (!countryFieldClicked[show._id]) {
                                      const suggestedCountry = getCountryFromLocation(show.location);
                                      if (suggestedCountry) {
                                        handleInputChange('country', suggestedCountry);
                                      }
                                      setCountryFieldClicked(prev => ({
                                        ...prev,
                                        [show._id]: true
                                      }));
                                    }
                                  }}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Tagi (oddzielone przecinkami) (archiwalne)</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.tags?.join(', ')}
                                  onChange={(e) => handleInputChange('tags', e.target.value.split(',').map(tag => tag.trim()))}
                                  disabled
                                  
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Link do biletów</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.ticketUrl}
                                  onChange={(e) => handleInputChange('ticketUrl', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Opis</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  defaultValue={show.about}
                                  onChange={(e) => handleInputChange('about', e.target.value)}
                                />
                              </Form.Group>
                            </>
                          ) : (
                            <>
                              <p>Kraj: {show.country || '-'}</p>
                              <p>Tagi: {show.tags?.join(', ') || '-'}</p>
                              <p>Link do biletów: {show.ticketUrl || '-'}</p>
                              <p>Opis: {show.about || '-'}</p>
                            </>
                          )}
                        </Col>
                        <Col md={4}>
                          <h6>Rankingi i statystyki</h6>
                          <p>Średnia ocena: {show.avgRating?.toFixed(1) || '-'}</p>
                          <p>Liczba ocen: {show.numberOfRatings || 0}</p>
                          <p>Ranking globalny: {show.rankGlobal || '-'}</p>
                          <p>Ranking krajowy: {show.rankNational || '-'}</p>
                          <p>Ranking lokalny: {show.rankLocal || '-'}</p>
                        </Col>
                        <Col md={4}>
                          <h6>Obrazy</h6>
                          {editingShow?._id === show._id ? (
                            <>
                              <Form.Group className="mb-2">
                                <Form.Label>Plakat</Form.Label>
                                <Form.Control
                                  type="file"
                                  onChange={handlePosterChange}
                                  accept="image/*"
                                  className="mb-2"
                                />
                                <Form.Control
                                  type="text"
                                  placeholder="lub wklej URL"
                                  onChange={handlePosterUrlChange}
                                  defaultValue={show.poster}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Tło</Form.Label>
                                <Form.Control
                                  type="file"
                                  onChange={handleBackgroundChange}
                                  accept="image/*"
                                  className="mb-2"
                                />
                                <Form.Control
                                  type="text"
                                  placeholder="lub wklej URL"
                                  onChange={handleBackgroundUrlChange}
                                  defaultValue={show.background}
                                />
                              </Form.Group>
                            </>
                          ) : (
                            <>
                              <p>Plakat: {show.poster || '-'}</p>
                              <p>Tło: {show.background || '-'}</p>
                            </>
                          )}
                        </Col>

                      </Row>
                    </div>
                  </Collapse>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      {hasMore && (
        <div className="text-center mt-3 mb-4">
          <Button 
            variant="primary" 
            onClick={() => setPage(prev => prev + 1)}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? 'Ładowanie...' : 'Załaduj więcej'}
          </Button>
        </div>
      )}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Potwierdź usunięcie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Czy na pewno chcesz usunąć ten spektakl? Tej operacji nie można cofnąć.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Anuluj
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Usuń
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal 
        show={showMergeModal} 
        onHide={() => {
          setShowMergeModal(false);
          setMergeResult(null);
          setMergeData({ correctSlug: '', duplicateSlugs: '' });
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
          <h1 className="my-2 mb-4">Złącz duplikaty spektakli</h1>
          {!mergeResult ? (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Slug prawidłowego spektaklu</Form.Label>
                <Form.Control
                  type="text"
                  value={mergeData.correctSlug}
                  onChange={(e) => setMergeData(prev => ({ ...prev, correctSlug: e.target.value }))}
                  placeholder="np. les-miserables"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Slugi duplikatów (oddzielone przecinkami)</Form.Label>
                <Form.Control
                  type="text"
                  value={mergeData.duplicateSlugs}
                  onChange={(e) => setMergeData(prev => ({ ...prev, duplicateSlugs: e.target.value }))}
                  placeholder="np. les-miserables-2, les-miserables-3"
                />
                <Form.Text className="text-muted">
                  Wpisz slugi duplikatów oddzielone przecinkami
                </Form.Text>
              </Form.Group>
            </Form>
          ) : (
            <div>
              {mergeResult.success ? (
                <>
                  <Alert variant="success">
                    Pomyślnie połączono spektakle!
                  </Alert>
                  <h6>Szczegóły operacji:</h6>
                  <ul>
                    <li>Prawidłowy spektakl: {mergeResult.correctShow.title} ({mergeResult.correctShow.slug})</li>
                    <li>Liczba zaktualizowanych użytkowników: {mergeResult.updatedUsersCount}</li>
                    <li>Liczba przeniesionych wpisów: {mergeResult.movedEntriesCount}</li>
                    <li>Nowa średnia ocena: {mergeResult.newRating?.toFixed(2) || 'brak'}</li>
                  </ul>
                  <h6>Przeniesione wpisy:</h6>
                  <ul>
                    {mergeResult.movedEntries.map((entry, index) => (
                      <li key={index}>
                        {entry.userName}: {entry.showTitle} (ocena: {entry.rating}, data: {new Date(entry.date).toLocaleDateString()})
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Alert variant="danger">
                  {mergeResult.error}
                </Alert>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => {
              setShowMergeModal(false);
              setMergeResult(null);
              setMergeData({ correctSlug: '', duplicateSlugs: '' });
            }}
          >
            {mergeResult ? 'Zamknij' : 'Anuluj'}
          </Button>
          {!mergeResult && (
            <Button 
              variant="warning" 
              onClick={handleMergeSubmit}
              disabled={!mergeData.correctSlug || !mergeData.duplicateSlugs || isMerging}
            >
              {isMerging ? 'Łączenie...' : 'Połącz spektakle'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Button 
        variant="info" 
        onClick={() => setShowModeratorChanges(!showModeratorChanges)}
        className="mb-3"
      >
        Pokaż zmiany moderatorów ({moderatorChanges.filter(change => change.status === 'pending').length})
      </Button>

      {showModeratorChanges && (
        <>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Moderator</th>
                <th>Spektakl</th>
                <th style={{ width: '500px' }}>Proponowane zmiany</th>
                <th>Data</th>
                <th>Status</th>
                <th>Notatka moderatora</th>
                <th>Akcje</th>
              </tr>
            </thead>
            <tbody>
              {moderatorChanges.map(change => (
                <tr key={change._id}>
                  <td>{change.moderator.name}</td>
                  <td style={{ width: '240px' }}>
                    {change.show ? (
                      <>
                        {change.show.title}
                        {change.show.theater && (
                          <div className="text-muted small">{change.show.theater}</div>
                        )}
                        <div className="text-muted small">{change.show.slug}</div>
                      </>
                    ) : (
                      'Notatka bez spektaklu'
                    )}
                  </td>
                  <td style={{ width: '500px', fontSize: '11px', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {change.show ? JSON.stringify(change.changes, null, 2) : change.changes.note}
                  </td>
                  <td>{new Date(change.createdAt).toLocaleDateString()}</td>
                  <td style={getStatusStyle(change.status)}>{change.status}</td>
                  <td>{change.moderatorNote || '-'}</td>
                  <td>
                    {change.status === 'pending' && (
                      <>
                        <Button 
                          variant="success" 
                          size="sm" 
                          onClick={() => handleModeratorChange(change._id, 'approved')}
                          className="me-2"
                        >
                          Zatwierdź
                        </Button>
                        <Button 
                          variant="danger" 
                          size="sm" 
                          onClick={() => handleModeratorChange(change._id, 'rejected')}
                        >
                          Odrzuć
                        </Button>
                      </>
                    )}
                    {change.status !== 'pending' && (
                      <span style={getStatusStyle(change.status)}>
                        {change.status === 'approved' ? 'Zatwierdzono' : 'Odrzucono'}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {hasMoreChanges && (
            <div className="text-center mt-3 mb-4">
              <Button 
                variant="primary" 
                onClick={handleLoadMoreChanges}
                disabled={isLoadingChanges}
              >
                {isLoadingChanges ? 'Ładowanie...' : 'Załaduj więcej'}
              </Button>
            </div>
          )}
        </>
      )}



      <Modal show={showAddModal} onHide={handleCloseAddModal} size="lg">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddShow}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Tytuł</Form.Label>
                  <Form.Control
                    type="text"
                    value={newShow.title}
                    onChange={(e) => setNewShow({...newShow, title: e.target.value})}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Teatr</Form.Label>
                  <Form.Control
                    type="text"
                    value={newShow.theater}
                    onChange={(e) => setNewShow({...newShow, theater: e.target.value})}
                    onFocus={() => {
                      setNewShow(prev => ({...prev, theater: "Gdański Teatr Szekspirowski"}));
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Lokalizacja</Form.Label>
                  <Form.Control
                    type="text"
                    value={newShow.location}
                    onChange={(e) => setNewShow({...newShow, location: e.target.value})}
                    onFocus={() => {
                      setNewShow(prev => ({...prev, location: "Gdańsk"}));
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Kraj</Form.Label>
                  <Form.Control
                    type="text"
                    value={newShow.country}
                    onChange={(e) => setNewShow({...newShow, country: e.target.value})}
                    onFocus={() => {
                      if (!newShowCountryClicked) {
                        const suggestedCountry = getCountryFromLocation(newShow.location);
                        if (suggestedCountry) {
                          setNewShow(prev => ({...prev, country: suggestedCountry}));
                        }
                        setNewShowCountryClicked(true);
                      }
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Link do biletów</Form.Label>
                  <Form.Control
                    type="text"
                    value={newShow.ticketUrl}
                    onChange={(e) => setNewShow({...newShow, ticketUrl: e.target.value})}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Reżyseria</Form.Label>
                  <Form.Control
                    type="text"
                    value={newShow.direction}
                    onChange={(e) => setNewShow({...newShow, direction: e.target.value})}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Autor</Form.Label>
                  <Form.Control
                    type="text"
                    value={newShow.author}
                    onChange={(e) => setNewShow({...newShow, author: e.target.value})}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Zweryfikowany"
                    checked={newShow.isVerified}
                    onChange={(e) => setNewShow({...newShow, isVerified: e.target.checked})}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Aktualnie grany"
                    checked={newShow.currentlyPlayed}
                    onChange={(e) => setNewShow({...newShow, currentlyPlayed: e.target.checked})}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Kategoria</Form.Label>
                  {SHOW_CATEGORIES.map(category => (
                    <Form.Check
                      key={category}
                      type="checkbox"
                      label={category}
                      checked={newShow.showCategory.includes(category)}
                      onChange={(e) => {
                        const categories = e.target.checked
                          ? [...newShow.showCategory, category]
                          : newShow.showCategory.filter(c => c !== category);
                        setNewShow({...newShow, showCategory: categories});
                      }}
                    />
                  ))}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Typ</Form.Label>
                  {SHOW_TYPES.map(type => (
                    <Form.Check
                      key={type}
                      type="checkbox"
                      label={type}
                      checked={newShow.showType.includes(type)}
                      onChange={(e) => {
                        const types = e.target.checked
                          ? [...newShow.showType, type]
                          : newShow.showType.filter(t => t !== type);
                        setNewShow({...newShow, showType: types});
                      }}
                    />
                  ))}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Pochodzenie</Form.Label>
                  {SHOW_ORIGINS.map(origin => (
                    <Form.Check
                      key={origin}
                      type="checkbox"
                      label={origin}
                      checked={newShow.showOrigin.includes(origin)}
                      onChange={(e) => {
                        const origins = e.target.checked
                          ? [...newShow.showOrigin, origin]
                          : newShow.showOrigin.filter(o => o !== origin);
                        setNewShow({...newShow, showOrigin: origins});
                      }}
                    />
                  ))}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Dla dzieci"
                    checked={newShow.showForKids}
                    onChange={(e) => setNewShow({...newShow, showForKids: e.target.checked})}
                  />
                </Form.Group>


              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Opis</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                value={newShow.about}
                onChange={(e) => setNewShow({...newShow, about: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Plakat</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(e, 'poster')}
                accept="image/*"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tło</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(e, 'background')}
                accept="image/*"
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Dodaj spektakl
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal className="addcast-modal" show={showCastModal} onHide={() => setShowCastModal(false)} size="lg">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body >
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Slug spektaklu</Form.Label>
              <Form.Control
                type="text"
                value={castData.showSlug}
                onChange={(e) => setCastData({...castData, showSlug: e.target.value})}
                placeholder="np. les-miserables"
              />
            </Form.Group>

            {castData.cast.map((member, index) => (
              <div key={index} className="border p-3 mb-3">
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Imię i nazwisko</Form.Label>
                      <Form.Control
                        type="text"
                        value={member.fullName}
                        onChange={(e) => handleCastNameChange(index, e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Slug</Form.Label>
                      <Form.Control
                        type="text"
                        value={member.slug}
                        onChange={(e) => handleCastSlugChange(index, e.target.value)}
                        style={{
                          color: member.exists ? '#198754' : '#6c757d'
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Zdjęcie</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={(e) => handleCastPhotoChange(index, e.target.files[0])}
                        accept="image/*"
                        disabled={member.exists}
                      />
                      {member.photoUrl && (
                        <img 
                          src={member.photoUrl} 
                          alt="Preview" 
                          style={{ maxWidth: '100px', marginTop: '10px' }} 
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Kolejność</Form.Label>
                      <Form.Control
                        type="number"
                        value={member.order}
                        onChange={(e) => handleCastOrderChange(index, e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            ))}

            <Button variant="secondary" onClick={addCastMember} className="mb-3">
              Dodaj kolejną osobę
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCastModal(false)}>
            Anuluj
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSaveCast}
            disabled={!castData.showSlug || castData.cast.some(member => !member.slug)}
          >
            Zapisz obsadę
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminPanel; 