// Importowanie zależności
import React, { useState, useEffect, useCallback, useMemo, useRef, useTransition } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Container, Row, Col, Card, Button, InputGroup, FormControl, Spinner, Overlay, Tooltip } from 'react-bootstrap';
import './SkeletonLoader.css'; // Dodaj własne style
import { startOfWeek, endOfWeek, eachDayOfInterval, format } from 'date-fns';
import Typewriter from 'typewriter-effect';

// Importowanie stylów i ikon
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';

// Importowanie komponentów niestandardowych
import SearchModal from './SearchModal';
import ShowModal from './ShowModal';
import OnboardingModal from './OnboardingModal';
import ShowTags from './components/ShowTags';

// Komponent Homepage
function Homepage({ 
    user,
    setUser,
    searchIcon, 
    rankIcon, 
    starIcon, 
    calendarIcon,
    bigRankIcon,
    bigRankAllIcon,
    bigDiaryIcon,
    colorCircleCalendarIcon,
    colorCircleStarIcon,
    getRatingDescription,
    renderStarRating,
    plusIcon,
    infoIcon,
    successIcon,
    POLAND_CITIES,
    FEATURED_SHOWS,
    arrowLeftIcon,
    arrowRightIcon,
    defaultPoster,
    t,
    currentLocale,
    formatDate,
    heartIcon,
    forumLogo,
    chevronsRightIcon,
    checkCircleIcon,
    activityIcon,
    bestPlaysIcon,
    bestMusicalsIcon,
    rankPlaysIcon,
    rankMusicalsIcon,
    handleNavigation
  }) {

  // Deklaracja zmiennych stanu
  const [userDiary, setUserDiary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMusicalsLocation, setCurrentMusicalsLocation] = useState(POLAND_CITIES);
  const [currentPlaysLocation, setCurrentPlaysLocation] = useState(POLAND_CITIES);
  const [currentLocation] = useState(POLAND_CITIES);
  const [activeMyShowsList, setActiveMyShowsList] = useState('Next');
  const [wishListShows, setWishListShows] = useState([]);
  const [selectedShow, setSelectedShow] = useState(null); // Dodaj stan wybranego spektaklu dla modalu
  const [loadingButtons, setLoadingButtons] = useState({}); // Stan dla śledzenia ładowania przycisków
  const [selectedEntry, setSelectedEntry] = useState(null); // <-- Add this line
  const [displayedActivities, setDisplayedActivities] = useState([]);
  const [displayedFollowingActivities, setDisplayedFollowingActivities] = useState([]);
  const [isLoadingFollowing, setIsLoadingFollowing] = useState(false);
  const [, startTransition] = useTransition();

  const [loadingMusicals, setLoadingMusicals] = useState(true);
  const [loadingPlays, setLoadingPlays] = useState(true);
  const [loadingNewFeaturedShows, setLoadingNewFeaturedShows] = useState(true);
  const [loadingNewPremiereShows, setLoadingNewPremiereShows] = useState(true);
  const [musicalShows, setMusicalShows] = useState([]);
  const [playShows, setPlayShows] = useState([]);
  const [newFeaturedShows, setNewFeaturedShows] = useState([]);
  const [newPremiereShows, setNewPremiereShows] = useState([]);
  const [trendingShows, setTrendingShows] = useState([]);
const [loadingTrendingShows, setLoadingTrendingShows] = useState(true); 


  // Stan i efekty dla kalendarza tygodniowego
  const [weeklyEvents, setWeeklyEvents] = useState([]);
  const [isLoadingWeekly, setIsLoadingWeekly] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isOverlayActive, setIsOverlayActive] = useState(false);



  // Dodaj nowy stan dla aktywnej lokalizacji
  const [activeCalendarLocation, setActiveCalendarLocation] = useState('POLAND');

  // 1. Dodaj nowy stan do śledzenia czy dane zostały już załadowane
  const [isDataFetched, setIsDataFetched] = useState(false);

  // 1. Dodaj nowy stan do śledzenia czy dane zostały już w pełni załadowane
  const [isFeaturedFullyLoaded, setIsFeaturedFullyLoaded] = useState(false);

  // Dodaj predefinedLocations dla kalendarza (możesz użyć tych samych co w sekcji best-played)
  const calendarLocations = useMemo(() => [
    { key: 'POLAND', label: t('best-played.countries.Poland'), locations: POLAND_CITIES },
    { key: 'PL-WARSAW', label: t('best-played.locations.PL-Warsaw'), locations: ['Warszawa'] },
    { key: 'PL-LODZ', label: 'Łódź', locations: ['Łódź'] },
    { key: 'PL-POZNAN', label: 'Poznań', locations: ['Poznań'] },
    { key: 'PL-GDYNIA', label: 'Gdynia', locations: ['Gdynia'] },
    { key: 'PL-KRAKOW', label: 'Kraków', locations: ['Kraków'] },
    { key: 'PL-WROCLAW', label: 'Wrocław', locations: ['Wrocław'] },
    { key: 'PL-CHORZOW', label: 'Chorzów', locations: ['Chorzów'] },
  ], [t, POLAND_CITIES]);

  const SkeletonLoaderFeatured = ({ width, height }) => {
    return (
      <div className="show-card-wrapper">
        <div className="skeleton-card is-loading featured-show-card">
          <div className="show-image-layer">
          </div>
          <div className="show-info-layer">
            <div className="top-layer">
              <div className="show-details">
                <div className="rating-wrapper">
                  <p className="average-rating"></p>
                </div>
              </div>
            </div>
            <p className="show-description"></p>
          </div>
        </div>
      </div>
    );
  };

  const SkeletonLoaderFeaturedDesktop = ({ width, height }) => {
    return (
      <div className="show-card-wrapper">
        <div className="skeleton-card is-loading featured-show-card">
          <div className="show-image-layer">
          </div>
          <div className="show-info-layer">

            <p className="show-description"></p>
          </div>
        </div>
      </div>
    );
  };

  const SkeletonLoaderMyShows = ({ width, height }) => {
    return (
      <div className="skeleton-card is-loading show-card">
        <div className="show-poster">
        </div>

        <div className="show-info">
          <p className="show-title"></p>
          <p className="show-date"></p>
        </div>
      </div>
    );
  };

  const SkeletonLoaderBest = ({ width, height }) => {
    return (
      <div className="skeleton-card is-loading show-card">

        <div className="show-poster">
          <div className="poster-img"></div>
        </div>

        <div className="show-info">
          <p className="ranking"></p>

          <div className="show-info-basic">
          </div>

          <p></p>
          <p></p>
          </div>


        
      </div>
    );
  };

  const SkeletonLoaderActivity = ({ width, height }) => {
    return (
      <div className="activity-card skeleton-card is-loading">
        <div className="activity-user">
          <p style={{ width: '100%', height: '40px' }}></p>
        </div>
        <div className="activity-content">
          <div className="activity-show">
            <div className="activity-poster" style={{ width: '100%', height: '60px' }}></div>

          </div>
        </div>
      </div>
    );
  };

  const navigate = useNavigate(); // Dodaj hook nawigacji

  // Śledzenie przesunięcia myszy
  const [isDragging, setIsDragging] = useState(false);
  
  // Ustaw dzisiejszą datę na początek dnia w lokalnej strefie czasowej
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Filtrowanie spektakli użytkownika na podstawie daty oglądania
  const nextMyShows = userDiary
    .filter(entry => {
      const entryDate = new Date(entry.dateWatched);
      entryDate.setHours(0, 0, 0, 0);
      return entryDate >= today;
    })
    .sort((a, b) => new Date(a.dateWatched) - new Date(b.dateWatched));

  const seenMyShows = userDiary
    .filter(entry => {
      const entryDate = new Date(entry.dateWatched);
      entryDate.setHours(0, 0, 0, 0);
      return entryDate < today;
    })
    .sort((a, b) => new Date(b.dateWatched) - new Date(a.dateWatched));

  const wishListMyShows = []; // Testowe dane na przyszłe potrzeby

// Przycięcie list Next i Seen do 9 elementów
const displayedNextMyShows = nextMyShows.slice(0, 6);
const displayedSeenMyShows = seenMyShows.slice(0, 6);

// Wyświetlanie listy aktywnych spektakli z ograniczeniem do 9
const activeMyShows = activeMyShowsList === 'Next' ? displayedNextMyShows : activeMyShowsList === 'Seen' ? displayedSeenMyShows : wishListMyShows;

// Stan dla paginacji
const [wishListPage, setWishListPage] = useState(1);
const itemsPerPage = 6;

// Funkcja do ładowania kolejnych elementów Wish-list
const handleShowMoreWishList = () => {
  setWishListPage(prevPage => prevPage + 1);
};

// Obliczenie elementów do wyświetlenia
const displayedWishListShows = wishListShows.slice(0, wishListPage * itemsPerPage);

// Pobieranie listy życzeń użytkownika
useEffect(() => {
  if (user) {
    const fetchWishList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        setWishListShows(response.data); // Zaktualizuj listę życzeń
      } catch (err) {
        console.error('Error fetching wish list:', err);
      }
    };

    fetchWishList();
  }
}, [user]);





const removeFromWishList = async (showId) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist/remove`, { showId }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    // Znajdź i ustaw flagę `isRemoved` na true dla danego spektaklu
    setWishListShows(
      wishListShows.map(show =>
        show._id === showId ? { ...show, isRemoved: true } : show
      )
    );
  } catch (err) {
    console.error('Error removing from wish list:', err);
  }
};

const addAgainToWishList = async (showId) => {
  setLoadingButtons(prevState => ({ ...prevState, [showId]: true })); // Ustaw spinner dla konkretnego show
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/wishlist/add`, { showId }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    // Zresetuj flagę `isRemoved` dla danego spektaklu
    setWishListShows(
      wishListShows.map(show =>
        show._id === showId ? { ...show, isRemoved: false } : show
      )
    );
  } catch (err) {
    console.error('Error adding to wish list again:', err);
  } finally {
    setLoadingButtons(prevState => ({ ...prevState, [showId]: false })); // Wyłącz spinner
  }
};


// Obsługa wylogowania użytkownika, zapamiętana przez useCallback
const handleLogout = useCallback(() => {
  setUser(null);
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}, [setUser]); // Zależność: setUser
  
// Zamiast tworzenia dat bezpośrednio w komponencie
const startDate = useMemo(() => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date.toISOString().split('T')[0];
}, []); // pusta tablica zależności, bo chcemy to policzyć tylko raz

const endDate = useMemo(() => {
  const date = new Date();
  date.setMonth(date.getMonth() + 2);
  return date.toISOString().split('T')[0];
}, []); // pusta tablica zależności, bo chcemy to policzyć tylko raz

  // Pobieranie wyróżnionych spektakli
  useEffect(() => {
    const fetchFeaturedShows = async () => {
      if (isFeaturedFullyLoaded) return;

      try {
        setLoadingNewFeaturedShows(true);
        const featuredResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/verified-shows/featured?slugs=${encodeURIComponent(JSON.stringify(FEATURED_SHOWS))}`,
          { withCredentials: true }
        );
        
        setNewFeaturedShows(featuredResponse.data);
        setIsFeaturedFullyLoaded(true);
      } catch (error) {
        console.error('Error fetching featured shows:', error);
      } finally {
        setLoadingNewFeaturedShows(false);
      }
    };

    fetchFeaturedShows();
  }, [FEATURED_SHOWS, isFeaturedFullyLoaded]);

  // Pobieranie musicali
  useEffect(() => {
    const fetchMusicals = async () => {
      try {
        setLoadingMusicals(true);
        const musicalResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/shows?category=musicals`);
        let filteredMusicals = musicalResponse.data;
        setMusicalShows(filteredMusicals);
      } catch (error) {
        console.error('Error fetching musicals:', error);
      } finally {
        setLoadingMusicals(false);
      }
    };

    fetchMusicals();
  }, []);

  // Pobieranie sztuk teatralnych
  useEffect(() => {
    const fetchPlays = async () => {
      try {
        setLoadingPlays(true);
        const playResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/shows?category=plays`);
        let filteredPlays = playResponse.data;
        setPlayShows(filteredPlays);
      } catch (error) {
        console.error('Error fetching plays:', error);
      } finally {
        setLoadingPlays(false);
      }
    };

    fetchPlays();
  }, []);

  // Pobieranie premier
  useEffect(() => {
    const fetchPremieres = async () => {
      try {
        setLoadingNewPremiereShows(true);
        console.log('Wysyłam zapytanie o premiery dla zakresu dat:', {startDate, endDate});
        const premieresResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/verified-shows/premieres?startDate=${startDate}&endDate=${endDate}`,
          { credentials: 'include' }
        );
        const premieresData = await premieresResponse.json();
        console.log('Otrzymane dane premier:', premieresData);
        setNewPremiereShows(premieresData);
      } catch (error) {
        console.error('Error fetching premieres:', error);
      } finally {
        setLoadingNewPremiereShows(false);
      }
    };

    fetchPremieres();
  }, [startDate, endDate]);

  // Pobieranie popularnych spektakli
useEffect(() => {
  const fetchTrendingShows = async () => {
    try {
      setLoadingTrendingShows(true);
      console.log('Rozpoczynam pobieranie popularnych spektakli...');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/trending-shows`);
      console.log('Otrzymano popularne spektakle:', response.data);
      setTrendingShows(response.data);
    } catch (error) {
      console.error('Błąd podczas pobierania popularnych spektakli:', error);
    } finally {
      setLoadingTrendingShows(false);
      console.log('Zakończono pobieranie popularnych spektakli.');
    }
  };

  fetchTrendingShows();
}, []);
  


  // Pobieranie danych o spektaklach i dzienniku użytkownika
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingMyShows(true); // Dodaj ustawienie loadera
        const [diaryResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }),
        ]);
      
        setUserDiary(diaryResponse.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        console.error('Error fetching data:', error);
      } finally {
        setIsLoadingMyShows(false); // Wyłącz loader po zakończeniu
      }
    };
    
    if (user) {
      fetchData();
    }
  }, [user, handleLogout]);
  

// Pobranie oceny użytkownika dla konkretnego spektaklu
const getUserRatingForShow = (showId) => {
  // Filtrowanie wpisów dla konkretnego showId z zabezpieczeniem
  const entries = userDiary.filter(entry => entry && entry.show && entry.show._id === showId);
  
  if (entries.length === 0) {
    return null;
  }

  // Filtrowanie ocen, aby uwzględnić tylko te, które nie są null
  const ratings = entries
    .map(entry => entry.userRating)
    .filter(rating => rating !== null && rating !== 0); // Dodano warunek, aby wykluczyć oceny 0

  if (ratings.length === 0) {
    return null;
  }

  // Sumowanie ocen i obliczanie średniej
  const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
  const averageRating = totalRating / ratings.length;

  return averageRating.toFixed(1); // Zaokrąglenie do jednego miejsca po przecinku
};

const predefinedLocations = [
  { key: 'POLAND', label: t('best-played.countries.Poland'), locations: POLAND_CITIES },
  { key: 'NYC', label: t('best-played.locations.NYC'), locations: ['New York'] },
  { key: 'WEST_END', label: t('best-played.locations.West-End'), locations: ['London'] },
  { key: 'PL-WARSAW', label: t('best-played.locations.PL-Warsaw'), locations: ['Warszawa'] },
  { key: 'PL-LODZ', label: 'Łódź', locations: ['Łódź'] },
  { key: 'PL-POZNAN', label: 'Poznań', locations: ['Poznań'] },
  { key: 'PL-GDYNIA', label: 'Gdynia', locations: ['Gdynia'] },
  { key: 'PL-KRAKOW', label: 'Kraków', locations: ['Kraków'] },
  { key: 'PL-WROCLAW', label: 'Wrocław', locations: ['Wrocław'] },
  { key: 'PL-CHORZOW', label: 'Chorzów', locations: ['Chorzów'] },
  // Dodaj więcej lokalizacji w razie potrzeby
];

// Najpierw funkcje liczące spektakle z useCallback
const getMusicalsCountForLocation = useCallback((locations) => {
  return musicalShows.filter(show =>
    show.rankGlobal !== null && 
    show.rankGlobal > 0 &&
    locations.some(location =>
      show.location
        ? show.location.split(',').map(city => city.trim()).includes(location)
        : false
    )
  ).length;
}, [musicalShows]);

const getPlaysCountForLocation = useCallback((locations) => {
  return playShows.filter(show =>
    show.rankGlobal !== null && 
    show.rankGlobal > 0 &&
    locations.some(location =>
      show.location
        ? show.location.split(',').map(city => city.trim()).includes(location)
        : false
    )
  ).length;
}, [playShows]);


// Funkcja do zmiany lokalizacji spektakli
const handleChangeMusicalsLocation = useCallback((locations) => {
  setCurrentMusicalsLocation(locations);
}, []);

const handleChangePlaysLocation = useCallback((locations) => {
  setCurrentPlaysLocation(locations);
}, []);

// Filtruj predefinedLocations, aby zawierały tylko lokalizacje z wynikami
const activeMusicalsLocations = predefinedLocations.filter(tab => 
  getMusicalsCountForLocation(tab.locations) > 0
);

const activePlaysLocations = predefinedLocations.filter(tab => 
  getPlaysCountForLocation(tab.locations) > 0
);
  

// Efekty do ustawienia początkowych lokalizacji
useEffect(() => {
  // Sprawdź czy mamy aktywne lokalizacje i czy nie została jeszcze wybrana żadna lokalizacja
  if (activeMusicalsLocations.length > 0 && (!currentMusicalsLocation || currentMusicalsLocation.length === 0)) {
    // Znajdź pierwszą niepustą lokalizację
    const firstValidLocation = activeMusicalsLocations.find(loc => getMusicalsCountForLocation(loc.locations) > 0);
    if (firstValidLocation) {
      handleChangeMusicalsLocation(firstValidLocation.locations);
    }
  }
}, [activeMusicalsLocations, currentMusicalsLocation, getMusicalsCountForLocation, handleChangeMusicalsLocation]);

useEffect(() => {
  // Sprawdź czy mamy aktywne lokalizacje i czy nie została jeszcze wybrana żadna lokalizacja
  if (activePlaysLocations.length > 0 && (!currentPlaysLocation || currentPlaysLocation.length === 0)) {
    // Znajdź pierwszą niepustą lokalizację
    const firstValidLocation = activePlaysLocations.find(loc => getPlaysCountForLocation(loc.locations) > 0);
    if (firstValidLocation) {
      handleChangePlaysLocation(firstValidLocation.locations);
    }
  }
}, [activePlaysLocations, currentPlaysLocation, getPlaysCountForLocation, handleChangePlaysLocation]);
  




// Filtrowanie spektakli na podstawie aktualnej lokalizacji
const musicalsByLocation = musicalShows.filter(show =>
  currentMusicalsLocation.some(location =>
    show.location 
    ? show.location.split(',').map(city => city.trim()).includes(location)
    : false // Sprawdzenie czy show.location istnieje przed użyciem split()
  )
);

const playsByLocation = playShows.filter(show =>
  currentPlaysLocation.some(location =>
    show.location 
    ? show.location.split(',').map(city => city.trim()).includes(location)
    : false // Sprawdzenie czy show.location istnieje przed użyciem split()
  )
);




    // Funkcja do obsługi nawigacji po kliknięciu linku (bez przeciągania)
    const handleClick = (e, slug) => {
      if (isDragging) {
        e.preventDefault();
      } else {
        navigate(`/shows/${slug}`);
      }
    };
    
// Komponent strzałki w lewo
const PrevArrow = (props) => {
  const { className, onClick, style } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <img src={arrowLeftIcon} alt="Previous" className="slider-custom-icon" />
    </div>
  );
};

// Komponent strzałki w prawo
const NextArrow = (props) => {
  const { className, onClick, style } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <img src={arrowRightIcon} alt="Next" className="slider-custom-icon" />
    </div>
  );
};

  

// Ustawienia dla karuzeli (Slider)
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 3000,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  cssEase: "cubic-bezier(0.25, 0.1, 0.25, 1)", // Dodaje płynniejszą animację
  touchThreshold: 5, // Zmniejsza próg dotknięcia dla łatwiejszego przewijania
  useCSS: true, // Używa CSS transitions dla animacji
  useTransform: true, // Używa CSS3 transforms dla animacji
  waitForAnimate: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
      },
    },
  ],
};

// Ustawienia dla karuzeli (Slider)
const settings_trending = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true,
  autoplay: false,
  autoplaySpeed: 3000,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  cssEase: "cubic-bezier(0.25, 0.1, 0.25, 1)", // Dodaje płynniejszą animację
  touchThreshold: 5, // Zmniejsza próg dotknięcia dla łatwiejszego przewijania
  useCSS: true, // Używa CSS transitions dla animacji
  useTransform: true, // Używa CSS3 transforms dla animacji
  waitForAnimate: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
      },
    },
  ],
};




const sortedNewPremiereShows = useMemo(() => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startDate = new Date('2024-09-01');
  const endDate = new Date('2025-06-30');

  const filteredShows = newPremiereShows
    .filter(show => {
      // Sprawdzenie daty premiery
      if (!show.premiere) return false;
      const premiereDate = new Date(show.premiere);
      
      // Sprawdzenie lokalizacji
      const isInPoland = show.location && POLAND_CITIES.some(city => 
        show.location.split(',').map(loc => loc.trim()).includes(city)
      );
      
      return !isNaN(premiereDate) && 
             premiereDate >= startDate && 
             premiereDate <= endDate && 
             isInPoland;
    });

  // Podziel spektakle na przyszłe/dzisiejsze i przeszłe
  const upcomingShows = [];
  const pastShows = [];

  filteredShows.forEach(show => {
    const premiereDate = new Date(show.premiere);
    premiereDate.setHours(0, 0, 0, 0);
    
    if (premiereDate >= today) {
      upcomingShows.push(show);
    } else {
      pastShows.push(show);
    }
  });

  // Sortuj obie grupy rosnąco po dacie premiery
  upcomingShows.sort((a, b) => new Date(a.premiere) - new Date(b.premiere));
  pastShows.sort((a, b) => new Date(a.premiere) - new Date(b.premiere));

  // Połącz grupy - najpierw nadchodzące, potem przeszłe
  return [...upcomingShows, ...pastShows];
}, [newPremiereShows, POLAND_CITIES]);





  

  // Sortowanie spektakli według avgRating
  const sortedMusicalsByLocation = musicalsByLocation
  .filter(show => show.currentlyPlayed) // Dodane filtrowanie po currentlyPlayed
  .filter(show => show.rankGlobal !== null && show.rankGlobal > 0)
  .sort((a, b) => b.avgRating - a.avgRating)
  .slice(0, 6);

// Dynamiczne określanie pozycji w rankingu
const musicalsWithDynamicRank = sortedMusicalsByLocation.map((show, index) => ({
  ...show,
  dynamicRank: index + 1,
}));

  // Sortowanie spektakli według avgRating
  const sortedPlaysByLocation = playsByLocation
  .filter(show => show.currentlyPlayed) // Dodane filtrowanie po currentlyPlayed
  .filter(show => show.rankGlobal !== null && show.rankGlobal > 0)
  .sort((a, b) => b.avgRating - a.avgRating)
  .slice(0, 6);

// Dynamiczne określanie pozycji w rankingu
const playsWithDynamicRank = sortedPlaysByLocation.map((show, index) => ({
  ...show,
  dynamicRank: index + 1,
}));





  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

// Użyj tego:
const handleShowClick = (entry) => {
  setSelectedShow(entry);
  setSelectedEntry(entry); // <-- Set selectedEntry instead of selectedShow

};
    // Funkcja obsługująca zamykanie modalu
    const handleCloseModal = () => {
      setSelectedShow(null);
      setSelectedEntry(null); // Reset selectedEntry when modal closes

    };


    

    useEffect(() => {
      const fetchRecentActivities = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/users/recent`,
            {
              params: { limit: 20 },
              headers: user ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {}
            }
          );
          setDisplayedActivities(response.data.slice(0, 3)); // Zmiana z 2 na 3
        } catch (error) {
          console.error('Error fetching recent activities:', error);
        }
      };
  
      const fetchFollowingActivities = async () => {
        if (!user) return;
        
        setIsLoadingFollowing(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/users/following-activities`,
            {
              params: { limit: 5 },
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
          );
          setDisplayedFollowingActivities(response.data.slice(0, 3)); // Zmiana z 2 na 3
        } catch (error) {
          console.error('Error fetching following activities:', error);
        } finally {
          setIsLoadingFollowing(false);
        }
      };
  
      fetchRecentActivities();
      fetchFollowingActivities();
    }, [user]);

  


// Dodaj funkcję pomocniczą w komponencie
const isFemaleFirstName = (name) => {
  if (!name) return false;
  const firstName = name.split(' ')[0]; // Bierzemy pierwszy człon
  return firstName.endsWith('a') && 
         !['Kuba', 'Barnaba', 'Bonawentura'].includes(firstName); // Wyjątki męskich imion
};

  // Pobierz wydarzenia na bieżący tydzień
  useEffect(() => {
    const fetchWeeklyEvents = async () => {
      if (!activeCalendarLocation || isDataFetched) return;
    
      setIsLoadingWeekly(true);
      const today = new Date();
      const weekStart = startOfWeek(today, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(today, { weekStartsOn: 1 });
    
      try {
        // Pobierz dane tylko raz, używając daty początku tygodnia
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/calendar/events`, {
          params: {
            year: weekStart.getFullYear(),
            month: weekStart.getMonth() + 1,
            weekView: true,
            endDate: weekEnd.toISOString(), // Dodaj datę końcową do parametrów
            country: currentLocation?.key === 'POLAND' ? 'Polska' : null,
            city: currentLocation?.locations?.[0]
          }
        });

        // Filtruj wydarzenia tylko po lokalizacji
        const filteredEvents = response.data.filter(event => {
          const currentLocation = calendarLocations.find(loc => loc.key === activeCalendarLocation);
          const isInSelectedLocation = currentLocation.key === 'POLAND' ? 
            true : 
            (event.altLocation && currentLocation.locations.includes(event.altLocation)) ||
            (event.show.location && currentLocation.locations.includes(event.show.location));

          return isInSelectedLocation;
        });

        setWeeklyEvents(filteredEvents);
        setIsDataFetched(true);
      } catch (error) {
        console.error('Error fetching weekly events:', error);
      } finally {
        setIsLoadingWeekly(false);
      }
    };

    if (calendarLocations.length > 0) {
      fetchWeeklyEvents();
    }
  }, [activeCalendarLocation, calendarLocations, isDataFetched, currentLocation?.key, currentLocation?.locations]); // Dodane zależności

  // Dodaj nowe stany dla tooltipów
  const [tooltipState, setTooltipState] = useState({
    visible: false,
    active: false,
    event: null,
    target: null,
    dayIndex: null
  });

    // Zmodyfikuj funkcję getTooltipPlacement
    const getTooltipPlacement = (dayIndex) => {
      if (isMobile) {
        return "top";
      }
      return dayIndex >= 4 ? "left" : "right";
    };

  // Dodaj ref dla tooltipa
  const tooltipTimeoutRef = useRef(null);

  // Dodaj nowy ref dla tooltipa
  const tooltipRef = useRef(null);

  // Dodaj efekt do obsługi kliknięć poza tooltipem
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobile && tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipState(prev => ({
          ...prev,
          visible: false,
          active: false,
          target: null // Resetuj target przy zamykaniu
        }));
        setIsOverlayActive(false); // Dezaktywuj overlay
      }
    };
  
    if (isMobile && tooltipState.visible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile, tooltipState.visible]);

  // Dodaj efekt do wykrywania rozmiaru ekranu
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Cleanup przy odmontowaniu komponentu
  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);

  // Zmodyfikuj funkcję handleShowTooltip
  const handleShowTooltip = (event, target, dayIndex) => {
    if (isMobile) {
      if (tooltipState.target === target) {
        return;
      }
      
      setTooltipState(prev => ({
        ...prev,
        event,
        target,
        dayIndex,
        visible: true
      }));
  
      // Aktywuj overlay
      setIsOverlayActive(true);
  
      requestAnimationFrame(() => {
        setTooltipState(prev => ({
          ...prev,
          active: true
        }));
      });
    } else {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
      tooltipTimeoutRef.current = setTimeout(() => {
        setTooltipState({
          visible: true,
          active: true,
          event,
          target,
          dayIndex
        });
      }, 50);
    }
  };

  const handleHideTooltip = () => {
    if (!isMobile) {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
      tooltipTimeoutRef.current = setTimeout(() => {
        setTooltipState(prev => ({
          ...prev,
          active: false,
          visible: false
        }));
      }, 250);
    }
  };

  // Zmodyfikuj renderowanie weeklyCalendar, dodając obsługę tooltipów
  const renderWeeklyCalendar = () => {
    const today = new Date();
    const weekStart = startOfWeek(today, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(today, { weekStartsOn: 1 });
    const days = eachDayOfInterval({ start: weekStart, end: weekEnd });

    return (
      <div className="weekly-calendar">
        {days.map((day, index) => {
          const dayEvents = weeklyEvents.filter(event =>
            event.dates.some(date => 
              new Date(date).toDateString() === day.toDateString()
            )
          );

          const maxEventsToShow = 5;
          const hasMoreEvents = dayEvents.length > maxEventsToShow;

          return (
            <div 
              key={day.toISOString()} 
              className={`calendar-day ${dayEvents.length > 0 ? 'has-events' : ''}`}
              data-day-index={index}
              onClick={(e) => {
                if (e.target.classList.contains('calendar-day') || 
                    e.target.classList.contains('day-header') ||
                    e.target.classList.contains('day-name') ||
                    e.target.classList.contains('day-number') ||
                    e.target.classList.contains('day-events')) {
                  const params = new URLSearchParams();
                  params.set('date', day.toISOString());
                  handleNavigation(`/calendar/day?${params.toString()}`);
                }
              }}
            >
              <div className="day-header">
                <span className="day-name">{format(day, 'EEEE', { locale: currentLocale })}</span>
                <span className="day-number">{format(day, 'd', { locale: currentLocale })}</span>
              </div>
              <div className="day-events">
                {dayEvents.slice(0, maxEventsToShow).map((event, eventIndex) => {
                  // Pobierz wszystkie godziny spektakli w danym dniu
                  const todayShowTimes = event.dates.filter(date => 
                    new Date(date).toDateString() === day.toDateString()
                  );
                  
                  // Sprawdź czy jest więcej niż jeden spektakl w tym dniu
                  const hasMultipleShows = todayShowTimes.length > 1;

                  return (
                    <Link
                      key={`${event.show._id}-${eventIndex}`}
                      to={`/shows/${event.show.slug}`}
                      className={`overlay-shadow event-link ${event.altLocation ? event.altLocation.toLowerCase().replace(/\s+/g, '-') : event.show.location.toLowerCase().replace(/\s+/g, '-')} ${hasMultipleShows ? 'has-multiple' : ''}`}
                      onMouseEnter={(e) => !isMobile && handleShowTooltip(event, e.currentTarget, index)}
                      onMouseLeave={() => !isMobile && handleHideTooltip()}
                      onClick={(e) => {
                        if (isMobile) {
                          e.preventDefault();
                          handleShowTooltip(event, e.currentTarget, index);
                        }
                      }}
                    >
                      {event.show.title}
                      {hasMultipleShows && (
                        <span className="multiple-shows-icon">{isMobile ? '•' : '2'}</span>
                      )}
                    </Link>
                  );
                })}
                {hasMoreEvents && (
                  <Link
                    to={`/calendar/day?date=${day.toISOString()}`}
                    className="more-events-link"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {t('calendar.more')}...
                  </Link>
                )}
              </div>

            </div>
          );
        })}
      </div>
    );
  };

  // Dodaj nowy stan dla śledzenia ładowania plakatu
  const [isTooltipPosterLoading, setIsTooltipPosterLoading] = useState(true);

  // Zmodyfikuj efekt resetowania stanu loadera
  useEffect(() => {
    if (tooltipState.event) {
      // Resetuj stan ładowania za każdym razem gdy zmienia się event
      setIsTooltipPosterLoading(true);
      
      // Stwórz nowy Image object do preloadowania
      const img = new Image();
      img.src = tooltipState.event.show.poster && tooltipState.event.show.poster.includes('http')
        ? tooltipState.event.show.poster
        : `${process.env.REACT_APP_API_BASE_URL}/img${tooltipState.event.show.poster || ''}`;
      
      img.onload = () => setIsTooltipPosterLoading(false);
      img.onerror = () => setIsTooltipPosterLoading(false);
    }
  }, [tooltipState.event]);

  // Dodaj nowy stan dla ładowania plakatów
  const [posterLoadingStates, setPosterLoadingStates] = useState({});

  // 1. Na początku komponentu, gdzie są inne stany, dodaj:
  const [isInitialized, setIsInitialized] = useState(false);

  // 2. Dodaj useEffect do resetowania stanów ładowania przy zmianie danych
  useEffect(() => {
    if (!isInitialized && calendarLocations.length > 0) {
      setActiveCalendarLocation('POLAND');
      setIsInitialized(true);
      setIsDataFetched(false); // Reset stanu przy zmianie lokalizacji
    }
  }, [calendarLocations, isInitialized]);

  // 3. Funkcja handlePosterLoad pozostaje bez zmian
  const handlePosterLoad = (showId) => {
    setPosterLoadingStates(prev => ({
      ...prev,
      [showId]: false
    }));
  };

  // 4. Zmodyfikuj handler zmiany lokalizacji
  const handleCalendarLocationChange = (location) => {
    setIsDataFetched(false); // Reset stanu przed zmianą lokalizacji
    setActiveCalendarLocation(location);
  };

  // Dodaj nowy stan dla zakładek aktywności (na początku komponentu, gdzie są inne stany)
  const [activeActivityTab, setActiveActivityTab] = useState('following');

  useEffect(() => {

    
    // Najpierw sprawdzamy flagę z API
    if (user && user.hasSeenOnboarding === false) {
      // Tylko jeśli API mówi false, sprawdzamy localStorage
      const hasSeenOnboardingLocally = localStorage.getItem('hasSeenOnboarding') === 'true';
      
      if (!hasSeenOnboardingLocally) {

        startTransition(() => {
          setShowOnboardingModal(true);
        });
      } else {

        setShowOnboardingModal(false);
      }
    } else {

      setShowOnboardingModal(false);
    }
  }, [user]);

  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const handleCloseOnboardingModal = () => {
    setShowOnboardingModal(false);
    localStorage.setItem('hasSeenOnboarding', 'true');
    
    // Aktualizujemy lokalny stan użytkownika
    if (user) {
      setUser(prevUser => ({
        ...prevUser,
        hasSeenOnboarding: true
      }));
    }
  };

  // 1. Dodaj nowy stan na początku komponentu, gdzie są inne stany:
  const [isLoadingMyShows, setIsLoadingMyShows] = useState(true);

  const SkeletonLoaderTrending = ({ width, height }) => {
    return (
      <div className="show-card-wrapper pb-3">
        <div className="skeleton-card is-loading trending-show-card">
          <div className="show-image-layer">
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
        {isMobile && isOverlayActive && (
      <div 
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          zIndex: 1040
        }}
      />
    )}

    <Container fluid className="px-3 mt-4 homepage">

      <Row className="no-margin-row pos-relative homepage-master-show-header justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="d-flex align-items-center resp-pre-xl justify-content-between">
          <div className="hp-master-container mb-5">
            <div className="hp-master-left">
              <div className="greeting-section-desktop justify-content-center">
                <div className="d-flex align-items-center justify-content-between">
                  {user && user.name ? (
                    <>
                      <div className="greeting-text-container d-flex">
                        <h1 className="header-title">{t('greeting.hello')}, {user.name.split(' ')[0]}!</h1>
                        <p className="header-subtitle">{t('greeting.theatre_daily')}</p>
                      </div>
                    </>
                  ) : (
                    <div className="login-section">
                      <h1 className="header-title mb-1">{t('login.welcome')}</h1>
                      <h2 className="header-subtitle mb-3">{t('login.log_in_prompt')}</h2>


<div className="benefits-list-desktop mb-5">
  {[
    't(login.benefits.rating)',
    't(login.benefits.history)',
    't(login.benefits.wishlist)', 
    't(login.benefits.friends)',
  ].map((benefit, index) => (
    <div
      key={index}
      className="benefit-item"
      style={{
        animation: `fadeIn 0.3s ease-in both`,
        animationDelay: `${index * 0.15}s`
      }}
    >
      <img src={checkCircleIcon} alt="Check" className="check-icon" />
      {t(benefit.slice(2, -1))}
    </div>
  ))}
</div>


                      <Link to="/signup">
                        <Button variant="primary" className="quick-button mt-4 mb-4">{t('login.signup_button')}</Button>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <InputGroup className="search-input-group" onClick={openSearchModal}>
                    <FormControl
                      placeholder={t('search.placeholder')}
                      aria-label="Search"
                      style={{ paddingLeft: '40px' }}
                    />
                    <img src={searchIcon} alt="Search" className="search-icon" />
                  </InputGroup>
                </div>
                {user && user.name ? (
                  <>
                <div className="desktop-quick-links mt-5 mb-2">
                  <div className="d-q-header">{t('quick-links.title')}</div>
                  <div className="d-q-buttons">
                    <Button variant="primary" className="add-show-button btn-q-desktop" onClick={() => handleNavigation('/addToDiary')}>
                      <img src={plusIcon} alt="Add show" className="quick-icon" />
                      {t('my_shows.add-show')}
                    </Button>
                    <Button variant="light" className="quick-button btn-q-desktop" onClick={() => handleNavigation('/diary')}>
                      <img src={bigDiaryIcon} alt="Diary" className="quick-icon" />
                      {t('quick-links.your-shows')}
                    </Button>
                    <Button variant="light" className="quick-button btn-q-desktop" onClick={() => handleNavigation('/stats-rank')}>
                      <img src={heartIcon} alt="Profile" className="quick-icon" />
                      {t('quick-links.my-profile-short')}
                    </Button>
                    <Button variant="light" className="quick-button btn-q-desktop" onClick={() => handleNavigation('/best-played')}>
                      <img src={bestMusicalsIcon} alt="Best of currently played" className="quick-icon" />
                      {t('quick-links.best-currently-musicals-short')}
                    </Button>
                    <Button variant="light" className="quick-button btn-q-desktop" onClick={() => handleNavigation('/best-played-plays')}>
                      <img src={bestPlaysIcon} alt="Best of currently played" className="quick-icon" />
                      {t('quick-links.best-currently-plays-short')}
                    </Button>
                  </div>

                </div>
                </>
                  ) : (
                    <>

</>
                  )}
              </div>
            </div>
            <div className="hp-master-right">
              {/* Sekcja - Wyróżnione Spektakle */}
              <div className="featured-shows-desktop justify-content-center">
                  <div> 
                    <Slider className="mb-0" {...settings}>
                      {loadingNewFeaturedShows && !isFeaturedFullyLoaded ? (
                        Array.from({ length: 3 }).map((_, index) => (
                          <SkeletonLoaderFeaturedDesktop key={index} />
                        ))
                      ) : (
                        newFeaturedShows.map((show, index) => (
                          <div key={index} className="show-card-wrapper">
                            <Link
                              to={`/shows/${show.slug}`}
                              className="show-link"
                              onMouseDown={() => setIsDragging(false)}
                              onMouseMove={() => setIsDragging(true)}
                              onMouseUp={() => setTimeout(() => setIsDragging(false), 0)}
                              onClick={(e) => handleClick(e, show.slug)}
                            >
                              <Card className="featured-show-card">
                                <div className="show-image-layer">
                                  {show.background && (
                                    <Card.Img 
                                      variant="top" 
                                      src={show.background.includes('http') ? show.background : `${process.env.REACT_APP_API_BASE_URL}/img${show.background}`}
                                      className="show-image" 
                                    />
                                  )}
                                  {getUserRatingForShow(show._id) ? (
                                    <div className="user-rating-badge">
                                      <span>{getUserRatingForShow(show._id)}</span>
                                    </div>
                                  ) : (
                                    <div className="user-rating-badge-star">
                                      <img src={starIcon} alt="Rating" className="rating-star-badge" />
                                    </div>
                                  )}
                                </div>
                                <div className="show-info-layer">
                                  <div className="top-layer">
                                  <div className="show-poster">
  <Link to={`/shows/${show.slug}`} className="show-link">
    <div className="poster-container">
      <div className={`skeleton-loader ${posterLoadingStates[show._id] !== false ? 'visible' : ''}`} />
      <img 
        key={show._id}
        src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`} 
        alt={show.title} 
        className={`poster-img ${posterLoadingStates[show._id] === false ? 'visible' : 'hidden'}`}
        onLoad={() => handlePosterLoad(show._id)}
        onError={() => handlePosterLoad(show._id)}
      />
    </div>
  </Link>
</div>
                                    <div className="show-details">
                                    {show.avgRating > 0 && (
                                        <div className="rating-wrapper">
                                          <img src={starIcon} alt="Rating" className="rating-star" />
                                          <p className="average-rating">
                                            {typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}
                                          </p>
                                        </div>
                                      )}
                                      <h5 className="show-title">
                                        {show.title && show.title.length > 40 ? show.title.substring(0, 40) + "..." : show.title || ""}
                                      </h5>
                                      <div className="desktop-tags">
                                      
                                      
                                      <span className="theater-name">
                                      {show.theater && show.theater.length > 50 ? show.theater.substring(0, 50) + "..." : show.theater || ""}
                                      </span>
                                      <ShowTags show={show} t={t} />
                                      </div>
                                      <p className="show-description">
                                        {show.about && show.about.length > 138 ? show.about.substring(0, 138) + "..." : show.about || ""}
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </Card>
                            </Link>
                          </div>
                        ))
                      )}
                    </Slider>
                  </div>
                </div>

            </div>
          </div>
        </Col>
      </Row>

      <Row className="mb-5 greeting-section justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="d-flex align-items-center resp-pre-xl justify-content-between">
        {user && user.name ? (
  <>
    <div className="greeting-text-container d-flex">
      <h1 className="header-title">{t('greeting.hello')}, {user.name.split(' ')[0]}!</h1>
      <p className="header-subtitle">{t('greeting.theatre_daily')}</p>
    </div>
    <div className="user-panel">
      <Link to="/stats-rank">
        <img 
          src={user?.avatarUrl && user.avatarUrl.includes('http') 
            ? user.avatarUrl 
            : `${process.env.REACT_APP_API_BASE_URL}/img${user?.avatarUrl || '/default-avatar.png'}`} 
          alt="User Avatar" 
          className="user-avatar" 
        />
      </Link>
    </div>
  </>
) : (
  <div className="login-section">
    <h1 className="header-title mb-1">{t('login.welcome')}</h1>
    <p className="header-subtitle mb-4">{t('login.log_in_prompt')}</p>
    <div className="benefits-list mb-4">
    <img src={checkCircleIcon} alt="Check" className="check-icon-purple" />
                        <Typewriter
                          options={{
                            strings: [
                              t('login.benefits.rating'),
                              t('login.benefits.history'),
                              t('login.benefits.wishlist'),
                              t('login.benefits.friends'),
                              t('login.benefits.insights'),
                              t('login.benefits.add')
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 40,
                            deleteSpeed: 20,
                            pauseFor: 1400
                          }}
                        />
                      </div>
    <Link to="/signup">
      <Button variant="primary" className="quick-button mt-4">{t('login.signup_button')}</Button>
    </Link>

  </div>
)}
        </Col>
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="mt-3 resp-pre-xl">
        <InputGroup className="search-input-group" onClick={openSearchModal} >
          <FormControl
            placeholder={t('search.placeholder')}  // Użycie tłumaczenia dla placeholdera
            aria-label="Search"
            style={{ paddingLeft: '40px' }} // Odstęp dla ikony
          />
          <img src={searchIcon} alt="Search" className="search-icon" />
        </InputGroup>

      <SearchModal
        isOpen={isSearchModalOpen}
        onRequestClose={closeSearchModal}
        starIcon={starIcon}
        searchIcon={searchIcon}
        currentLocale={currentLocale}
        defaultPoster={defaultPoster}
        colorCircleCalendarIcon={colorCircleCalendarIcon}
        colorCircleStarIcon={colorCircleStarIcon}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        successIcon={successIcon}

getRatingDescription={getRatingDescription}

      />
        </Col>
      </Row>
 

     {/* Sekcja - Wyróżnione Spektakle */}
     <Row className="g-0 my-4 mb-5 featured-shows justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl px12">
          <h2 className="section-title mb-1">{t('featured_shows.title')}</h2>
        </Col>
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl px12"> 
        <div className="featured-shows-mobile">
          <Slider className="mb-0" {...settings}>
            {loadingNewFeaturedShows && !isFeaturedFullyLoaded ? (
              Array.from({ length: 3 }).map((_, index) => (
                <SkeletonLoaderFeatured key={index} />
              ))
            ) : (
              newFeaturedShows.map((show, index) => (
                <div key={index} className="show-card-wrapper">
                  <Link
                    to={`/shows/${show.slug}`}
                    className="show-link"
                    onMouseDown={() => setIsDragging(false)}
                    onMouseMove={() => setIsDragging(true)}
                    onMouseUp={() => setTimeout(() => setIsDragging(false), 0)}
                    onClick={(e) => handleClick(e, show.slug)}
                  >
                    <Card className="featured-show-card">
                      <div className="show-image-layer">
                      {show.background && (
                        <Card.Img 
                          variant="top" 
                          src={show.background.includes('http') ? show.background : `${process.env.REACT_APP_API_BASE_URL}/img${show.background}`}
                          className="show-image" 
                        />
                      )}
                        {getUserRatingForShow(show._id) ? (
                          <div className="user-rating-badge">
                            <span>{getUserRatingForShow(show._id)}</span>
                          </div>
                        ) : (
                          <div className="user-rating-badge-star">
                            <img src={starIcon} alt="Rating" className="rating-star-badge" />
                          </div>
                        )}
                        <div className="tags-featured">
                        
                        </div>
                      </div>
                      <div className="show-info-layer">
                        <div className="top-layer">
                        
                        <div className="show-poster">
                    <Link to={`/shows/${show.slug}`} className="show-link">
                      <div className="poster-container">
                        <div className={`skeleton-loader ${posterLoadingStates[show._id] !== false ? 'visible' : ''}`} />
                        <img 
                          key={show._id}
                          src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`} 
                          alt={show.title} 
                          className={`poster-img ${posterLoadingStates[show._id] === false ? 'visible' : 'hidden'}`}
                          onLoad={() => handlePosterLoad(show._id)}
                          onError={() => handlePosterLoad(show._id)}
                        />
                      </div>
                    </Link>
                  </div>
                          <div className="show-details">
                            <h5 className="show-title">
                              {show.title && show.title.length > 27 ? show.title.substring(0, 27) + "..." : show.title || ""}
                            </h5>
                            <p className="theater-name">{show.theater && show.theater.length > 34 ? show.theater.substring(0, 34) + "..." : show.theater || ""}</p>
                            <div className="rating-line-wrapper">
                            {show.avgRating > 0 && (
                              
                              <div className="rating-wrapper">
                                <img src={starIcon} alt="Rating" className="rating-star" />
                                <p className="average-rating">
                                  {typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}
                                </p>
                               
                              </div>
                             
                            )}
                             <ShowTags show={show} t={t} />
                             </div>
                          </div>
                        </div>
                        <p className="show-description">
                          {show.about && show.about.length > 120 ? show.about.substring(0, 120) + "..." : show.about || ""}
                        </p>
                      </div>
                    </Card>
                  </Link>
                </div>
              ))
            )}
          </Slider>
          </div>
        </Col>
      </Row>


{/* Modal dla wybranego spektaklu */}
<ShowModal 
starIcon={starIcon}
colorCircleCalendarIcon={colorCircleCalendarIcon}
            colorCircleStarIcon={colorCircleStarIcon}
            getRatingDescription={getRatingDescription}
            renderStarRating={renderStarRating}
            successIcon={successIcon}
            t={t}
            currentLocale={currentLocale}
            selectedEntry={selectedEntry} // <-- Pass selectedEntry to ShowModal

show={selectedShow} onClose={handleCloseModal} />

{user ? (
<Row className="g-0 mt-0 mb-1 justify-content-center hp-user-section no-margin-row">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
    <div className="personal-sections-wrapper my-and-activity">

      {/* Sekcja - Moje spektakle */}
      <div className="personal-section my-shows justify-content-center ">

        <h2 className="section-title">{t('my_shows.title')}</h2>

        {/* Zakładki */}
        <div className="show-tabs show-tabs-container">
          <Button 
            variant="link" 
            onClick={() => setActiveMyShowsList('Next')} 
            className={`tab ${activeMyShowsList === 'Next' ? 'active' : ''}`}
          >
            {t('my_shows.tabs.next')}
          </Button>
          <Button 
            variant="link" 
            onClick={() => setActiveMyShowsList('Seen')} 
            className={`tab ${activeMyShowsList === 'Seen' ? 'active' : ''}`}
          >
            {t('my_shows.tabs.seen')}
          </Button>
          <Button 
            variant="link" 
            onClick={() => setActiveMyShowsList('Wish-list')} 
            className={`tab ${activeMyShowsList === 'Wish-list' ? 'active' : ''}`}
          >
            {t('my_shows.tabs.wishlist')}
          </Button>
        </div>

        <div className="shows-grid-container">
          {/* Lista spektakli */}
          <div className="shows-grid">
            {isLoadingMyShows ? (
              // Zamiana standardowego loadera na skeleton loader
              <>
                {Array(6).fill().map((_, index) => (
                  <SkeletonLoaderMyShows key={index} />
                ))}
              </>
            ) : (
              <>
                {activeMyShowsList === 'Wish-list' ? (
                  <>
                    {displayedWishListShows.map((show, index) => {
                      // Dodajemy sprawdzenie czy show istnieje
                      if (!show) return null;

                      return (
                        <div key={index} className="show-card">
                          <Link to={`/shows/${show.slug}`} className="show-link">
                            <div className="show-poster">
                              <img src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`} alt={show.title} />
                            </div>
                          </Link>
                          <div className="show-info">
                            <Link to={`/shows/${show.slug}`} className="show-link">
                              <h5 className="show-title">{show.title}</h5>
                            </Link>

                            {/* Renderowanie przycisków na podstawie flagi `isRemoved` */}
                            {show.isRemoved ? (
                              <Button 
                                variant="success" 
                                className="add-again-button" 
                                onClick={() => addAgainToWishList(show._id)}
                                disabled={loadingButtons[show._id]} // Zablokuj przycisk, gdy trwa ładowanie
                              >
                                {loadingButtons[show._id] ? (
                                  <Spinner animation="border" size="sm" /> // Wyświetl spinner, gdy trwa ładowanie
                                ) : (
                                  t('my_shows.wishlist.add_again')
                                )}
                              </Button>
                            ) : (
                              <Button 
                                variant="danger" 
                                className="remove-button" 
                                onClick={() => removeFromWishList(show._id)}
                                disabled={loadingButtons[show._id]} // Zablokuj przycisk, gdy trwa ładowanie
                              >
                                {loadingButtons[show._id] ? (
                                  <Spinner animation="border" size="sm" /> // Wyświetl spinner, gdy trwa ładowanie
                                ) : (
                                  t('my_shows.wishlist.remove')
                                )}
                              </Button>
                            )}

                          </div>
                        </div>
                      );
                    })}
                    {wishListShows.length > displayedWishListShows.length && (
                      <Button variant="secondary" className="wishlist-more mt-3 btn-small" onClick={handleShowMoreWishList}>
                        {t('my_shows.wishlist.show_more')}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {activeMyShows.map((entry, index) => {
                      // Dodajemy sprawdzenie czy entry i entry.show istnieją
                      if (!entry || !entry.show) return null;

                      return index === 0 && activeMyShowsList === 'Next' ? (
                        // Specjalny wygląd dla pierwszego elementu w zakładce Next
                        <div key={index} className="show-card first-next-card" onClick={() => handleShowClick(entry)}>
                          <div className="show-poster">
                            <img 
                              src={entry.show.poster.includes('http') ? entry.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                              alt={entry.show.title} 
                            />
                          </div>
                          
                          {entry.userRating ? (
                            <div className="user-rating-badge">
                              <span>{entry.userRating}</span>
                            </div>
                          ) : (
                            <div className="user-rating-badge-star">
                              <img src={starIcon} alt="Rating" className="rating-star-badge" />
                            </div>
                          )}
                          <div className="show-info">
                            <h5 className="show-title">{entry.show.title}</h5>
                            <div className="show-date">
                              <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                              <span>
                                {(!entry.dateWatched || entry.dateWatched === null) 
                                ? t('common.no_date') 
                                : new Date(entry.dateWatched).toLocaleDateString()
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // Standardowy wygląd dla pozostałych elementów
                        <div key={index} className="show-card" onClick={() => handleShowClick(entry)}>
                          <div className="show-poster">
                            <img 
                              src={entry.show.poster.includes('http') ? entry.show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                              alt={entry.show.title} 
                            />
                          </div>
                          
                          {entry.userRating ? (
                            <div className="user-rating-badge">
                              <span>{entry.userRating}</span>
                            </div>
                          ) : (
                            <div className="user-rating-badge-star">
                              <img src={starIcon} alt="Rating" className="rating-star-badge" />
                            </div>
                          )}
                          <div className="show-info">
                            <h5 className="show-title">{entry.show.title}</h5>
                            <div className="show-date">
                              <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                              <span>
                                {(!entry.dateWatched || entry.dateWatched === null) 
                                ? t('common.no_date') 
                                : new Date(entry.dateWatched).toLocaleDateString()
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </>
            )}
          </div>

          <div className="center-container">
            {isLoadingMyShows ? (
              // Usuń pusty div podczas ładowania
              null
            ) : (
              <>
                {activeMyShowsList !== 'Wish-list' && activeMyShows.length >= 6 && (
                  <div className="all-shows-info">
                    <img src={infoIcon} alt="Info" className="info-icon" />
                    {t('my_shows.all-info')}
                  </div>
                )}

                {activeMyShowsList === 'Next' && activeMyShows.length === 0 && (
                  <div className="all-shows-info">
                    <img src={infoIcon} alt="Info" className="info-icon" />
                    {t('my_shows.empty-info')}
                  </div>
                )}

                {activeMyShowsList === 'Seen' && activeMyShows.length === 0 && (
                  <div className="all-shows-info">
                    <img src={infoIcon} alt="Info" className="info-icon" />
                    {t('my_shows.empty-seen-info')}
                  </div>
                )}

                {activeMyShowsList === 'Wish-list' && wishListShows.length === 0 && (
                  <div className="all-shows-info">
                    <img src={infoIcon} alt="Info" className="info-icon" />
                    {t('my_shows.empty-wishlist-info')}
                  </div>
                )}

                {activeMyShowsList === 'Wish-list' && wishListShows.length >= 1 && wishListShows.length <= displayedWishListShows.length && (
                  <div className="all-shows-info more-wishlist-info">
                    <img src={infoIcon} alt="Info" className="info-icon" />
                    {t('my_shows.more-wishlist-info')}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="my-shows-buttons mt-2">
            {/* Przycisk dodawania spektaklu do dziennika */}
            <Link to="/diary">
              <Button variant="light" className="add-show-button">
                {t('my_shows.see-all')}
              </Button>
            </Link>
            <Link to="/addToDiary">
              <Button variant="primary" className="add-show-button">
                {t('my_shows.add-show')}
              </Button>
            </Link>
          </div>
        </div>
      </div>

      {/* Sekcja - Activities z */}
      <div className="personal-section personal-activities">
        <h2 className="section-title">
          {t('activities.homepage-title')}
        </h2>
        {/* Zakładki */}
        <div className="show-tabs show-tabs-container mb-4">
          <Button 
            variant="link" 
            onClick={() => setActiveActivityTab('following')} 
            className={`tab ${activeActivityTab === 'following' ? 'active' : ''}`}
          >
            {t('activities.hp-title-following')}
          </Button>
          <Button 
            variant="link" 
            onClick={() => setActiveActivityTab('all')} 
            className={`tab ${activeActivityTab === 'all' ? 'active' : ''}`}
          >
            {t('activities.hp-title-all')}
          </Button>
        </div>
        <div className="activity-column-container">
          <>
            {isLoadingFollowing ? (
              <div className="skeleton-following-container">
                    <SkeletonLoaderActivity />
    <SkeletonLoaderActivity />
    <SkeletonLoaderActivity />
              </div>
            ) : (
              <div className="topics-container">
                {activeActivityTab === 'following' && displayedFollowingActivities.length === 0 ? (
                  <div class="activities-empty-wrapper">
                  <div className="all-shows-info">
                    <img src={infoIcon} alt="Info" className="info-icon" />
                    {t('activities.empty-following-info')}
                  </div>
                  </div>
                ) : (
                  /* Wyświetl odpowiednie aktywności w zależności od wybranej zakładki */
                  (activeActivityTab === 'following' ? displayedFollowingActivities : displayedActivities).map((activity, index) => {
                    const isFemale = isFemaleFirstName(activity.user?.name);
                    return (
                      <Card className="latest-topic topic-card" key={index}>
                        <Card.Body>
                          <div className="user-activity-container">
                            <div className="activity-user">
                              <Link to={`/user/${activity.user.googleId}`} className="show-link">
                                <img 
                                  src={activity.user.avatarUrl && activity.user.avatarUrl.includes('http') 
                                    ? activity.user.avatarUrl 
                                    : `${process.env.REACT_APP_API_BASE_URL}/img${activity.user.avatarUrl || '/default-avatar.png'}`} 
                                  alt="User Avatar" 
                                />
                              </Link>
                            </div>
                            <div className="single-activity-container">
                              <div className="activity-date">{formatDate(activity.processedAt)}</div>
                              <Link to={`/user/${activity.user.googleId}`} className="show-link">
                                <span className="activity-username">{activity.user.name}&nbsp;</span>
                              </Link>
                              {activity.type === 'RATING_ENTRIES' ? (
                                isFemale ? t('activities.has-rated-f') : t('activities.has-rated-m')
                              ) : (
                                isFemale ? t('activities.has-added-f') : t('activities.has-added-m')
                              )}
                              {activity.entries.map((entry, entryIndex) => (
                                <div key={entryIndex} className="single-activity mt-2">
                                  <div className="activity-show-info">
                                    <div>
                                      {entry.show.isVerified ? (
                                        <Link to={`/shows/${entry.show.slug}`} className="show-link">
                                          <img 
                                            src={entry.show.poster.includes('http') 
                                              ? entry.show.poster 
                                              : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                                            alt={entry.show.title} 
                                            className="show-mini-poster" 
                                          />
                                        </Link>
                                      ) : (
                                        <img 
                                          src={entry.show.poster.includes('http') 
                                            ? entry.show.poster 
                                            : `${process.env.REACT_APP_API_BASE_URL}/img${entry.show.poster}`} 
                                          alt={entry.show.title} 
                                          className="show-mini-poster" 
                                        />
                                      )}
                                    </div>
                                    <div className="activity-content">
                                      {entry.show.isVerified ? (
                                        <Link to={`/shows/${entry.show.slug}`} className="show-link">
                                          <span className="show-title">{entry.show.title}</span>
                                        </Link>
                                      ) : (
                                        <span className="show-title">{entry.show.title}</span>
                                      )}
                                      
                                      <span className="show-info">
                                        {entry.show.theater && entry.show.location 
                                          ? `${entry.show.theater}, ${entry.show.location}` 
                                          : entry.show.theater || entry.show.location}
                                      </span>

                                      <span className="show-info">
                                        <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                                        {(!entry.dateWatched || entry.dateWatched === null) 
                                          ? t('common.no_date') 
                                          : new Date(entry.dateWatched).toLocaleDateString()
                                        }
                                      </span>
                                    
                                    </div>
                                  </div>
                                  {activity.type === 'RATING_ENTRIES' && entry.rating > 0 && (
                                    <div className="activity-rating">
                                      <div className="number-rating">{entry.rating}</div> {renderStarRating(entry.rating)}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })
                )}
              </div>
            )}
          </>
          <Button 
            variant="light" 
            className="see-rank-button mt-3 show-more-button" 
            onClick={() => handleNavigation('/feed')}
          >
            {t('buttons.show_more')}
          </Button>
        </div>
      </div>
    </div>
  </Col>
</Row>
) : null}


{/* Sekcja - Popularne w tym tygodniu */}
<Row className="g-0 mt-4 mb-5 justify-content-center trending-section">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl">
    <h2 className="section-title mb-2">{t('trending.title')}</h2>
  </Col>
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl"> 
    {loadingTrendingShows ? (
      <Slider className="mb-0" {...settings_trending}>
        {Array.from({ length: 5 }).map((_, index) => (
          <SkeletonLoaderTrending key={index} />
        ))}
      </Slider>
    ) : (
      <Slider className="mb-0" {...settings_trending}>
        {trendingShows.map((show, index) => (
          <div key={index} className="show-card-wrapper">
            <Link
              to={`/shows/${show?.slug || ''}`}
              className="show-link"
              onMouseDown={() => setIsDragging(false)}
              onMouseMove={() => setIsDragging(true)}
              onMouseUp={() => setTimeout(() => setIsDragging(false), 0)}
              onClick={(e) => show?.slug && handleClick(e, show.slug)}
            >
              <Card className="featured-show-card">
                <div className="show-image-layer">
                  <Card.Img 
                    variant="top" 
                    src={show?.background && !show.background.includes('default-poster')
                      ? show.background.includes('http') 
                        ? show.background 
                        : `${process.env.REACT_APP_API_BASE_URL}/img${show.background}`
                      : show?.poster && !show.poster.includes('default-poster')
                        ? show.poster.includes('http')
                          ? show.poster
                          : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`
                        : null
                    }
                    className="show-image" 
                  />
                  <div className="show-info-layer">
                    <div className="show-info">
                      {show && (
                        <div className="trending-category desktop-tags">
                          <ShowTags show={show} t={t} />
                        </div>
                      )}
                      {show?.title && <h3 className="trending-title">{show.title}</h3>}
                      {(show?.theater || show?.location) && (
                        <p className="trending-theater">
                          {show.theater}
                          {show.theater && show.location && (
                            <span className="trending-location">, {show.location}</span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </Link>
          </div>
        ))}
      </Slider>
    )}
  </Col>
</Row>

{/* Nowa sekcja kalendarza tygodniowego */}
<Row className="g-0 my-5 mb-5 justify-content-center weekly-section">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl mt-1">
    <div className="section-title-wrapper">
      <h2 className="section-title">
        <Link to={`/calendar`} className="title-link" onClick={() => handleNavigation('/calendar')}>
          {t('calendar.this-week')}
          <svg 
            width="8" 
            height="14" 
            viewBox="0 0 8 14" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="section-title-arrow"
            aria-hidden="true"
            role="img"
          >
            <path 
              d="M1 13L7 7L1 1" 
              stroke="currentColor" 
              strokeWidth="2.5" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </h2>
      <Button 
        variant="light" 
        className="display-desktop-only"
        onClick={() => handleNavigation('/calendar')}
      >
        {t('calendar.see-full')}
      </Button>
    </div>
  </Col>
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl weekly-l-c">
  {calendarLocations.length > 0 && (  // Dodaj to sprawdzenie
    <div className="location-links show-tabs-container">
      {calendarLocations.map((location) => (
        <Button
          key={location.key}
          variant="link"
          onClick={() => handleCalendarLocationChange(location.key)}
          className={`location-tab ${activeCalendarLocation === location.key ? 'active' : ''}`}
        >
          {location.label}
        </Button>
      ))}
    </div>
  )}
  {isLoadingWeekly ? (
    <div className="text-center my-5 spinner-container">
      <div className="d-flex text-center align-items-center justify-content-center w-100"><div className="loader"></div></div>
    </div>
  ) : weeklyEvents.length > 0 ? (  // Dodaj sprawdzenie czy są wydarzenia
    renderWeeklyCalendar()
  ) : (
    <div className="text-center no-events-container">
      <p className="no-events">{t('calendar.no-events-city')}</p>
    </div>
  )}
  </Col>
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center center-container justify-content-center resp-pre-xl mt-2 display-mobile-only">
<Button 
    variant="primary" 
    className="hp-see-calendar-button mt-3" 
    onClick={() => handleNavigation('/calendar')}
  >
    {t('calendar.see-full')}
  </Button>
</Col>
</Row>


{/* Sekcja - Najlepsze grane MUSICAL */}
<Row className="g-0 best-played my-5 mb-5 justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl">
    <div className="section-title-wrapper">
      <h2 className="section-title">
        <Link to={`/best-played`} className="title-link" onClick={() => handleNavigation('/best-played')}>
          <span>{t('best-played.title')}: {t('common.musicals')}</span>
          <svg 
            width="8" 
            height="14" 
            viewBox="0 0 8 14" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="section-title-arrow"
            aria-hidden="true"
            role="img"
          >
            <path 
              d="M1 13L7 7L1 1" 
              stroke="currentColor" 
              strokeWidth="2.5" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </h2>
      <Button 
        variant="light" 
        className="display-desktop-only"
        onClick={() => handleNavigation('/best-played')}
      >
        {t('best-played.see-full')}
      </Button>
    </div>

    {/* Zakładki lokalizacji */}
    <div className="location-links show-tabs-container">
    {activeMusicalsLocations.map(tab => (
    <Button
      key={tab.key}
      variant="link"
      onClick={() => handleChangeMusicalsLocation(tab.locations)}
      className={
        currentMusicalsLocation.length === tab.locations.length &&
        tab.locations.every(location => currentMusicalsLocation.includes(location))
          ? 'active'
          : ''
      }
    >
      {tab.label}
    </Button>
  ))}
</div>
  </Col>

  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="best-played-col resp-pre-xl">
    {/* Wyświetlanie posortowanych spektakli lub SkeletonLoader */}
    {loadingMusicals ? (
      <>
        {Array(6).fill().map((_, index) => (
          <SkeletonLoaderBest key={index} />
        ))}
      </>
    ) : (
      musicalsWithDynamicRank.map((show, index) => (
        <div key={index} className="show-card" onClick={() => handleNavigation(`/shows/${show.slug}`)}>
          {/* Plakat i ocena */}

        <div className="under-ranking">
          {show.dynamicRank}
          </div>

          <div className="show-poster">
            <Link to={`/shows/${show.slug}`} className="show-link">
              <div className="poster-container">
                <div className={`skeleton-loader ${posterLoadingStates[show._id] !== false ? 'visible' : ''}`} />
                <img 
                  key={show._id}
                  src={show.poster && show.poster.includes('http') 
                    ? show.poster 
                    : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`}
                  alt={show.title}
                  className={`poster-img ${posterLoadingStates[show._id] === false ? 'visible' : 'hidden'}`}
                  onLoad={() => handlePosterLoad(show._id)}
                  onError={() => handlePosterLoad(show._id)}
                />
              </div>
            </Link>
            {getUserRatingForShow(show._id) ? (
              <div className="user-rating-badge">
                <span>{getUserRatingForShow(show._id)}</span>
              </div>
            ) : (
              <div className="user-rating-badge-star">
                <img src={starIcon} alt="Rating" className="rating-star-badge" />
              </div>
            )}
                    <div className="above-ranking">
                      <div className="rank-number">
          {show.dynamicRank}
          </div>
          </div>
          </div>

          {/* Info o spektaklu */}
          <div className="show-info">
          <div className="ranking">
              <img src={rankIcon} alt="Ranking" className="rank-icon" />
              <span>{show.dynamicRank}.</span>
            </div>
            <div className="show-info-basic">
              <Link to={`/shows/${show.slug}`} className="show-link">
                <h5 className="show-title">{show.title}</h5>
              </Link>
              <div className="theater-info">
              {show.theater && show.theater.length > 64 ? show.theater.substring(0, 64) + "..." : show.theater || ""}

              </div>
              <div className="location">{show.location}</div>
            </div>
            <div className="rating">
              <img src={starIcon} alt="Rating" className="rating-star" />
              <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
            </div>
            <div className="tags">
<ShowTags show={show} t={t} />
            </div>
          </div>
        </div>
      ))
    )}
  </Col>

  {/* Przycisk */}
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center center-container justify-content-center resp-pre-xl mt-2 display-mobile-only">
    <Button 
      variant="primary" 
      className="see-rank-button" 
      onClick={() => handleNavigation('/best-played')}
    >
      {t('best-played.see-full')}
    </Button>
  </Col>
</Row>



{/* Sekcja - Najlepsze grane DRAMAT */}
<Row className="g-0 best-played my-5 mb-5 justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl">
    <div className="section-title-wrapper">
      <h2 className="section-title">
        <Link to={`/best-played-plays`} className="title-link" onClick={() => handleNavigation('/best-played-plays')}>
          <span>{t('best-played.title')}: {t('common.plays')}</span>
          <svg 
            width="8" 
            height="14" 
            viewBox="0 0 8 14" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="section-title-arrow"
            aria-hidden="true"
            role="img"
          >
            <path 
              d="M1 13L7 7L1 1" 
              stroke="currentColor" 
              strokeWidth="2.5" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </h2>
      <Button 
        variant="light" 
        className="display-desktop-only"
        onClick={() => handleNavigation('/best-played-plays')}
      >
        {t('best-played.see-full')}
      </Button>
    </div>

    {/* Zakładki lokalizacji */}
    <div className="location-links show-tabs-container">
    {activePlaysLocations.map(tab => (
    <Button
      key={tab.key}
      variant="link"
      onClick={() => handleChangePlaysLocation(tab.locations)}
      className={
        currentPlaysLocation.length === tab.locations.length &&
        tab.locations.every(location => currentPlaysLocation.includes(location))
          ? 'active'
          : ''
      }
    >
      {tab.label}
    </Button>
  ))}
</div>
  </Col>

  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="best-played-col resp-pre-xl">
    {/* Wyświetlanie posortowanych spektakli lub SkeletonLoader */}
    {loadingPlays ? (
      <>
        {Array(6).fill().map((_, index) => (
          <SkeletonLoaderBest key={index} />
        ))}
      </>
    ) : (
      playsWithDynamicRank.map((show, index) => (
        <div key={index} className="show-card" onClick={() => handleNavigation(`/shows/${show.slug}`)}>
          {/* Plakat i ocena */}
          <div className="show-poster">
            <Link to={`/shows/${show.slug}`} className="show-link">
              <div className="poster-container">
                <div className={`skeleton-loader ${posterLoadingStates[show._id] !== false ? 'visible' : ''}`} />
                <img 
                  key={show._id}
                  src={show.poster && show.poster.includes('http') 
                    ? show.poster 
                    : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`}
                  alt={show.title}
                  className={`poster-img ${posterLoadingStates[show._id] === false ? 'visible' : 'hidden'}`}
                  onLoad={() => handlePosterLoad(show._id)}
                  onError={() => handlePosterLoad(show._id)}
                />
              </div>
            </Link>
            {getUserRatingForShow(show._id) ? (
              <div className="user-rating-badge">
                <span>{getUserRatingForShow(show._id)}</span>
              </div>
            ) : (
              <div className="user-rating-badge-star">
                <img src={starIcon} alt="Rating" className="rating-star-badge" />
              </div>
            )}
          </div>

          {/* Info o spektaklu */}
          <div className="show-info">
            <div className="ranking">
              <img src={rankIcon} alt="Ranking" className="rank-icon" />
              <span>{show.dynamicRank}.</span>
            </div>
            <div className="show-info-basic">
              <Link to={`/shows/${show.slug}`} className="show-link">
                <h5 className="show-title">{show.title}</h5>
              </Link>
              <div className="theater-info">{show.theater}</div>
              <div className="location">{show.location}</div>
            </div>
            <div className="rating">
              <img src={starIcon} alt="Rating" className="rating-star" />
              <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
            </div>
            <div className="tags">
<ShowTags show={show} t={t} />
            </div>
          </div>
        </div>
      ))
    )}
  </Col>

  {/* Przycisk */}
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center center-container justify-content-center resp-pre-xl mt-2 display-mobile-only">
    <Button 
      variant="primary" 
      className="see-rank-button" 
      onClick={() => handleNavigation('/best-played-plays')}
    >
      {t('best-played.see-full')}
    </Button>
  </Col>
</Row>


           {/* Sekcja - Premiery sezonu */}
     <Row className="g-0 my-5 mb-5 justify-content-center this-season">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl px12">
  <h2 className="section-title mb-1">{t('this_season.title')}</h2>
  </Col>
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl px12"> 

{loadingNewPremiereShows ? (
  <div className="loader-container full-width">
    <div className="loader"></div>
  </div>
) : (
  <Slider className="mb-0" {...settings_trending}>
    {sortedNewPremiereShows.map((show, index) => (
      <div key={index} className="show-card-wrapper pb-3">
        <Link
          to={`/shows/${show.slug}`}
          className="show-link"
          onMouseDown={() => setIsDragging(false)}
          onMouseMove={() => setIsDragging(true)}
          onMouseUp={() => setTimeout(() => setIsDragging(false), 0)}
          onClick={(e) => handleClick(e, show.slug)}
        >
          <Card className="featured-show-card">
            <div className="show-image-layer">
              <Card.Img 
                variant="top" 
                src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                className="show-image" 
              />
              {getUserRatingForShow(show._id) && getUserRatingForShow(show._id) > 0 ? (
                <div className="user-rating-badge2"><span>{getUserRatingForShow(show._id)}</span></div>
              ) : (
                <div className="user-rating-badge-star2">
                  <img src={starIcon} alt="Rating" className="rating-star-badge2" />
                </div>
              )}
            </div>
            <div className="show-info-layer">
              <div className="show-info">
                <div>
              <div className="show-date">
                  <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                  <span>{formatDate(show.premiere)}</span>
                </div>
                <h5 className="show-title">{show.title}</h5>
                </div>
                <div>
                <p className="theater-name">{show.theater && show.theater.length > 36 ? show.theater.substring(0, 36) + "..." : show.theater || ""}</p>
                <p className="theater-location">{show.location && show.location.length > 120 ? show.location.substring(0, 120) + "..." : show.location || ""}</p>
                {show && (
                        <div className="show-tags">
                          <ShowTags show={show} t={t} />
                        </div>
                      )}
                </div>
              </div>
            </div>
          </Card>
        </Link>
      </div>
    ))}
  </Slider>
)}
  </Col>
</Row>


      {/* Sekcja - Szybkie linki */}
      <Row className="g-0 mb-5 my-4 quick-links justify-content-center">
  <Col xs={12} sm={12} md={12} lg={11} xl={10} className="text-center resp-pre-xl">
    <h2 className="section-title">{t('quick-links.title')}</h2>
    <div className="my-shows-buttons">
      {/* Te przyciski przewiną stronę na górę i przeładują */}
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/diary')}>
        <img src={bigDiaryIcon} alt="Diary" className="quick-icon" />
        {t('quick-links.your-shows')}
      </Button>
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/stats-rank')}>
        <img src={heartIcon} alt="Best of currently played" className="quick-icon" />
        {t('quick-links.stats-rank')}
        </Button>
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/calendar')}>
        <img src={calendarIcon} alt="Best of currently played" className="quick-icon" />
        {t('quick-links.calendar')}
      </Button>
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/activity')}>
        <img src={activityIcon} alt="Best of all time" className="quick-icon" />
        {t('quick-links.activity')}
      </Button>


    </div>
    <div className="my-shows-buttons">
      {/* Te przyciski przewiną stronę na górę i przeładują */}
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/best-played')}>
        <img src={bestMusicalsIcon} alt="Diary" className="quick-icon" />
        {t('quick-links.best-currently-musicals')}
      </Button>
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/best-played-plays')}>
        <img src={bestPlaysIcon} alt="Best of currently played" className="quick-icon" />
        {t('quick-links.best-currently-plays')}
      </Button>
      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/best-all-time')}>
        <img src={rankMusicalsIcon} alt="Best of currently played" className="quick-icon" />
        {t('quick-links.best-alltime-musicals')}
        </Button>

      <Button variant="light" className="quick-button" onClick={() => handleNavigation('/best-all-time-plays')}>
        <img src={rankPlaysIcon} alt="Best of all time" className="quick-icon" />
        {t('quick-links.best-alltime-plays')}
      </Button>


    </div>
  </Col>
</Row>



    </Container>

    <Overlay
      show={tooltipState.visible}
      target={tooltipState.target}
      placement={tooltipState.dayIndex !== null ? getTooltipPlacement(tooltipState.dayIndex) : "right"}
      container={document.body}
      transition={false}
      offset={[0, 8]}
    >
      {(props) => (
        <Tooltip 
          id="show-tooltip" 
          {...props} 
          className={`show-tooltip ${tooltipState.active ? 'show' : ''}`}
        >
          {tooltipState.event && (
            <div 
              ref={tooltipRef}
              className="show-tooltip-content"
              onMouseEnter={() => {
                if (!isMobile && tooltipTimeoutRef.current) {
                  clearTimeout(tooltipTimeoutRef.current);
                }
                setTooltipState(prev => ({ ...prev, visible: true, active: true }));
              }}
              onMouseLeave={() => !isMobile && handleHideTooltip()}
              onClick={() => navigate(`/shows/${tooltipState.event.show.slug}`)}
              style={{ cursor: 'pointer' }}
            >
              <div className="tooltip-poster-container">
                <div className="tooltip-poster">
                  <div className={`skeleton-loader ${isTooltipPosterLoading ? 'visible' : ''}`} />
                  {tooltipState.event && (
                    <img 
                      key={tooltipState.event.show._id}
                      src={tooltipState.event.show.poster && tooltipState.event.show.poster.includes('http')
                        ? tooltipState.event.show.poster
                        : `${process.env.REACT_APP_API_BASE_URL}/img${tooltipState.event.show.poster || ''}`}
                      alt={tooltipState.event.show.title}
                      className={`poster-img ${isTooltipPosterLoading ? 'hidden' : 'visible'}`}
                    />
                  )}
                </div>
              </div>
              <div className="tooltip-info">
                <div className="tooltip-location">
                  {tooltipState.event.altLocation || tooltipState.event.show.location}
                </div>
                <h4>{tooltipState.event.show.title}</h4>
                <div className="tooltip-theater">{tooltipState.event.show.theater}</div>
                {tooltipState.event.show.avgRating > 0 && (
                  <div className="tooltip-rating">
                    <img src={starIcon} alt="Rating" className="rating-star" />
                    <span>{tooltipState.event.show.avgRating.toFixed(1)}</span>
                  </div>
                )}
                {tooltipState.target?.classList.contains('has-multiple') && (
                  <div className="multiple-shows-tag">
                    2 {t('calendar.shows-today')}
                  </div>
                )}
              </div>
              <div className="tooltip-chevrons">
                <img src={chevronsRightIcon} alt="Chevrons right" className="chevron-right" />
              </div>
            </div>
          )}
        </Tooltip>
      )}
    </Overlay>

    <OnboardingModal 
      showOnboardingModal={showOnboardingModal}
      handleCloseOnboardingModal={handleCloseOnboardingModal}
      t={t}
    />

    </>
  );
}

export default Homepage;