import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const Contact = ( {
  t
} ) => {
  return (
    <Container fluid className="px-3 mb-5 ">
                  <Row className="title-row mb-5 justify-content-center no-margin-row">
              <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center title-col">
                <div className="page-title-container">
                  <h1 className="page-title">{t('about.contact')}</h1>
                  </div>
              </Col>
            </Row>
      <Row className="justify-content-center about-page mt-5">
      <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">



          <div className="row-contact mb-5">
            <div className="about-section-header mb-3">

            
          <h2 className="about-section-title mt-1">{t('about.row-contact.title')}</h2>
          <h3 className="about-section-subtitle contact-info"><strong>{t('about.row-contact.subtitle')}</strong></h3>
          </div>
          </div>

        </Col>
      </Row>


    </Container>
  );
};

export default Contact;
