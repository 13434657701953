import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

function GoogleSignUp({ setUser, t, shieldIcon, keyIcon, userIcon }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  

  const responseGoogle = async (response) => {
    setLoading(true);
    console.log('=== GOOGLE SIGN UP ===');
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/google-login`, {
            tokenId: response.credential
        }, {
            withCredentials: true
        });

        console.log('Login response:', res.data);
        
        // Zapisz token w localStorage
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        
        setUser(res.data.user);
        navigate('/');
    } catch (error) {
        console.error('Login error:', error);
        setError(t('signup.error'));
    } finally {
        setLoading(false);
    }
  };

  return (
    <GoogleOAuthProvider clientId="690754398286-r6ogc9atog29jfi7shdeomt19h03ma2v.apps.googleusercontent.com">
      <Container fluid className="px-3 mt-4 main-container login-page">
        <Row className="g-0 text-center justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="d-flex justify-content-center google-col resp-pre-xl">
            <div className="login-section text-center">
              <h1 className="header-title">{t('signup.title')}</h1>
              <p className="header-subtitle">{t('signup.subtitle')} {t('signup.subtitle2')}</p>
              
            </div>
            {error && <p className="text-danger">{error}</p>}

            <div className="google-button-div my-4">
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <GoogleLogin
                  onSuccess={responseGoogle}
                  className={'google-button'}
                  onError={() => setError(t('signup.error'))}
                  useOneTap
                />
              )}
            </div>
          </Col>
        </Row>

        <Row className="g-0 text-center justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="d-flex justify-content-center google-col resp-pre-xl mt-3">
              <div className="login-security mt-3">
                <div className="login-security-1">
                  <img src={shieldIcon} className="security-icon" alt="Secure" />
                  <span>{t('login.security_top')}&nbsp;
                     <span onClick={(e) => {e.preventDefault(); setShowMore(!showMore)}} className="security-more">
                   {showMore ? t('login.read_less') : t('login.read_more')}
                  </span>
                 </span>
                 </div>
               {showMore && (
                 <div className="mb-0 mt-2 login-security-2">
                   <img src={userIcon} className="security-icon" alt="Secure" />{t('login.security_bottom')}
                 </div>
               )}
               </div>
          </Col>
        </Row>

        <Row className="g-0 text-center justify-content-center mt-4">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
            <Link to="/">
              <Button variant="light" className="quick-button">{t('signup.back-button')}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </GoogleOAuthProvider>
  );
}

export default GoogleSignUp;