import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';

function CookieConsent({ t }) {
  const [show, setShow] = useState(false);

  const deleteCookies = () => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const cookieName = cookie.split('=')[0].trim();
      if (cookieName.startsWith('_ga') || cookieName.startsWith('_gid') || cookieName.startsWith('MUID')) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${window.location.hostname}`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
      }
    }
  };

  const applyTrackingPreferences = useCallback((status) => {
    if (status === 'accepted') {
      // Włącz Google Analytics
      window['ga-disable-' + process.env.REACT_APP_GA_TRACKING_ID] = false;
      
      // Włącz Microsoft Clarity
      if (window.clarity) {
        window.clarity("consent");
      }
    } else {
      // Wyłącz Google Analytics
      window['ga-disable-' + process.env.REACT_APP_GA_TRACKING_ID] = true;
      
      // Wyłącz Microsoft Clarity
      if (window.clarity) {
        window.clarity("stop");
      }

      // Usuń istniejące ciasteczka analityczne
      deleteCookies();
    }
  }, []);

  useEffect(() => {
    const consentStatus = localStorage.getItem('cookieConsent');
    if (!consentStatus) {
      setShow(true);
    } else {
      // Zastosuj zapisane preferencje przy ładowaniu
      applyTrackingPreferences(consentStatus);
    }
  }, [applyTrackingPreferences]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    applyTrackingPreferences('accepted');
    setShow(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    applyTrackingPreferences('rejected');
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      document.body.classList.remove('modal-open');
      document.body.classList.add('cookie-consent-open');
      document.documentElement.classList.add('cookie-consent-open');
      document.body.style.overflow = 'auto';
    } else {
      document.body.classList.remove('cookie-consent-open');
      document.documentElement.classList.remove('cookie-consent-open');
      document.body.style.overflow = '';
    }
  
    return () => {
      document.body.classList.remove('cookie-consent-open');
      document.documentElement.classList.remove('cookie-consent-open');
      document.body.style.overflow = '';
    };
  }, [show]);

  return (
    <Modal 
      show={show} 
      backdrop="false" 
      keyboard={false} 
      dialogClassName="modal-dialog-bottom"  
      className="no-blur-modal"
      backdropClassName="custom-backdrop"
    >
      <Modal.Header>
      </Modal.Header>
      <Modal.Body>
        <p>{t('cookies.content')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAccept}>
          {t('cookies.accept')}
        </Button>
        <Button variant="secondary" onClick={handleReject}>
          {t('cookies.reject')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CookieConsent;
