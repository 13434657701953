import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const WelcomeModal = ({ 
  showWelcomeModal, 
  handleCloseWelcomeModal, 
  t,
  welcomeModalVideo,
  heartIcon,
  calendarIcon,
  bigDiaryIcon,
  userIcon
}) => {
  return (
    <Modal 
      backdrop="true"
      centered
      className="welcome-modal"
      onHide={handleCloseWelcomeModal}
      show={showWelcomeModal}
    >
      <Modal.Header closeButton className="modal-header">
      </Modal.Header>
      <Modal.Body>
        <div className="welcome-modal-container">
          <div className="welcome-modal-image-container">
            {showWelcomeModal && (
              <video 
                autoPlay 
                muted 
                playsInline
                className="welcome-modal-image"
              >
                <source src={welcomeModalVideo} type="video/mp4" />
              </video>
            )}
          </div>
          <div className="modal-section">
            <div className="welcome-content">
              <h2>{t('welcome.title')}</h2>
              <div className="welcome-features">
                <div className="feature">
                  <div className="feature-title-container">
                    <img src={heartIcon} alt="Rankings" className="feature-icon" />
                    <h3>{t('welcome.features.rankings')}</h3>
                  </div>
                  <p>{t('welcome.features.rankings_desc')}</p>
                </div>

                <div className="feature">
                  <div className="feature-title-container">
                    <img src={calendarIcon} alt="Calendar" className="feature-icon" />
                    <h3>{t('welcome.features.calendar')}</h3>
                  </div>
                  <p>{t('welcome.features.calendar_desc')}</p>
                </div>

                <div className="feature">
                  <div className="feature-title-container">
                    <img src={bigDiaryIcon} alt="Rating" className="feature-icon" />
                    <h3>{t('welcome.features.rating')}</h3>
                  </div>
                  <p>{t('welcome.features.rating_desc')}</p>
                </div>

                <div className="feature">
                  <div className="feature-title-container">
                    <img src={userIcon} alt="Friends" className="feature-icon" />
                    <h3>{t('welcome.features.friends')}</h3>
                  </div>
                  <p>{t('welcome.features.friends_desc')}</p>
                </div>
              </div>

              <Button variant="primary" className="welcome-modal-button" onClick={handleCloseWelcomeModal}>
                {t('welcome.get_started')}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal; 