import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Container, Table, Form, Button, Row, Col, Collapse, Alert } from 'react-bootstrap';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

const ModeratorPanel = ({ user }) => {
  const [shows, setShows] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [verificationFilter, setVerificationFilter] = useState('all');
  const [editingShow, setEditingShow] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [sortField, setSortField] = useState('title');
  const [sortDirection, setSortDirection] = useState('asc');
  const [expandedRows, setExpandedRows] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [posterFile, setPosterFile] = useState(null);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [myChanges, setMyChanges] = useState([]);
  const [showMyChanges, setShowMyChanges] = useState(false);
  const [hasMoreChanges, setHasMoreChanges] = useState(true);
  const [isLoadingChanges, setIsLoadingChanges] = useState(false);
  const currentChangesPageRef = useRef(1);

  const ITEMS_PER_PAGE = 20;
  const abortControllerRef = useRef(null);
  const timeoutRef = useRef(null);

  const MODERATOR_IDS = ['103430856178263876454', '107910795253308612999', '111664177168673544131'];

  const fetchShows = useCallback(async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      abortControllerRef.current = new AbortController();
      
      if (page === 1) {
        setIsSearching(true);
      } else {
        setIsLoadingMore(true);
      }

      await new Promise((resolve) => {
        timeoutRef.current = setTimeout(resolve, 300);
      });

      const params = new URLSearchParams();
      if (searchTitle) params.append('title', searchTitle.trim());
      params.append('page', page);
      params.append('limit', ITEMS_PER_PAGE);
      
      switch(verificationFilter) {
        case 'verified':
          params.append('isVerified', 'true');
          break;
        case 'softVerified':
          params.append('onlySoftVerified', 'true');
          break;
        case 'unverified':
          params.append('completelyUnverified', 'true');
          break;
        default:
          break;
      }
      
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/moderator/shows?${params.toString()}`, {
        headers: { 'Authorization': `Bearer ${token}` },
        signal: abortControllerRef.current.signal
      });

      setShows(prevShows => page === 1 ? response.data.shows : [...prevShows, ...response.data.shows]);
      setHasMore(response.data.shows.length === ITEMS_PER_PAGE);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error fetching shows:', error);
      }
    } finally {
      setIsInitialLoading(false);
      setIsSearching(false);
      setIsLoadingMore(false);
    }
  }, [searchTitle, verificationFilter, page]);

  const fetchMyChanges = useCallback(async (loadMore = false) => {
    try {
      setIsLoadingChanges(true);
      const token = localStorage.getItem('token');
      
      const pageToFetch = loadMore ? currentChangesPageRef.current + 1 : 1;
      
      const response = await axios.get(`/api/moderator/changes?page=${pageToFetch}&limit=10`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      setMyChanges(prev => loadMore ? [...prev, ...response.data.changes] : response.data.changes);
      setHasMoreChanges(response.data.hasMore);
      
      currentChangesPageRef.current = pageToFetch;
    } catch (error) {
      console.error('Error fetching my changes:', error);
    } finally {
      setIsLoadingChanges(false);
    }
  }, []);

  const handleSave = async () => {
    try {
      const moderatorNote = prompt('Dodaj opcjonalną notatkę dla administratora:');
      
      const token = localStorage.getItem('token');
      const formData = new FormData();
      
      Object.keys(editedValues).forEach(key => {
        if (key === 'poster' && posterFile) {
          formData.append('poster', posterFile);
        } else if (key === 'background' && backgroundFile) {
          formData.append('background', backgroundFile);
        } else if (key === 'tags' && Array.isArray(editedValues[key])) {
          formData.append('tags', JSON.stringify(editedValues[key]));
        } else {
          formData.append(key, editedValues[key]);
        }
      });

      if (moderatorNote) {
        formData.append('moderatorNote', moderatorNote);
      }

      await axios.post(
        `/api/moderator/shows/${editingShow._id}/propose`, 
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      
      setMessage({
        type: 'success',
        text: 'Zmiany zostały wysłane do weryfikacji przez administratora'
      });
      setEditingShow(null);
      setEditedValues({});
      setPosterFile(null);
      setBackgroundFile(null);
      
      await fetchMyChanges();
      
    } catch (error) {
      console.error('Error proposing changes:', error);
      setMessage({
        type: 'danger',
        text: 'Wystąpił błąd podczas zapisywania zmian'
      });
    }
  };

  const handleSort = (field) => {
    if (field === 'title') {
        const sortedShows = [...shows].sort((a, b) => {
          const direction = sortDirection === 'asc' ? 1 : -1;
          return direction * a.title.localeCompare(b.title);
        });
        setShows(sortedShows);
      }
      setSortDirection(field === sortField ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc');
      setSortField(field);
  };

  const toggleRowExpand = (showId) => {
    setExpandedRows(prev => ({
      ...prev,
      [showId]: !prev[showId]
    }));
  };

  const handleEdit = (show) => {
    setEditingShow(show);
    setEditedValues({});
  };

  const handleInputChange = (field, value) => {
    setEditedValues(prev => {
      if (Array.isArray(value) && value.length === 0) {
        const newValues = { ...prev };
        delete newValues[field];
        return newValues;
      }
      if (typeof value === 'boolean' && !value && ['showForKids'].includes(field)) {
        const newValues = { ...prev };
        delete newValues[field];
        return newValues;
      }
      return {
        ...prev,
        [field]: value
      };
    });
  };

  const handlePosterChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPosterFile(file);
      handleInputChange('poster', file);
    }
  };

  const handleBackgroundChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackgroundFile(file);
      handleInputChange('background', file);
    }
  };

  const handlePosterUrlChange = (e) => {
    const url = e.target.value;
    setPosterFile(null);
    handleInputChange('poster', url);
  };

  const handleBackgroundUrlChange = (e) => {
    const url = e.target.value;
    setBackgroundFile(null);
    handleInputChange('background', url);
  };

  const SHOW_CATEGORIES = ['play', 'musical', 'opera', 'concert', 'other'];
  const SHOW_TYPES = ['comedy', 'jukebox', 'revue'];
  const SHOW_ORIGINS = ['broadway', 'west-end', 'polish', 'german', 'korean'];

  useEffect(() => {
    setPage(1);
    setShows([]);
  }, [searchTitle, verificationFilter]);

  useEffect(() => {
    fetchShows();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [fetchShows]);

  useEffect(() => {
    if (showMyChanges) {
      currentChangesPageRef.current = 1;
      fetchMyChanges(false);
    }
  }, [showMyChanges, fetchMyChanges]);

  useEffect(() => {
    console.log('State changed:', {
      myChangesLength: myChanges.length,
      hasMoreChanges,
      isLoadingChanges
    });
  }, [myChanges.length, hasMoreChanges, isLoadingChanges]);

  const getStatusStyle = (status) => {
    switch (status) {
      case 'approved':
        return { color: '#198754' }; // zielony
      case 'rejected':
        return { color: '#dc3545' }; // czerwony
      default:
        return {};
    }
  };

  const handleLoadMore = useCallback(() => {
    fetchMyChanges(true);
  }, [fetchMyChanges]);

  if (!user || !MODERATOR_IDS.includes(user.googleId)) {
    return <Container>Brak uprawnień moderatora</Container>;
  }

  if (isInitialLoading) {
    return <Container>Ładowanie...</Container>;
  }

  return (
    <Container fluid className="moderator-panel px-3 mb-5">
      {message && (
        <Alert variant={message.type} onClose={() => setMessage(null)} dismissible>
          {message.text}
        </Alert>
      )}
   <Row className="mb-4">
        <Col md={5}>
          <Form.Group>
            <Form.Label>Wyszukaj po tytule</Form.Label>
            <Form.Control
              type="text"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              placeholder="Wpisz tytuł..."
            />
            {isSearching && <div className="mt-2">Wyszukiwanie...</div>}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Status weryfikacji</Form.Label>
            <Form.Select
              value={verificationFilter}
              onChange={(e) => setVerificationFilter(e.target.value)}
            >
              <option value="all">Wszystkie</option>
              <option value="verified">Zweryfikowane</option>
              <option value="softVerified">Zostawione na platformie</option>
              <option value="unverified">Niezweryfikowane</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={2} className="mb-3 admin-duplicate">
          <Button 
            variant="warning" 
            className="mt-4"
            onClick={() => {
              const note = prompt('Wpisz notatkę dla administratora:');
              if (note) {
                axios.post('/api/moderator/notes', 
                  { note },
                  { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }}
                )
                .then(() => {
                  setMessage({
                    type: 'success',
                    text: 'Notatka została wysłana do administratora'
                  });
                })
                .catch(error => {
                  console.error('Error sending note:', error);
                  setMessage({
                    type: 'danger',
                    text: 'Wystąpił błąd podczas wysyłania notatki'
                  });
                });
              }
            }}
          >
            Wyślij notatkę do admina
          </Button>
        </Col>
      </Row>

      <style>
        {`
          .admin-table {
            font-size: 0.9rem;
          }
          .admin-table th {
            padding: 0.5rem;
            vertical-align: middle;
          }
          .admin-table td {
            padding: 0.5rem;
          }
          .admin-table th {
            white-space: normal;
            word-wrap: break-word;
          }
          .status-column {
            width: 100px;
            min-width: 80px;
            max-width: 80px;
          }
          .action-buttons .btn {
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
          }
        `}
      </style>

      <Table striped bordered responsive className="admin-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('title')} style={{ cursor: 'pointer', width: '240px' }}>
              Tytuł {sortField === 'title' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th>
              Slug {sortField === 'slug' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th style={{ width: '60px' }}>Plakat</th>
            <th>Data premiery</th>
            <th>Teatr</th>
            <th>Kategoria</th>
            <th>Lokalizacja</th>
            <th>Kraj</th>
            <th className="status-column">
              Aktualnie grany
            </th>
            <th className="status-column">
              Zweryfikowany
            </th>
            <th className="status-column">
              Zostawiony
            </th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {shows.map(show => (
            <React.Fragment key={show._id}>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                  <Button 
                      variant="link" 
                      onClick={() => toggleRowExpand(show._id)}
                      className="arrow"
                    >
                      {expandedRows[show._id] ? '▼' : '▶'}
                    </Button>
                    <span className="me-2">{editingShow?._id === show._id ? (
                      <Form.Control
                        type="text"
                        defaultValue={show.title}
                        onChange={(e) => handleInputChange('title', e.target.value)}
                      />
                    ) : show.title}</span>

                  </div>
                </td>
                <td>{show.slug}</td>
                <td>
                  {editingShow?._id === show._id ? (
                    <div>
                      <Form.Control
                        type="file"
                        onChange={handlePosterChange}
                        accept="image/*"
                        className="mb-2"
                      />
                      <Form.Control
                        type="text"
                        placeholder="lub wklej URL"
                        onChange={handlePosterUrlChange}
                        defaultValue={show.poster}
                      />
                    </div>
                  ) : (
                    <img 
                      src={
                        show?.poster?.includes('http')
                          ? show?.poster
                          : `${process.env.REACT_APP_API_BASE_URL}/img${show?.poster}`
                      }
                      alt={show.title} 
                      
                      className="admin-poster"
                    />
                  )}
                </td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Control
                      type="date"
                      defaultValue={show.premiere ? new Date(show.premiere).toISOString().split('T')[0] : ''}
                      onChange={(e) => handleInputChange('premiere', e.target.value)}
                    />
                  ) : (show.premiere ? format(new Date(show.premiere), 'dd.MM.yyyy', { locale: pl }) : 'Brak daty')}
                </td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Control
                      type="text"
                      defaultValue={show.theater}
                      onChange={(e) => handleInputChange('theater', e.target.value)}
                    />
                  ) : show.theater || '-'}
                </td>
                <td>{show.showCategory?.join(', ') || '-'}</td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Control
                      type="text"
                      defaultValue={show.location}
                      onChange={(e) => handleInputChange('location', e.target.value)}
                    />
                  ) : show.location || '-'}
                </td>
                <td>{show.country || '-'}</td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Check
                      type="checkbox"
                      checked={editedValues.currentlyPlayed ?? show.currentlyPlayed}
                      onChange={(e) => handleInputChange('currentlyPlayed', e.target.checked)}
                    />
                  ) : (
                    show.currentlyPlayed ? '✅' : '❌'
                  )}
                </td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Check
                      type="checkbox"
                      checked={editedValues.isVerified ?? show.isVerified}
                      onChange={(e) => handleInputChange('isVerified', e.target.checked)}
                    />
                  ) : (
                    show.isVerified ? '✅' : '❌'
                  )}
                </td>
                <td>
                  {editingShow?._id === show._id ? (
                    <Form.Check
                      type="checkbox"
                      checked={editedValues.isSoftVerified ?? show.isSoftVerified}
                      onChange={(e) => handleInputChange('isSoftVerified', e.target.checked)}
                    />
                  ) : (
                    show.isSoftVerified ? '✅' : '❌'
                  )}
                </td>
                <td className="action-buttons">
                  {editingShow?._id === show._id ? (
                    <>
                      <Button variant="success" size="sm" onClick={handleSave} className="me-2 mb-1">
                        Zaproponuj zmiany
                      </Button>
                      <Button variant="secondary" size="sm" onClick={() => {
                        setEditingShow(null);
                        setEditedValues({});
                        setPosterFile(null);
                        setBackgroundFile(null);
                      }}>
                        Anuluj
                      </Button>
                    </>
                  ) : (
                    <Button variant="primary" size="sm" onClick={() => handleEdit(show)} className="me-2 mb-1">
                      Edytuj
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="11" className="p-0">
                  <Collapse in={expandedRows[show._id]}>
                    <div className="p-3">
                      <Row>
                        <Col md={4}>
                          <h6>Podstawowe informacje</h6>
                          {editingShow?._id === show._id ? (
                            <>
                              <Form.Group className="mb-2">
                                <Form.Label>Autor</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.author}
                                  onChange={(e) => handleInputChange('author', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Reżyseria</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.direction}
                                  onChange={(e) => handleInputChange('direction', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Muzyka</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.musicAuthor}
                                  onChange={(e) => handleInputChange('musicAuthor', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Tłumaczenie</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.translation}
                                  onChange={(e) => handleInputChange('translation', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Czas trwania (min)</Form.Label>
                                <Form.Control
                                  type="number"
                                  defaultValue={show.duration}
                                  onChange={(e) => handleInputChange('duration', e.target.value)}
                                />
                              </Form.Group>
                            </>
                          ) : (
                            <>
                              <p>Autor: {show.author || '-'}</p>
                              <p>Reżyseria: {show.direction || '-'}</p>
                              <p>Muzyka: {show.musicAuthor || '-'}</p>
                              <p>Tłumaczenie: {show.translation || '-'}</p>
                              <p>Czas trwania: {show.duration ? `${show.duration} min` : '-'}</p>
                            </>
                          )}
                        </Col>
                        <Col md={4} className="classification">
  <h6>Klasyfikacja spektaklu</h6>
  {editingShow?._id === show._id ? (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Kategoria</Form.Label>
        <div>
          {SHOW_CATEGORIES.map(category => (
            <Form.Check
              key={category}
              type="checkbox"
              inline
              label={category.charAt(0).toUpperCase() + category.slice(1)}
              checked={editedValues.showCategory?.includes(category) ?? show.showCategory?.includes(category) ?? false}
              onChange={(e) => {
                const currentCategories = editedValues.showCategory ?? show.showCategory ?? [];
                const newCategories = e.target.checked
                  ? [...currentCategories, category]
                  : currentCategories.filter(c => c !== category);
                handleInputChange('showCategory', newCategories.length > 0 ? newCategories : undefined);
              }}
            />
          ))}
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Typ</Form.Label>
        <div>
          {SHOW_TYPES.map(type => (
            <Form.Check
              key={type}
              type="checkbox"
              inline
              label={type.charAt(0).toUpperCase() + type.slice(1)}
              checked={editedValues.showType?.includes(type) ?? show.showType?.includes(type) ?? false}
              onChange={(e) => {
                const currentTypes = editedValues.showType ?? show.showType ?? [];
                const newTypes = e.target.checked
                  ? [...currentTypes, type]
                  : currentTypes.filter(t => t !== type);
                handleInputChange('showType', newTypes.length > 0 ? newTypes : undefined);
              }}
            />
          ))}
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Dla dzieci</Form.Label>
        <Form.Check
          type="checkbox"
          label="Tak"
          checked={editedValues.showForKids ?? show.showForKids ?? false}
          onChange={(e) => handleInputChange('showForKids', e.target.checked)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Pochodzenie</Form.Label>
        <div>
          {SHOW_ORIGINS.map(origin => (
            <Form.Check
              key={origin}
              type="checkbox"
              inline
              label={origin.charAt(0).toUpperCase() + origin.slice(1)}
              checked={editedValues.showOrigin?.includes(origin) ?? show.showOrigin?.includes(origin) ?? false}
              onChange={(e) => {
                const currentOrigins = editedValues.showOrigin ?? show.showOrigin ?? [];
                const newOrigins = e.target.checked
                  ? [...currentOrigins, origin]
                  : currentOrigins.filter(o => o !== origin);
                handleInputChange('showOrigin', newOrigins.length > 0 ? newOrigins : undefined);
              }}
            />
          ))}
        </div>
      </Form.Group>
    </>
  ) : (
    <>
      <p>Kategoria: {show.showCategory?.join(', ') || '-'}</p>
      <p>Typ: {show.showType?.join(', ') || '-'}</p>
      <p>Dla dzieci: {show.showForKids ? 'Tak' : 'Nie'}</p>
      <p>Pochodzenie: {show.showOrigin?.join(', ') || '-'}</p>
    </>
  )}
</Col>

                        <Col md={4}>
                          <h6>Dodatkowe informacje</h6>
                          {editingShow?._id === show._id ? (
                            <>
                              <Form.Group className="mb-2">
                                <Form.Label>Kraj</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.country}
                                  onChange={(e) => handleInputChange('country', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Tagi (oddzielone przecinkami) (archiwalne)</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.tags?.join(', ')}
                                  onChange={(e) => handleInputChange('tags', e.target.value.split(',').map(tag => tag.trim()))}
                                  disabled
                                  
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Link do biletów</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={show.ticketUrl}
                                  onChange={(e) => handleInputChange('ticketUrl', e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Opis</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  defaultValue={show.about}
                                  onChange={(e) => handleInputChange('about', e.target.value)}
                                />
                              </Form.Group>
                            </>
                          ) : (
                            <>
                              <p>Kraj: {show.country || '-'}</p>
                              <p>Tagi: {show.tags?.join(', ') || '-'}</p>
                              <p>Link do biletów: {show.ticketUrl || '-'}</p>
                              <p>Opis: {show.about || '-'}</p>
                            </>
                          )}
                        </Col>
                        <Col md={4}>
                          <h6>Obrazy</h6>
                          {editingShow?._id === show._id ? (
                            <>
                              <Form.Group className="mb-2">
                                <Form.Label>Plakat</Form.Label>
                                <Form.Control
                                  type="file"
                                  onChange={handlePosterChange}
                                  accept="image/*"
                                  className="mb-2"
                                />
                                <Form.Control
                                  type="text"
                                  placeholder="lub wklej URL"
                                  onChange={handlePosterUrlChange}
                                  defaultValue={show.poster}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>Tło</Form.Label>
                                <Form.Control
                                  type="file"
                                  onChange={handleBackgroundChange}
                                  accept="image/*"
                                  className="mb-2"
                                />
                                <Form.Control
                                  type="text"
                                  placeholder="lub wklej URL"
                                  onChange={handleBackgroundUrlChange}
                                  defaultValue={show.background}
                                />
                              </Form.Group>
                            </>
                          ) : (
                            <>
                              <p>Plakat: {show.poster || '-'}</p>
                              <p>Tło: {show.background || '-'}</p>
                            </>
                          )}
                        </Col>

                      </Row>
                    </div>
                  </Collapse>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      {hasMore && (
        <div className="text-center mt-3 mb-4">
          <Button 
            variant="primary" 
            onClick={() => setPage(prev => prev + 1)}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? 'Ładowanie...' : 'Załaduj więcej'}
          </Button>
        </div>
      )}

      <div className="mt-4">
        <Button 
          variant="info" 
          onClick={() => setShowMyChanges(!showMyChanges)}
          className="mb-3"
        >
          Moje proponowane zmiany ({myChanges.filter(change => change.status === 'pending').length})
        </Button>

        {showMyChanges && (
          <>
            {console.log('Rendering changes table:', { 
              changesCount: myChanges.length, 
              hasMoreChanges
            })}
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Spektakl</th>
                  <th style={{ width: '500px' }}>Proponowane zmiany</th>
                  <th>Data</th>
                  <th>Status</th>
                  <th>Moja notatka</th>
                  <th>Komentarz admina</th>
                </tr>
              </thead>
              <tbody>
                {myChanges.map(change => (
                  <tr key={change._id}>
                    <td>
                    {change.show ? (
                      <>
                        <a href={`https://showify.pl/shows/${change.show.slug}`} target="_blank" rel="noopener noreferrer">{change.show.title}</a>
                        {change.show.theater && (
                          <div className="text-muted small">{change.show.theater}</div>
                        )}
                        <div className="text-muted small">{change.show.slug}</div>
                      </>
                    ) : (
                      'Notatka bez spektaklu'
                    )}
                    </td>
                    <td style={{ width: '500px', fontSize: '11px', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {change.show ? JSON.stringify(change.changes, null, 2) : change.changes.note}
                    </td>
                    <td>{new Date(change.createdAt).toLocaleDateString()}</td>
                    <td style={getStatusStyle(change.status)}>
                      {change.status === 'pending' && 'Oczekuje'}
                      {change.status === 'approved' && 'Zatwierdzono'}
                      {change.status === 'rejected' && 'Odrzucono'}
                    </td>
                    <td>{change.moderatorNote || '-'}</td>
                    <td>{change.comment || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {hasMoreChanges && (
              <div className="text-center mt-3 mb-4">
                <Button 
                  variant="primary" 
                  onClick={handleLoadMore}
                  disabled={isLoadingChanges}
                >
                  {isLoadingChanges ? 'Ładowanie...' : 'Załaduj więcej'}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default ModeratorPanel; 