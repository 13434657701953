import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Explore = ({ t, compassIcon, bestPlaysIcon, bestMusicalsIcon, rankPlaysIcon, rankMusicalsIcon, activityIcon, calendarIcon }) => {
  const [popularShows, setPopularShows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPopularShows = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/popular-shows`);
        setPopularShows(response.data.slice(0, 18)); // Pobierz tylko 18 spektakli
      } catch (error) {
        console.error('Error fetching popular shows:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPopularShows();
  }, []);

  const exploreBlocks = [
    {
        title: t('explore.calendar.title'),
        subtitle: t('explore.calendar.subtitle'),
        link: '/calendar',
        icon: calendarIcon,
        gradient: 'linear-gradient(135deg, #027A48, #12B76A)'
      },
    {
      title: t('explore.currently_played.title'),
      subtitle: t('explore.currently_played.subtitle'),
      link: '/best-played',
      icon: bestMusicalsIcon,
      gradient: 'linear-gradient(135deg, #7F56D9, #B692F6)'
    },
    {
      title: t('explore.currently_played_plays.title'),
      subtitle: t('explore.currently_played_plays.subtitle'),
      link: '/best-played-plays',
      icon: bestPlaysIcon,
      gradient: 'linear-gradient(135deg, #1570EF, #53B1FD)'
    },
    {
      title: t('explore.activity.title'),
      subtitle: t('explore.activity.subtitle'),
      link: '/feed',
      icon: activityIcon,
      gradient: 'linear-gradient(135deg, #DC6803, #FDB022)'
    },
    {
      title: t('explore.all_time.title'),
      subtitle: t('explore.all_time.subtitle'),
      link: '/best-all-time',
      icon: rankMusicalsIcon,
      gradient: 'linear-gradient(135deg, #53389E, #9E77ED)'
    },
    {
      title: t('explore.all_time_plays.title'),
      subtitle: t('explore.all_time_plays.subtitle'),
      link: '/best-all-time-plays',
      icon: rankPlaysIcon,
      gradient: 'linear-gradient(135deg, #175CD3, #53B1FD)'
    }
  ];


  return (
    <Container fluid className="px-3  main-container explore">

            <Row className="title-row mb-5 justify-content-center no-margin-row">
              <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center title-col">
                <div className="page-title-container">
                  <img src={compassIcon} alt="Explore" className="title-icon" />
                  <h1 className="page-title">{t('explore.title')}</h1>
                </div>
              </Col>
            </Row>

      <Row className="g-0 mb-5 justify-content-center">

        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
          <div className="explore-grid">
            {exploreBlocks.map((block, index) => (
              <Link 
                key={index} 
                to={block.link} 
                className="explore-block"
                style={{
                  background: block.gradient,
                  textDecoration: 'none'
                }}
              >
                <img src={block.icon} className="explore-icon" alt={block.title} />
                <div className="block-description">
                <h2>{block.title}</h2>
                <p>{block.subtitle}</p>
                </div>
              </Link>
            ))}
          </div>


          {/* Nowa sekcja z popularnymi spektaklami */}
          <div className="diary-section mt-5">
           
              <div>
                <h1 className="section-title mb-4">{t('explore.popular.title')}</h1>
          
              </div>
            

            
              <div className="shows-grid">
                {isLoading ? (
                  <div className="loader-container"><div className="loader"></div></div>
                ) : (
                  popularShows.map((show, index) => (
                    <Link 
                      to={`/shows/${show.slug}`} 
                      key={index} 
                      className="show-card"
                    >
                      <div className="show-poster">
                        <img 
                          src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                          alt={show.title} 
                        />
                      </div>

                     
                    </Link>
                  ))
                )}
              </div>
            </div>
          
        </Col>
      </Row>
    </Container>
  );
};

export default Explore; 