import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShowTags from './components/ShowTags';

const TheaterShows = ({
  getUserRatingForShow,
  starIcon,
  user,
  t,
  bigRankAllIcon,
  handleNavigation,
  rankIcon
}) => {
  const [theaterInput, setTheaterInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [shows, setShows] = useState([]);
  const [posterLoadingStates, setPosterLoadingStates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [visibleEntriesCount, setVisibleEntriesCount] = useState(51);
  const [hasMore] = useState(true);

  // Pobieranie danych
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/shows`);
        setShows(response.data);
      } catch (error) {
        console.error('Error fetching shows:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // Funkcja znajdująca najczęściej występującą wartość w tablicy
  const getMostCommon = (arr) => {
    return arr.sort((a,b) =>
      arr.filter(v => v === a).length - arr.filter(v => v === b).length
    ).pop();
  };

  const calculateGridDimensions = (count) => {
    if (count <= 4) return { columns: 2, width: '486px' };
    if (count <= 9) return { columns: 3, width: '314px' };
    if (count <= 16) return { columns: 4, width: '230px' };
    if (count <= 25) return { columns: 5, width: '180px' };
    return { columns: 6, width: '147px' };
  };

  // Filtrowanie i przetwarzanie pokazów
  const { filteredShows, commonTheater, commonLocation } = useMemo(() => {
    if (!theaterInput && !locationInput) {
      return { filteredShows: [], commonTheater: '', commonLocation: '' };
    }

    const normalizedTheaterInput = theaterInput.trim().toLowerCase();
    const normalizedLocationInput = locationInput.trim().toLowerCase();

    const filtered = shows.filter(show => {
      // Zabezpieczenie przed undefined
      const theaters = (show.theater || '').split(',').map(t => t.trim().toLowerCase());
      const locations = (show.location || '').split(',').map(l => l.trim().toLowerCase());

      const matchesTheater = !normalizedTheaterInput || 
        theaters.some(t => t.includes(normalizedTheaterInput));
      const matchesLocation = !normalizedLocationInput || 
        locations.some(l => l.includes(normalizedLocationInput));

      return matchesTheater && matchesLocation;
    });

    // Zabezpieczenie przed undefined w flatMap
    const allTheaters = filtered.flatMap(show => 
      (show.theater || '').split(',').map(t => t.trim())
    );
    const allLocations = filtered.flatMap(show => 
      (show.location || '').split(',').map(l => l.trim())
    );

    return {
      filteredShows: filtered,
      commonTheater: allTheaters.length ? getMostCommon(allTheaters) : '',
      commonLocation: allLocations.length ? getMostCommon(allLocations) : ''
    };
  }, [shows, theaterInput, locationInput]);

  // Oblicz wymiary siatki na podstawie liczby wyników
  const gridDimensions = calculateGridDimensions(filteredShows.length);

  const handlePosterLoad = (showId) => {
    setPosterLoadingStates(prev => ({
      ...prev,
      [showId]: false
    }));
  };

  const loadMoreEntries = () => {
    setVisibleEntriesCount(prevCount => prevCount + 51);
  };

  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }

  return (
    <>
    <Container fluid className="px-3 main-container theater-summary">

      <Row className="g-0 mb-4 mt-3 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
          <Form>
            <Form.Group className="small-form">
              <Form.Control
                type="text"
                placeholder={t('theater.enter_theater')}
                value={theaterInput}
                onChange={(e) => setTheaterInput(e.target.value)}
              />
                            <Form.Control
                type="text"
                placeholder={t('theater.enter_location')}
                value={locationInput}
                onChange={(e) => setLocationInput(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>

<Row className="g-0 posters-grid justify-content-center mt-5">
  <Col xs={12} className="posters-container" style={{
    display: 'grid',
    gridTemplateColumns: `repeat(${gridDimensions.columns}, ${gridDimensions.width})`,
    gap: '20px',
    padding: '10px',
    margin: '0 auto',
    width: `calc(${gridDimensions.columns} * ${gridDimensions.width} + ${(gridDimensions.columns - 1) * 5}px)`, // dokładna szerokość dla siatki
    justifyContent: 'center'
  }}>
    {filteredShows.map(show => (
      <div 
        key={show._id} 
        onClick={() => handleNavigation(`/shows/${show.slug}`)}
        style={{
          width: gridDimensions.width,
          paddingBottom: '150%',
          position: 'relative'
        }}
      >
        <Link 
          to={`/shows/${show.slug}`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        >
          <div style={{
            width: '100%',
            height: '100%',
            borderRadius: '12px',
            overflow: 'hidden',
            position: 'relative'
          }}>
            <div className={`skeleton-loader ${
              posterLoadingStates[show._id] !== false ? 'visible' : ''
            }`} style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }} />
            <img 
              key={show._id}
              src={show.poster && show.poster.includes('http') 
                ? show.poster 
                : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`}
              alt={show.title}
              className={`poster-img ${
                posterLoadingStates[show._id] === false ? 'visible' : 'hidden'
              }`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0
              }}
              onLoad={() => handlePosterLoad(show._id)}
              onError={() => handlePosterLoad(show._id)}
            />
          </div>
        </Link>
      </div>
    ))}
  </Col>
</Row>

      {hasMore && filteredShows.length > visibleEntriesCount && (
        <Row className="g-0 mb-5 justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
            <Button onClick={loadMoreEntries}>{t('buttons.show_more')}</Button>
          </Col>
        </Row>
      )}
    </Container>



        <Container fluid className="px-3 main-container display-none">
      <Row className="title-row mb-5 justify-content-center no-margin-row">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center title-col">
          <div className="page-title-container">
            <img src={bigRankAllIcon} alt="Theater Shows" className="title-icon" />
            <h1 className="page-title">{t('theater.search_title')}</h1>
          </div>
        </Col>
      </Row>

      <Row className="g-0 mb-4 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
          <Form className="search-form">
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder={t('theater.enter_theater')}
                value={theaterInput}
                onChange={(e) => setTheaterInput(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder={t('theater.enter_location')}
                value={locationInput}
                onChange={(e) => setLocationInput(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      {(theaterInput || locationInput) && filteredShows.length > 0 && (
        <Row className="g-0 mb-4 justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
            <h2 className="common-info">
              {commonTheater && `${t('theater.common_theater')}: ${commonTheater}`}
              {commonTheater && commonLocation && ' | '}
              {commonLocation && `${t('theater.common_location')}: ${commonLocation}`}
            </h2>
          </Col>
        </Row>
      )}

      {/* Tutaj wstawić kod wyświetlania spektakli - identyczny jak w BestAllTime */}
      <Row className="g-0 best-played mb-5 justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="best-played-col resp-pre-xl">
          {filteredShows.slice(0, visibleEntriesCount).map(show => (
                <div key={show._id} className="show-card" onClick={() => handleNavigation(`/shows/${show.slug}`)}>
                <div className="show-poster">
                  <Link to={`/shows/${show.slug}`} className="show-link">
                    <div className="poster-container">
                      <div className={`skeleton-loader ${posterLoadingStates[show._id] !== false ? 'visible' : ''}`} />
                      <img 
                        key={show._id}
                        src={show.poster && show.poster.includes('http') 
                          ? show.poster 
                          : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster || ''}`}
                        alt={show.title}
                        className={`poster-img ${posterLoadingStates[show._id] === false ? 'visible' : 'hidden'}`}
                        onLoad={() => handlePosterLoad(show._id)}
                        onError={() => handlePosterLoad(show._id)}
                      />
                    </div>
                  </Link>
                  {user && getUserRatingForShow(show._id) ? (
<div className="user-rating-badge">
  <span>{getUserRatingForShow(show._id)}</span>
</div>
) : (
<div className="user-rating-badge-star">
  <img src={starIcon} alt="Rating" className="rating-star-badge" />
</div>
)}
                </div>

                <div className="show-info">
                  <div className="ranking">
                    <img src={rankIcon} alt="Rating" className="rank-icon" />
                    <span>{show.dynamicRank}.</span>
                  </div>
                  <div className="show-info-basic">
                    <Link to={`/shows/${show.slug}`} className="show-link">
                      <h5 className="show-title">{show.title}</h5>
                    </Link>
                    <div className="theater-info">{show.theater}</div>
                    <div className="location">{show.location}</div>
                  </div>
                  <div className="rating">
                    <img src={starIcon} alt="Rating" className="rating-star" />
                    <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
                  </div>
                  {(show.showCategory || show.showType || show.showForKids || show.showOrigin) && (
  <div className="tags">
    <ShowTags show={show} t={t} />
  </div>
)}
                </div>
              </div>
          ))}
        </Col>
      </Row>

      {hasMore && filteredShows.length > visibleEntriesCount && (
        <Row className="g-0 mb-5 justify-content-center">
          <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center">
            <Button onClick={loadMoreEntries}>{t('buttons.show_more')}</Button>
          </Col>
        </Row>
      )}
    </Container>
    </>
  );
};

export default TheaterShows; 