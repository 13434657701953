import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormControl, InputGroup, Container, Row, Col, Button, Modal } from 'react-bootstrap';
import AddShowModal from './AddShowModal';
import AddCustomShowModal from './AddCustomShowModal';



const AddToDiary = ({ t, currentLocale, noteIcon, getRatingDescription, defaultPoster, successIcon, starIcon, searchIcon, plusIcon, colorCircleCalendarIcon, colorCircleStarIcon }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [popularShows, setPopularShows] = useState([]);
  const [visibleShows, setVisibleShows] = useState(5); // Number of shows initially visible
  const [selectedShow, setSelectedShow] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customModalIsOpen, setCustomModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Stan dla loadera
const [isSuccess, setIsSuccess] = useState(false); // Stan dla statusu sukcesu
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [showUnverified, setShowUnverified] = useState(false);
  const [showUnverifiedModal, setShowUnverifiedModal] = useState(false);

  // Dodaj nowe stany dla różnych typów dopasowań
  const [exactMatches, setExactMatches] = useState([]);
  const [startMatches, setStartMatches] = useState([]);
  const [wordMatches, setWordMatches] = useState([]);
  const [partialMatches, setPartialMatches] = useState([]);

  // Dodaj refy dla kontroli zapytań
  const abortControllerRef = useRef(null);
  const timeoutRef = useRef(null);

  const [unverifiedResults, setUnverifiedResults] = useState([]);

  useEffect(() => {
    const fetchPopularShows = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/popular-shows`);
        setPopularShows(response.data);
      } catch (error) {
        console.error('Error fetching popular shows:', error);
      }
    };

    fetchPopularShows();
  }, []);

  const handleLoadMore = () => {
    setVisibleShows((prevVisibleShows) => prevVisibleShows + 5);
  };

  useEffect(() => {
    const handleSearch = async () => {
      if (searchTerm.length >= 1) {
        try {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          abortControllerRef.current = new AbortController();
          setIsLoading(true);

          await new Promise((resolve) => {
            timeoutRef.current = setTimeout(resolve, 300);
          });

          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/shows`,
            {
              params: { title: searchTerm.trim() },
              signal: abortControllerRef.current.signal
            }
          );

          const exact = [];
          const start = [];
          const word = [];
          const partial = [];
          
          response.data.forEach(show => {
            switch(show.score) {
              case 4:
                exact.push(show);
                break;
              case 3:
                start.push(show);
                break;
              case 2:
                word.push(show);
                break;
              case 1:
                partial.push(show);
                break;
              default:
                break;
            }
          });

          const sortByRating = (a, b) => b.avgRating - a.avgRating;
          
          setExactMatches(exact.sort(sortByRating));
          setStartMatches(start.sort(sortByRating));
          setWordMatches(word.sort(sortByRating));
          setPartialMatches(partial.sort(sortByRating));
          
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error('Error searching shows:', error);
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setExactMatches([]);
        setStartMatches([]);
        setWordMatches([]);
        setPartialMatches([]);
        setIsLoading(false);
      }
    };

    handleSearch();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [searchTerm]);

  // Dodaj zmienną allSearchResults
  const allSearchResults = [...exactMatches, ...startMatches, ...wordMatches, ...partialMatches];

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const slug = query.get('slug'); // Pobierz parametr slug z URL
    console.log('Slug from URL:', slug); // Log slug for debugging
  
    if (slug) {
      const fetchShowBySlug = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows/${slug}`);
          setSelectedShow(response.data);
          setModalIsOpen(true); // Otwórz modal po załadowaniu danych
          console.log('Show fetched by slug:', response.data);
        } catch (error) {
          console.error('Error fetching show by slug:', error);
        }
      };
  
      fetchShowBySlug();
    }
  }, [location.search]);
  

  const handleAddToDiary = async (dateWatched, userRating, userNote) => {
    setIsLoading(true);  // Włącz loader
    setIsSuccess(false); // Zresetuj status sukcesu
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/diary`, {
        showId: selectedShow._id,
        dateWatched: dateWatched ? dateWatched.toISOString() : null, // Zapis daty w formacie ISO
        userRating,
        userNote
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.status === 201) {
        setConfirmationMessage('Your show has been saved!');
        setIsSuccess(true); // Ustawienie flagi sukcesu
      }
    } catch (error) {
      console.error('Error adding show to diary:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);  // Wyłączenie loadera po zakończeniu operacji
    }
  };

  const handleOpenModal = (show) => {
    setSelectedShow(show);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedShow(null);
  };

  const capitalizeWords = (str) => {
    return str.replace(/(^|\s)[^\s]/g, (match) => match.toUpperCase());
  };



  const handleOpenCustomModal = () => {
    setCustomModalIsOpen(true);
  };

  const handleCloseCustomModal = () => {
    setCustomModalIsOpen(false);
  };

  const handleShowUnverified = () => {
    setShowUnverifiedModal(true);
  };

  const handleConfirmUnverified = () => {
    console.log('Confirming unverified search, current search term:', searchTerm);
    setShowUnverified(true);
    setShowUnverifiedModal(false);
  };

  const renderShowItem = (show, isUnverified = false) => (
    <div 
      key={show._id} 
      className={`search-result-item ${isUnverified ? 'unverified-show' : ''}`}
      onClick={() => handleOpenModal(show)} 
      style={{ cursor: 'pointer' }} 
    >
      <div className="show-poster">
        <img
          src={show.poster.includes('http') ? show.poster : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
          alt={show.title}
          className="poster-img"
        />
      </div>
      <div className="show-info">
      {isUnverified ? <div className="label-unverified">{t('add_to_diary.unverified-show')}</div> : ''}
        <h5 className="show-title">{show.title}</h5>
        {show.theater && <div className="theater-info">{show.theater}</div>}
        {show.location && <div className="location-info">{show.location}</div>}
        <div className="rating">
          {show.avgRating > 0 && (
            <>
              <img src={starIcon} alt="Rating" className="rating-star" />
              <span>{typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}</span>
            </>
          )}
        </div>
      </div>
      <img src={plusIcon} alt="Add to diary" className="search-results-icon" />
    </div>
  );

  useEffect(() => {
    console.log('showUnverified state changed to:', showUnverified);
  }, [showUnverified]);

  useEffect(() => {
    if (searchTerm.length >= 1 && showUnverified) {
      const fetchUnverifiedResults = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shows/unverified`, {
            params: { title: searchTerm },
          });
          setUnverifiedResults(response.data);
        } catch (error) {
          console.error('Error searching unverified shows:', error);
        }
      };

      fetchUnverifiedResults();
    }
  }, [searchTerm, showUnverified]);

  return (
    <Container fluid className="add-to-diary px-3 mb-5 ">

                  <Row className="title-row mb-5 justify-content-center no-margin-row">
              <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center title-col">
                <div className="page-title-container">
                  <img src={plusIcon} alt="Explore" className="title-icon" />
                  <h1 className="page-title">{t('add_to_diary.title')}</h1>
                </div>
              </Col>
            </Row>

      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">
          <div>
           

            {/* Sekcja wyszukiwania */}
            <div className="search-section">
              <InputGroup className="search-input-group">
                <FormControl
                  placeholder={t('search.placeholder')} 
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoFocus
                  style={{ paddingLeft: '40px' }}
                />
                <img src={searchIcon} alt="Search" className="search-icon" />
              </InputGroup>
            </div>

            {searchTerm.length > 0 && (
              <>
                <div className="search-modal-results">
                  <h3 className="section-subtitle">{t('add_to_diary.search-results')}:</h3>
                  {allSearchResults.map(show => renderShowItem(show))}





                  {/* Specjalny element dodawany na końcu */}
                  <div 
                    className="search-result-item special-result-item"
                    onClick={handleOpenCustomModal}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="show-poster">
                      <img
                        src={defaultPoster}
                        alt="Default poster"
                        className="poster-img"
                      />
                    </div>
                    <div className="show-info">
                      <p className="theater-info">{t('add_to_diary.cant-find')}</p>
                      <p className="theater-info-add">{t('add_to_diary.add-yourself')}:</p>
                      <h5 className="show-title">{capitalizeWords(searchTerm)}</h5>
                    </div>
                    <img src={plusIcon} alt="Add new show" className="search-results-icon" />
                  </div>


                  {!showUnverified && searchTerm.length > 0 && (
                    <div 
                      className="search-unverified-link mt-3"
                      onClick={handleShowUnverified}
                    >
                      {t('add_to_diary.search_unverified')}
                    </div>
                  )}


                  {showUnverified && unverifiedResults.length > 0 && (
                    <div className="unverified-section mt-3">
                      <h3 className="section-subtitle">
                        {t('add_to_diary.unverified_results')}:
                      </h3>
                      {unverifiedResults.map(show => renderShowItem(show, true))}
                    </div>
                  )}

                </div>
              </>
            )}

            {/* Renderowanie popularnych spektakli, jeśli searchTerm jest pusty */}
            {searchTerm.length === 0 && (
              <>
                <div className="popular-shows">
                  <h3 className="section-subtitle">{t('add_to_diary.popular')}:</h3>
                  <div>
                    {popularShows.slice(0, visibleShows).map((show) => (
                      <div 
                        key={show._id} 
                        className="search-result-item" 
                        onClick={() => handleOpenModal(show)} 
                        style={{ cursor: 'pointer' }} 
                      >
                        <div className="show-poster">
                          <img
                            src={show.poster.includes('http')  ? show.poster  : `${process.env.REACT_APP_API_BASE_URL}/img${show.poster}`}
                            alt={show.title}
                            className="poster-img"
                          />
                        </div>
                        <div className="show-info">
                          <h5 className="show-title">{show.title}</h5>
                          <div className="theater-info">{show.theater}</div>
                          <div className="rating">
                            <img src={starIcon} alt="Rating" className="rating-star" />
                            <span>  {typeof show.avgRating === 'number' ? show.avgRating.toFixed(1) : 'N/A'}
                            </span>
                          </div>
                        </div>
                        <img src={plusIcon} alt="Add to diary" className="search-results-icon" />
                      </div>
                    ))}
                  </div>
                  {visibleShows < popularShows.length && (
                    <Button onClick={handleLoadMore} className="load-more-btn">
                      {t('add_to_diary.get-more')}
                    </Button>
                  )}
                </div>
              </>
            )}

            <AddShowModal 
              show={selectedShow} 
              onClose={handleCloseModal} 
              colorCircleCalendarIcon={colorCircleCalendarIcon} 
              colorCircleStarIcon={colorCircleStarIcon} 
              starIcon={starIcon} 
              getRatingDescription={getRatingDescription} 
              handleAddToDiary={handleAddToDiary}
              modalIsOpen={modalIsOpen}
              navigate={navigate}
              confirmationMsg={confirmationMessage}
              setConfirmationMsg={setConfirmationMessage}
              successIcon={successIcon}
              currentLocale={currentLocale}
              t={t}
              isLoading={isLoading}
                setIsLoading={setIsLoading}
              isSuccess={isSuccess}
              setIsSuccess={setIsSuccess}
              noteIcon={noteIcon}
            />

            

<AddCustomShowModal
        isOpen={customModalIsOpen}
        onClose={handleCloseCustomModal}
        defaultPoster={defaultPoster}
        initialTitle={capitalizeWords(searchTerm)} // Przekazanie tytułu do modala
        colorCircleCalendarIcon={colorCircleCalendarIcon} 
        colorCircleStarIcon={colorCircleStarIcon} 
        starIcon={starIcon} 
        getRatingDescription={getRatingDescription}
        successIcon={successIcon}
        currentLocale={currentLocale}
        t={t}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      isSuccess={isSuccess}
      setIsSuccess={setIsSuccess}
      confirmationMsg={confirmationMessage}
      setConfirmationMsg={setConfirmationMessage}
      />

          </div>
        </Col>
      </Row>

      {/* Modal ostrzeżenia */}
      <Modal
        show={showUnverifiedModal}
        onHide={() => setShowUnverifiedModal(false)}
        centered
      >
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
        <h1 className="mb-4 mt-4">{t('add_to_diary.unverified_warning_title')}</h1>
          <p className="mb-4">{t('add_to_diary.unverified_warning_body')}</p>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleConfirmUnverified}>
            {t('common.continue')}
          </Button>
          <Button variant="secondary" onClick={() => setShowUnverifiedModal(false)}>
            {t('common.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AddToDiary;
