import React from 'react';

const ShowTags = ({ show, t }) => {
  const getTagClassName = (category) => {
    const baseClass = 'tag';
    switch (category) {
      case 'play':
        return `${baseClass} tag-play`;
      case 'musical':
        return `${baseClass} tag-musical`;
      case 'opera':
        return `${baseClass} tag-opera`;
      case 'concert':
        return `${baseClass} tag-concert`;
      default:
        return `${baseClass} tag-other`;
    }
  };

  // Zabezpiecz dostęp do wszystkich właściwości show
  if (!show) {
    return null;
  }

  // Zabezpiecz i filtruj wszystkie tablice
  const validCategories = Array.isArray(show.showCategory) 
    ? show.showCategory.filter(category => category && category !== 'undefined')
    : show.showCategory ? [show.showCategory] : [];

  const processTypes = (types) => {
    if (Array.isArray(types)) {
      // Spłaszcz i podziel wszystkie elementy tablicy, które mogą zawierać przecinki
      return types
        .flatMap(type => 
          typeof type === 'string' && type.includes(',') 
            ? type.split(',') 
            : type
        )
        .map(type => type.trim())
        .filter(type => type && type !== 'undefined');
    }
    if (typeof types === 'string') {
      return types.split(',')
        .map(type => type.trim())
        .filter(type => type && type !== 'undefined');
    }
    return [];
  };


  const validTypes = processTypes(show.showType);

  const validOrigins = Array.isArray(show.showOrigin)
    ? show.showOrigin.filter(origin => origin && origin !== 'undefined')
    : show.showOrigin ? [show.showOrigin] : [];

  // Użyj pierwszej prawidłowej kategorii do baseTagClass
  const mainCategory = validCategories[0];
  const baseTagClass = getTagClassName(mainCategory);

  return (
    <>
      {/* Kategorie */}
      {validCategories.length > 0 && validCategories.map((category, index) => (
        <span 
          key={`category-${index}`} 
          className={getTagClassName(category)}
        >
          {t(`show_attributes.categories.${category}`)}
        </span>
      ))}

      {/* Typy */}
      {validTypes.length > 0 && validTypes.map((type, index) => (
        <span 
          key={`type-${index}`} 
          className={`${baseTagClass} tag-secondary`}
        >
          {t(`show_attributes.types.${type}`)}
        </span>
      ))}

      {/* Dla dzieci - tylko gdy jest true */}
      {show.showForKids === true && (
        <span className={`${baseTagClass} tag-tertiary`}>
          {t('show_attributes.for_kids.yes')}
        </span>
      )}

      {/* Pochodzenie */}
      {validOrigins.length > 0 && validOrigins.map((origin, index) => (
        <span 
          key={`origin-${index}`} 
          className={`${baseTagClass} tag-quaternary`}
        >
          {t(`show_attributes.origins.${origin}`)}
        </span>
      ))}
    </>
  );
};

export default ShowTags; 