import React, { useState, useEffect } from 'react';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import colorCircleNoteIcon from './img/icons/color-circle-note.svg';



function ShowModal({ 
    show, onClose, colorCircleCalendarIcon, colorCircleStarIcon,
    getRatingDescription, successIcon, t, currentLocale,  selectedEntry
}) {

  const [userRating, setUserRating] = useState(0);
  const [tempRating, setTempRating] = useState(0); // Tymczasowa ocena podczas edycji
  const [hoverRating, setHoverRating] = useState(null); // Rating przy najechaniu myszą
  const [ratingDescription, setRatingDescription] = useState(''); // Opis oceny
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isRemoved, setIsRemoved] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false); 
  const [dateWatched, setDateWatched] = useState(new Date());
  const [pendingDateWatched, setPendingDateWatched] = useState(new Date());
  const [manualYear, setManualYear] = useState(new Date().getFullYear()); // Rok wprowadzony ręcznie
  const [isLoading, setIsLoading] = useState(false); // Stan dla loadera
const [isSuccess, setIsSuccess] = useState(false); // Stan dla statusu sukcesu
const [userNote, setUserNote] = useState('');
const [tempNote, setTempNote] = useState('');
const [showNoteInput, setShowNoteInput] = useState(false);
const [hasChanges, setHasChanges] = useState(false);
  


useEffect(() => {
  if (selectedEntry) { // Ensure selectedEntry is defined
    setUserRating(selectedEntry.userRating || 0);
    setTempRating(selectedEntry.userRating || 0);
    setUserNote(selectedEntry.userNote || '');
    setTempNote(selectedEntry.userNote || '');

      setRatingDescription(getRatingDescription(selectedEntry.userRating || 0));

      // Ustaw aktualną datę obejrzenia spektaklu
      const watchedDate = selectedEntry?.dateWatched ? new Date(selectedEntry.dateWatched) : null;
      setDateWatched(watchedDate);
      setPendingDateWatched(watchedDate);
      setManualYear(watchedDate ? watchedDate.getFullYear() : new Date().getFullYear()); // Ustawienie roku wprowadzanego ręcznie na podstawie aktualnej daty

    }
  }, [selectedEntry, getRatingDescription]);



// W funkcji handleRatingChange
const handleRatingChange = (newRating) => {
  setTempRating(newRating); // Zapisz tymczasową ocenę
  setRatingDescription(getRatingDescription(newRating)); // Aktualizuj opis oceny
};



  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(null);
  };

// W funkcji saveRating
const saveRatingAndDate = async () => {
  setIsLoading(true);
  setIsSuccess(false);
  try {
    await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/diary/update`, {
      diaryEntryId: show._id,
      rating: tempRating || 0,
      dateWatched: dateWatched ? dateWatched.toISOString() : null,
      userNote: tempNote
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    setUserRating(tempRating);
    setUserNote(tempNote);
    setConfirmationMessage(t('modals.show_modal.rating_date_successful'));
    setIsSuccess(true);
    setHasChanges(true);
  } catch (error) {
    console.error('Error saving rating and date:', error.response ? error.response.data : error.message);
  } finally {
    setIsLoading(false);
  }
};
  

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handleRatingChange(i)}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={handleMouseLeave}
          className={'star-icon'}
          style={{ color: i <= (hoverRating ?? tempRating) ? '#FFC319' : '#ccc', cursor: 'pointer' }}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  const handleRemoveFromDiary = async (show, dateWatched) => {
    setIsLoading(true); // Włącz loader
    setIsSuccess(false); // Zresetuj status sukcesu
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/diary/remove`, {
        showId: show.show._id,
        dateWatched: show.dateWatched,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      setConfirmationMessage(t('modals.show_modal.remove_successful'));
      setIsRemoved(true);
      setHasChanges(true);
    } catch (error) {
      console.error('Error removing show entry from diary:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false); // Wyłącz loader po zakończeniu operacji
    }
  };
  
  const handleOpenDateModal = () => {
    setIsDateModalOpen(true);
  };

  const handleCloseDateModal = () => {
    setIsDateModalOpen(false);
  };

  const handleDateChange = (date) => {
    setPendingDateWatched(date);
    setManualYear(date.getFullYear());
  };

  const handleMonthChange = (date) => {
    setManualYear(date.getFullYear());
  };

  const handleYearChange = (e) => {
    const year = parseInt(e.target.value, 10);
    if (!isNaN(year)) {
      setManualYear(year);
      const updatedDate = new Date(pendingDateWatched);
      updatedDate.setFullYear(year); // Zmieniamy rok w aktualnie wybranej dacie
      setPendingDateWatched(updatedDate); // Aktualizujemy datę w kalendarzu
    }
  };

  const handleSaveDateChange = () => {
    setDateWatched(pendingDateWatched);
    setIsDateModalOpen(false);
  };

const handleSaveAndClose = async () => {
  await saveRatingAndDate();
};

  const handleCloseAndReload = () => {
    onClose();
    window.location.reload();
  };

  useEffect(() => {
    if (!isDateModalOpen) {
      setConfirmationMessage('');
    }
  }, [isDateModalOpen]);

  const handleModalClose = () => {
    if (hasChanges) {
      onClose();
      window.location.reload();
    } else {
      setTempRating(userRating);
      setHoverRating(null);
      setShowNoteInput(false);
      setPendingDateWatched(null);
      setTempNote(userNote);
      onClose();
    }
  };

  if (!show) return null;

  return (
    <>
      <Modal 
        show={!!show} 
        onHide={handleModalClose} 
        centered 
        backdrop="true"
        onBackdropClick={handleModalClose}
      >
  <Modal.Header closeButton className="modal-header"></Modal.Header>
  <Modal.Body>
    {isLoading ? (
      <div className="loader-container"><div className="loader"></div></div> 
    ) : isSuccess ? (
      <div className="confirmation-message-modal-rem">
        <div className="confirmation-message">
          <img src={successIcon} className="success-icon" alt="Success" />
          <p>{confirmationMessage}</p>
        </div>
        <Button variant="primary" onClick={handleCloseAndReload}>
          {t('modals.show_modal_regular.close_button')}
        </Button>
      </div>
    ) : isRemoved ? (
      <div className="confirmation-message-modal-rem">
        <div className="confirmation-message">
          <img src={successIcon} className="success-icon" alt="Success" />
          <p>{confirmationMessage}</p>
        </div>
        <Button variant="primary" onClick={handleCloseAndReload}>
          {t('modals.show_modal_regular.close_button')}
        </Button>
      </div>
    ) : (
<div className="modal-section">
  <div className="show-card">
    <div className="show-poster">
      {show.show.isVerified ? (
        <Link to={`/shows/${show.show.slug}`} className="show-link">
          <img 
            src={
              show.show.poster.includes('http')
                ? show.show.poster
                : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`
            } 
            alt={show.show.title} 
            className="modal-poster" 
          />
        </Link>
      ) : (
        <img 
          src={
            show.show.poster.includes('http')
              ? show.show.poster
              : `${process.env.REACT_APP_API_BASE_URL}/img${show.show.poster}`
          } 
          alt={show.show.title} 
          className="modal-poster" 
        />
      )}
                </div>
              </div>
              <Link to={`/shows/${show.show.slug}`} className="show-link">
                <h5 className="show-title">{show.show.title}</h5>
              </Link>
              {(show.show.theater || show.show.location) && (
                <h6 className="theater-info">
                  {show.show.theater && show.show.theater}
                  {show.show.theater && show.show.location && ", "}
                  {show.show.location && show.show.location}
                </h6>
              )}
              
              <div className="modal-subsection">
                <div className="modal-small-section">
                  <div className="modal-small-section-top">
                    <div className="icon-section">
                      <img src={colorCircleCalendarIcon} alt="Calendar Icon" />
                    </div>
                    <div className="content">
                      <h6><strong>{t('modals.show_modal.date_of_show')}</strong></h6>
                      <p className="sub">{dateWatched ? dateWatched.toLocaleDateString() : t('common.no_date')}</p>
                    </div>
                  </div>
                  <div className="modal-small-section-bottom">
                  <Button 
                  variant="light" 
                  onClick={handleOpenDateModal}
                  className="btn-small"
                >
                  {dateWatched ? t('modals.show_modal.change_date') : t('modals.show_modal.add_date')}
                </Button>                  </div>
                </div>
                <div className="modal-small-section">
                  <div className="modal-small-section-top">
                    <div className="icon-section">
                      <img src={colorCircleStarIcon} alt="Star Icon" />
                    </div>
                    <div className="content">
                      <strong>{t('modals.show_modal.rating_label')} </strong>
                      <p className="rating-summary-p">
                    {t('modals.add_show_modal.your_rating')}:&nbsp;
                    <strong>{tempRating ? `${ratingDescription} (${tempRating})` : t('modals.add_show_modal.no_rating')}</strong>
                  </p>
                      </div>
                  </div>
                  <div className="modal-small-section-bottom">
                    <div className="star-rating">
                    {renderStarRating(tempRating)}
                    </div>
                  </div>
                </div>
                {(!selectedEntry?.userNote || selectedEntry.userNote === '') && !showNoteInput && (
                  <Button variant="light" className="btn-additional" onClick={() => setShowNoteInput(true)}>
                    {t('modals.show_modal_regular.add-note')}
                  </Button>
                )}

                {(showNoteInput || (selectedEntry?.userNote && selectedEntry.userNote !== '')) && (
                  <>
                    <div className="modal-small-section">
                      <div className="modal-small-section-top">
                        <div className="icon-section">
                          <img src={colorCircleNoteIcon} alt="Note Icon" />
                        </div>
                        <div className="content">
                          <h6><strong>{t('modals.show_modal_regular.note-title')}</strong></h6>
                          <p className="sub">{t('modals.show_modal_regular.note-subtitle')}</p>
                        </div>
                      </div>
                      <div className="modal-small-section-bottom modal-small-section-note note-section">
                        <textarea
                          className="note-textarea"
                          value={tempNote}
                          onChange={(e) => setTempNote(e.target.value)}
                          placeholder={t('modals.show_modal_regular.note-placeholder')} 
                          rows={3}
                        />
                      </div>
                    </div>
                  </>
                )}

              </div>
              </div>
    )}
  </Modal.Body>
  {!isRemoved && !isLoading && !isSuccess && (
    <Modal.Footer className="modal-footer">
      <Button variant="primary" onClick={handleSaveAndClose}>
        {t('modals.show_modal.save_button')}
      </Button>
      <Button variant="light" onClick={handleModalClose}>
        {t('modals.show_modal.cancel_button')}
      </Button>
      <Button variant="danger" onClick={() => handleRemoveFromDiary(show, show.dateWatched)}>
        {t('modals.show_modal.remove_button')}
      </Button>
    </Modal.Footer>
  )}
</Modal>

            {/* Nowy modal z kalendarzem */}
            <Modal show={isDateModalOpen} onHide={handleCloseDateModal} className="modal-calendar-container" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="custom-datepicker">
          <div className="modal-calendar">
          <InputGroup className="mb-3">
              <InputGroup.Text>{t('modals.show_modal.year_label')}</InputGroup.Text>
              <FormControl
                type="number"
                value={manualYear}
                onChange={handleYearChange}
                aria-label="Year"
              />
            </InputGroup>
          <DatePicker 
                        selected={pendingDateWatched}
                        onChange={handleDateChange}
                        onMonthChange={handleMonthChange}
                        inline
                        calendarStartDay={1} // Ustawienie pierwszego dnia tygodnia na poniedziałek
                        locale={currentLocale} // Ustawiamy lokalizację kalendarza

                    />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveDateChange}>{t('modals.show_modal.save_button')}</Button>
          <Button variant="light" onClick={handleCloseDateModal}>{t('modals.show_modal.cancel_button')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShowModal;
