import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import colorCircleNoteIcon from './img/icons/color-circle-note.svg';
import infoIcon from './img/icons/info.svg';


const AddCustomShowModal = ({ isOpen, t, isLoading, isSuccess, setIsLoading, setIsSuccess, confirmationMsg, setConfirmationMsg, currentLocale, onClose, defaultPoster, initialTitle, colorCircleCalendarIcon, colorCircleStarIcon, getRatingDescription, successIcon }) => {

  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [poster, setPoster] = useState(null);
  const [posterPreview, setPosterPreview] = useState(defaultPoster);
  const [dateWatched, setDateWatched] = useState(null);
  const [pendingDateWatched, setPendingDateWatched] = useState(null);
  const [tempRating, setTempRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(null);
  const [ratingDescription, setRatingDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [manualYear, setManualYear] = useState(new Date().getFullYear()); // Rok wprowadzony ręcznie
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [theater, setTheater] = useState('');
  const [location, setLocation] = useState('');
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [userNote, setUserNote] = useState('');



  useEffect(() => {
    if (isOpen) {
      setTitle(initialTitle || '');
      setPosterPreview(defaultPoster);
      setTempRating(0);
      setRatingDescription(getRatingDescription(0));
    }
  }, [isOpen, initialTitle, defaultPoster, getRatingDescription]);

  const handlePosterChange = (e) => {
    const file = e.target.files[0];
    setPoster(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPosterPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPosterPreview(defaultPoster);
    }
  };

  const handlePosterClick = () => {
    document.getElementById('poster-input').click();
  };

  const handleRatingChange = (newRating) => {
    setTempRating(newRating);
    setRatingDescription(getRatingDescription(newRating));
  };

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(null);
  };

  const handleOpenDateModal = () => {
    setIsDateModalOpen(true);
  };

  const handleCloseDateModal = () => {
    setIsDateModalOpen(false);
  };

  const handleDateChange = (date) => {
    setPendingDateWatched(date);
    setManualYear(date.getFullYear()); // Aktualizacja pola z rokiem przy wyborze daty z kalendarza

  };

  const handleMonthChange = (date) => {
    setManualYear(date.getFullYear());
  };

  const handleYearChange = (e) => {
    const year = parseInt(e.target.value, 10);
    if (!isNaN(year)) {
      setManualYear(year);
      const updatedDate = new Date(pendingDateWatched);
      updatedDate.setFullYear(year); // Zmieniamy rok w aktualnie wybranej dacie
      setPendingDateWatched(updatedDate); // Aktualizujemy datę w kalendarzu
    }
  };

  const handleSaveDateChange = () => {
    setDateWatched(pendingDateWatched);
    setIsDateModalOpen(false);
  };

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handleRatingChange(i)}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={handleMouseLeave}
          className={'star-icon'}
          style={{ color: i <= (hoverRating ?? tempRating) ? '#FFC319' : '#ccc', cursor: 'pointer' }}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);  // Natychmiastowe włączenie loadera
    setIsSuccess(false); // Resetowanie sukcesu, aby ukryć wcześniejsze komunikaty
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('title', title);
    
    // Dodaj datę tylko jeśli została wybrana
    if (dateWatched) {
      formData.append('dateWatched', dateWatched.toISOString());
    } else {
      formData.append('dateWatched', null);
    }

    if (tempRating > 0) {
      formData.append('userRating', tempRating);
    }
    if (theater) formData.append('theater', theater);
    if (location) formData.append('location', location);
    if (userNote) formData.append('userNote', userNote);

    // Ustaw domyślny plakat, jeśli użytkownik nie wybrał nowego
    const posterToSave = poster ? poster : defaultPoster;
    formData.append('poster', posterToSave);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/shows/add`, formData, {
        withCredentials: true, // Jeśli używasz ciasteczek
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 201) {
        setConfirmationMsg(t('modals.add_show_modal.confirmation_message'));
        setIsSuccess(true);   // Ustawienie statusu sukcesu
      } else {
        setError(t('modals.add_custom_show_modal.error_message'));
      }
    } catch (error) {
      if (error.response) {
        setError(`${t('modals.add_custom_show_modal.server_error_message')}${error.response.data.message || t('modals.add_custom_show_modal.generic_error_message')}`);
      } else if (error.request) {
        setError(t('modals.add_custom_show_modal.no_response_error_message'));
      } else {
        setError(`${t('modals.add_custom_show_modal.generic_error_message')}${error.message}`);
      }
    } finally {
      setLoading(false);
      setIsLoading(false);  // Wyłączenie loadera po zapisaniu
    }
  };

  

  return (
    <>
      <Modal show={isOpen} onHide={onClose} className="add-custom-show-modal" centered backdrop="true">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
              ) : isSuccess ? (
                <div className="confirmation-message-modal-rem">
                <div className="confirmation-message">
                  <img src={successIcon} className="success-icon" alt="Success" />
                  <p>{confirmationMsg}</p>
        
                  <Button variant="primary" onClick={() => navigate('/diary')}>{t('modals.add_show_modal.close_button')}</Button>
        
                </div>
                </div>
      ) : (
        <>
          <Form.Group controlId="poster">
            {posterPreview && (
              <div className="modal-section">
                <div className="show-card">
                  <div className="show-poster">
                    <img
                      src={posterPreview}
                      alt={t('modals.add_custom_show_modal.poster_alt')}
                      className="modal-poster"
                      onClick={handlePosterClick}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </div>
            )}

            <Form.Label style={{ display: 'none' }}>{t('modals.add_custom_show_modal.poster_alt')}</Form.Label>
            <Form.Control
              type="file"
              id="poster-input"
              onChange={handlePosterChange}
              accept="image/*"
              style={{ display: 'none' }}
            />
          </Form.Group>
          <h3 className="mb-3 mt-3 text-center">{t('modals.add_custom_show_modal.title')}</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label>{t('modals.add_custom_show_modal.title-input')}</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>

            <Button 
              variant="light" 
              className="btn-additional mt-2 mb-2"
              onClick={() => setShowAdditionalFields(!showAdditionalFields)}
            >
              {showAdditionalFields 
                ? t('modals.add_custom_show_modal.hide_additional_info')
                : t('modals.add_custom_show_modal.show_additional_info')}
            </Button>

            {showAdditionalFields && (
              <div className="additional-fields">
                <Form.Group controlId="theater" className="mb-3">
                  <Form.Label>{t('modals.add_custom_show_modal.theater')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={theater}
                    onChange={(e) => setTheater(e.target.value)}
                    placeholder={t('modals.add_custom_show_modal.theater_placeholder')}
                  />
                </Form.Group>

                <Form.Group controlId="location" className="mb-3">
                  <Form.Label>{t('modals.add_custom_show_modal.location')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder={t('modals.add_custom_show_modal.location_placeholder')}
                  />
                </Form.Group>
              </div>
            )}

            <h3 className="mt-4 pt-2 mb-4 text-center">{t('modals.add_custom_show_modal.diary_entry')}</h3>
            <div className="modal-subsection">
              <div className="modal-small-section">
                <div className="modal-small-section-top">
                  <div className="icon-section">
                    <img src={colorCircleCalendarIcon} alt="Calendar Icon" />
                  </div>
                  <div className="content">
                    <h6><strong>{t('modals.add_custom_show_modal.date_of_show')}</strong></h6>
                    <p className="sub">{dateWatched ? dateWatched.toLocaleDateString() : t('common.no_date')}</p>
                  </div>
                </div>
                <div className="modal-small-section-bottom">
                  <Button variant="light" className="change-date-btn" onClick={handleOpenDateModal}>
                    {dateWatched ? t('modals.show_modal.change_date') : t('modals.show_modal.add_date')}
                  </Button>
                </div>
              </div>
              <div className="modal-small-section">
                <div className="modal-small-section-top">
                  <div className="icon-section">
                    <img src={colorCircleStarIcon} alt="Star Icon" />
                  </div>
                  <div className="content">
                    <strong>{t('modals.add_custom_show_modal.rating_label')}: </strong>
                    <p className="rating-summary-p">{t('modals.add_custom_show_modal.your_rating')}:                     <strong>{tempRating ? `${ratingDescription} (${tempRating})` : t('modals.add_show_modal.no_rating')}</strong>
                    </p>
                  </div>
                </div>
                <div className="modal-small-section-bottom">
                  <div className="star-rating">
                    {renderStarRating(tempRating)}
                  </div>
                </div>
              </div>

              {!showNoteInput && (
              <Button 
                variant="light" 
                className="btn-additional" 
                onClick={() => setShowNoteInput(true)}
              >
                {t('modals.show_modal_regular.add-note')}
              </Button>
            )}

            {showNoteInput && (
              <div className="modal-small-section">
                <div className="modal-small-section-top">
                  <div className="icon-section">
                    <img src={colorCircleNoteIcon} alt="Note Icon" />
                  </div>
                  <div className="content">
                    <h6><strong>{t('modals.show_modal_regular.note-title')}</strong></h6>
                    <p className="sub">{t('modals.show_modal_regular.note-subtitle')}</p>
                  </div>
                </div>
                <div className="modal-small-section-bottom modal-small-section-note note-section">
                  <textarea
                    className="note-textarea"
                    value={userNote}
                    onChange={(e) => setUserNote(e.target.value)}
                    placeholder={t('modals.show_modal_regular.note-placeholder')} 
                    rows={3}
                  />
                </div>
              </div>
            )}

{posterPreview === defaultPoster && (
                  <div className="all-shows-info p-0 mt-3 mb-1">
                    <img src={infoIcon} alt="Info" className="info-icon" />
                    {t('modals.add_custom_show_modal.add_poster_info')}
                  </div>
                )}

            </div>

            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Button variant="primary" type="submit" disabled={loading} className="mb-0 mt-4">
              {loading ? t('modals.add_custom_show_modal.adding_button') : t('modals.add_custom_show_modal.add_show_button')}
            </Button>
            <Button variant="light" onClick={onClose} className="mb-0 mt-2">
              {t('modals.add_custom_show_modal.cancel_button')}
            </Button>
          </Form>
          </>
)}
        </Modal.Body>
      </Modal>

      <Modal show={isDateModalOpen} onHide={handleCloseDateModal} className="modal-calendar-container" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="custom-datepicker">
          <div className="modal-calendar">
          <InputGroup className="mb-3">
              <InputGroup.Text>{t('modals.show_modal.year_label')}</InputGroup.Text>
              <FormControl
                type="number"
                value={manualYear}
                onChange={handleYearChange}
                aria-label="Year"
              />
            </InputGroup>
            <DatePicker
              selected={pendingDateWatched}
              onChange={handleDateChange}
              onMonthChange={handleMonthChange}
              inline
              locale={currentLocale}
              calendarStartDay={1}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveDateChange}>
            {t('modals.add_custom_show_modal.save_changes_button')}
          </Button>
          <Button variant="light" onClick={handleCloseDateModal}>
            {t('modals.add_custom_show_modal.cancel_button')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCustomShowModal;
