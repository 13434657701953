import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const PrivacyPolicy = ( {
  t
} ) => {
  return (
    <Container fluid className="px-3 mb-5 ">
                  <Row className="title-row mb-5 justify-content-center no-margin-row">
              <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl text-center title-col">
                <div className="page-title-container">
                  <h1 className="page-title">{t('privacypolicy.title')}</h1>
                  </div>
              </Col>
            </Row>
      <Row className="justify-content-center privacypolicy mt-5">
      <Col xs={12} sm={12} md={12} lg={11} xl={10} className="resp-pre-xl">



          <div className="">
            <div className="privacy-policy">
              <section className="privacy-section">
                <h2>1. Postanowienia ogólne</h2>
                <p>1.1. Niniejsza Polityka Prywatności określa zasady przetwarzania danych osobowych użytkowników aplikacji Showify ("Serwis").</p>
                <p>1.2. Administratorem danych osobowych jest Showify, kontakt: kontakt@showify.pl.</p>
                <p>1.3. Serwis umożliwia rejestrację wyłącznie poprzez konto Google.</p>
              </section>

              <section className="privacy-section">
                <h2>2. Zakres przetwarzanych danych</h2>
                <p>2.1. W ramach logowania przez Google pobierane są następujące dane:</p>
                <ul>
                  <li>Imię i nazwisko (wykorzystywane w profilu użytkownika)</li>
                  <li>Zdjęcie profilowe (wykorzystywane w profilu użytkownika)</li>
                  <li>Adres e-mail (niepublikowany, wykorzystywany wyłącznie do identyfikacji konta)</li>
                </ul>
              </section>

              <section className="privacy-section">
                <h2>3. Cel i podstawa prawna przetwarzania danych</h2>
                <p>3.1. Dane są przetwarzane w celu:</p>
                <ul>
                  <li>Umożliwienia korzystania z funkcji Serwisu (profil użytkownika, dziennik spektakli)</li>
                  <li>Obliczania średnich ocen spektakli</li>
                  <li>Wyświetlania aktywności użytkowników w zakładce "Aktywność użytkowników"</li>
                  <li>Zapewnienia funkcjonalności obserwowania profili</li>
                  <li>Obsługi zgłoszeń użytkowników (np. usunięcia konta)</li>
                  <li>Analizy ruchu na stronie za pomocą Google Analytics i Microsoft Clarity (za zgodą użytkownika)</li>
                </ul>
                <p>3.2. Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. b i f RODO (wykonanie umowy oraz uzasadniony interes administratora).</p>
              </section>

              <section className="privacy-section">
                <h2>4. Profilowanie i udostępnianie danych</h2>
                <p>4.1. Dane użytkowników nie są sprzedawane ani udostępniane podmiotom trzecim w celach marketingowych.</p>
                <p>4.2. Imię, nazwisko i zdjęcie użytkownika są widoczne w profilu oraz w zakładce "Aktywność użytkowników", chyba że użytkownik ustawi tryb prywatności na "dla znajomych" lub "prywatny".</p>
              </section>

              <section className="privacy-section">
                <h2>5. Prawa użytkowników</h2>
                <p>5.1. Użytkownik ma prawo do:</p>
                <ul>
                  <li>Dostępu do swoich danych oraz ich sprostowania</li>
                  <li>Żądania ograniczenia przetwarzania lub usunięcia danych</li>
                  <li>Przenoszenia danych do innego administratora</li>
                  <li>Wniesienia sprzeciwu wobec przetwarzania danych</li>
                  <li>Cofnięcia zgody na analizę ruchu przez Google Analytics i Clarity</li>
                </ul>
                <p>5.2. Żądania dotyczące usunięcia konta należy kierować na kontakt@showify.pl.</p>
              </section>

              <section className="privacy-section">
                <h2>6. Pliki cookies i analityka</h2>
                <p>6.1. Serwis wykorzystuje pliki cookies w celu:</p>
                <ul>
                  <li>Analizy ruchu za pomocą Google Analytics i Microsoft Clarity</li>
                  <li>Utrzymania sesji użytkownika</li>
                </ul>
                <p>6.2. Podczas pierwszej wizyty użytkownik ma możliwość wyrażenia zgody na pliki cookies lub ich odrzucenia. W przypadku odrzucenia cookies analitycznych jego sesja nie będzie rejestrowana.</p>
              </section>

              <section className="privacy-section">
                <h2>7. Zmiany w polityce prywatności</h2>
                <p>7.1. Administrator zastrzega sobie prawo do wprowadzania zmian w Polityce Prywatności. Użytkownicy zostaną poinformowani o istotnych zmianach za pośrednictwem Serwisu.</p>
              </section>
            </div>

          </div>
          

        </Col>
      </Row>


    </Container>
  );
};

export default PrivacyPolicy;
